/* eslint max-len: ["error", { "ignoreStrings": true }] */
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Box,
  Button,
  ButtonGroup,
  Fab,
  Grid,
  Paper,
  Tooltip,
  TextField,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListIcon from '@material-ui/icons/List';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';

import { useStyles } from '../useStyles.js';
import theme from '../theme.js';
import PatientTable from '../components/PatientsTable';
import PatientCard from '../components/PatientCard';
import SortButton from '../components/SortButton';
import QrCodeDialog from '../components/QrCodeDialog.js';

import {
  fetchData,
  extractGeneralPatData,
} from '../utils/dataFetchFunctions.js';
import {
  generalPatientDataApiCall,
} from '../data/apiCalls.js';

import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

/**
 * Renders the "Home" page. Marks the entry point of the web app.
 * @param {Object} props with api tokens to patient data
 * @return {jsx} The "Home" page component.
 */
function Home(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [patientData, setPatientData] = React.useState({
    displayed: [],
    full: [],
  });
  const [view, setView] = React.useState('card');
  const [sortMode, setSortMode] = React.useState(3);

  const isLoggedIn = props.isLoggedIn;
  const apiTokens = props.apiTokens;

  // Fetch from database if
  useEffect(() => {
    if (!isLoggedIn || apiTokens === null) {
      return;
    }

    setLoading(true);
    Promise.all(
      apiTokens.map((ui, idx) => {
        return fetchData(ui.tk, generalPatientDataApiCall)
          .then((rawProfile) => {
            const pData = extractGeneralPatData(rawProfile);
            pData.id = idx;
            pData.apiToken = ui.tk;
            pData.username = ui.tk.split('@')[1].split('.')[0];

            pData.upToDate = ui.userUpToDate;

            rawProfile[0].events.map( (x) => x.streamId);

            const sData = {
              'total-assessments': 0,
              'trend': '=',
              'stats': {
                'overall-stats': {
                  'symmetry': 0,
                  'endurance': 0,
                  'activity': 0,
                  'consistency': 0,
                },
              },
            };
            return { ...pData, ...sData };
          });
      }),
    ).then( (data) => {
      data.sort((a, b) => {
        return a.username - b.username;
      });

      setPatientData({ full: data, displayed: data });
      setLoading(false);
    });
  }, [apiTokens, isLoggedIn]);

  // Clear all states if logout is initiated
  useEffect(() => {
    if (!props.isLoggedIn) {
      setOpen(false);
      setLoading(false);
      setPatientData({ full: [], displayed: [] });
    }
  }, [props.isLoggedIn]);

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: '/', title: 'Home Page',
    });
  }, []);


  /* eslint-disable max-len */
  const toDoText = () => {
    const result = { l1: '', l2: '' };
    if (isLoggedIn) {
      result.l1 = 'You don\'t seem to have any access to patients data currently.';
      result.l2 = 'To get access, share the QR code found when pressing the button below.';
    } else {
      result.l1 = 'Welcome to NUSHU, glad to have you here.';
      result.l2 = 'Log in to continue.';
    }
    return result;
  };
  /* eslint-enable max-len */

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewChange = (event) => {
    setView(event.currentTarget.value);
  };

  const sortOptions =
    ['Last Name',
      'Age',
      'Days since registration',
      'Days since last',
      'Total'];

  const forceUpdate = React.useReducer(() => ({}))[1];

  const handleSortChange = (isAscending, index) => {
    const newSortMode = index;
    let newPatients = patientData.full;
    newPatients.sort(function(a, b) {
      switch (newSortMode) {
      case 0:
        return a.lastName > b.lastName ? 1 : -1;
      case 1:
        return a.age > b.age ? 1: -1;
      case 2:
        return a.daysInRehab > b.daysInRehab ? 1: -1;
      case 3:
        return a.daysSinceLast > b.daysSinceLast ? 1: -1;
      case 4:
        return a.total > b.total ? 1: -1;
      default:
        return null;
      }
    });
    if (!isAscending) newPatients = newPatients.reverse();
    setPatientData({
      ...patientData,
      displayed: newPatients,
    });
    setSortMode(newSortMode);
    forceUpdate();
  };

  const handleSearchInput = () => {
    let newPatients = patientData.full;
    let inputString = document.getElementById('Search field').value;
    inputString = inputString.toLowerCase();

    newPatients = newPatients.filter((p) => {
      if (
        p.lastName.toLowerCase().includes(inputString) ||
        p.firstName.toLowerCase().includes(inputString) ||
        p.username.toLowerCase().includes(inputString) ||
        p.birthdate.toLocaleDateString().includes(inputString)
      ) {
        return true;
      }
      return false;
    });
    setPatientData({
      ...patientData,
      displayed: newPatients,
    });
    forceUpdate();
  };

  if (!isLoggedIn || isLoading || (patientData.full.length === 0) ) {
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            height: '50vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {isLoading ?
            <CircularProgress color="secondary"/>:
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div>
                <Typography className= { classes.defaultSigninText }>
                  { toDoText(isLoggedIn).l1 }
                </Typography>
              </div>
              <div>
                <Typography className= { classes.defaultSigninText }>
                  { toDoText(isLoggedIn).l2 }
                </Typography>
              </div>
              {isLoggedIn &&
                  <div
                    style={{
                      position: 'relative',
                      top: '20px',
                      display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',
                    }}>
                    <Fab
                      color="primary"
                      onClick={() => {
                        setOpen(true);
                      }}>
                      <FontAwesomeIcon
                        icon={faQrcode}
                        style={{
                          left: '-10%',
                          color: theme.palette.secondary.main,
                          fontSize: 25,
                        }} />
                    </Fab>
                  </div>
              }
            </div>
          }
        </div>
        <QrCodeDialog
          isOpen={open}
          onClose={handleClose}
          doctorData={props.doctorData}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Box className={classes.alignContainer} marginBottom={2}>
          <ButtonGroup color="primary" style={{ backgroundColor: 'white' }}>
            <Tooltip placement="top" title="Show list" arrow>
              <Button
                value="list"
                onClick={handleViewChange}
                variant={view === 'list' ? 'contained' : 'outlined'}>
                <ListIcon />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Show cards" arrow>
              <Button
                value="card"
                onClick={handleViewChange}
                variant={view === 'card' ? 'contained' : 'outlined'}>
                <ViewModuleIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
          <TextField
            className={classes.searchBar}
            id="Search field"
            label="Search User"
            variant="outlined"
            onChange = {handleSearchInput}/>
          {view === 'card' &&
            <SortButton
              className={classes.alignCenter}
              sortMode={sortMode}
              onChange={handleSortChange}
              options={sortOptions}
            />}
          <Button
            className={classes.alignRight}
            color="primary"
            endIcon={<FontAwesomeIcon icon={faQrcode}/>}
            onClick={handleClickOpen}
            variant="contained">
            Add patient
          </Button>
        </Box>
        {view === 'card' &&
          <Grid container spacing={3}>
            {patientData.displayed.map((patient, i) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
                <PatientCard patientData={patient} />
              </Grid>
            ))}
          </Grid>}
        {view === 'list' &&
          <Paper>
            <PatientTable data = {patientData.displayed}/>
          </Paper>}
        <QrCodeDialog
          isOpen={open}
          onClose={handleClose}
          doctorData={props.doctorData}
        />
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  isLoggedIn: PropTypes.bool,
  apiTokens: PropTypes.array,
  doctorData: PropTypes.array,
};

export default Home;

const theme = {
  palette: {
    type: 'light',
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#3a464c',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#4ebfff',
    },
    subtitle: {
      main: '#6a6a6a',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ed8902',
    },
    info: {
      main: '#008fcc',
    },
    success: {
      main: '#28a745',
    },
  },
};

export default theme;

import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import TimelineCard from './TimelineCard.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCommentIcon from '@material-ui/icons/AddComment';

import { useStyles } from '../useStyles';
import AddTimelineEntryDialog from './AddTimelineEntryDialog.js';
import {
  makeTimeOfDayString,
  makeDateString,
} from '../utils/auxFunctions.js';
import {
  pryvApiCall,
  createEventCall,
  eventsFromStreams,
  updateEventCall,
  deleteEventCall,
} from '../data/apiCalls.js';

import {
  timelineDefs,
} from '../definitions/timeline.js';

export const generateEntryStr = (type, date, content) => {
  if (!(Object.keys(timelineDefs).includes(type))) {
    throw new Error('Passed entry type not recognised!');
  }
  const entry = {
    'type': type,
    'date': date.getTime() / 1000,
    'content': content,
  };
  return JSON.stringify(entry);
};

/**
 * Renders the "PatientTimeline" component.
 * @param {Object} props of the page.
 * @return {jsx} The patient timeline component.
 */
export default function PatientTimeline(props) {
  const classes = useStyles();
  const [openCD, setOpenCD] = React.useState(false);
  const [tlEntries, setTlEntries]= React.useState([]);
  const [deletionDialog, setDeletionDialog] = React.useState({
    open: false,
    id: undefined,
  });

  useEffect(() => {
    pryvApiCall(props.patApiEndPoint, eventsFromStreams(['comments']))
      .then( (res) => {
        if (
          res[0].events.length === 0 &&
          !sessionStorage.getItem('uploadingEntries')
        ) {
          // Generate 'Default' Entries
          const registrationEntryStr = generateEntryStr(
            'Registration',
            props.regDate,
            'Signed up using the Nushu App.',
          );
          pryvApiCall(
            props.patApiEndPoint,
            createEventCall(
              ['comments'],
              'note/txt',
              registrationEntryStr,
              (new Date()).getTime() / 1000,
            ))
            .then((ev) => {
              const entry = JSON.parse(ev[0].event.content);
              entry.id = ev[0].event.id;
              setTlEntries((entries) => [...entries, entry]);
            });


          const firstAssEntryStr = generateEntryStr(
            'First Assessment',
            props.firstAssDate,
            'Completed the first exercise using Nushus.',
          );
          pryvApiCall(
            props.patApiEndPoint, createEventCall(
              ['comments'],
              'note/txt',
              firstAssEntryStr,
              (new Date()).getTime() / 1000,
            ))
            .then((ev) => {
              const entry = JSON.parse(ev[0].event.content);
              entry.id = ev[0].event.id;
              setTlEntries((entries) => [...entries, entry]);
            });
        } else {
          sessionStorage.removeItem('uploadingEntries');
          const tlItems = res[0].events.map((item) => {
            const newItem = JSON.parse(item.content);
            newItem['id'] = item.id;
            return newItem;
          });
          setTlEntries(tlItems);
        }
      });
  }, []);

  const dateFormat = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const sortedEntries = () => {
    const es = tlEntries.sort( (a, b) => (a.date < b.date ? 1 : -1));
    return es;
  };

  const handleClose = () => {
    setOpenCD(false);
  };
  const handleDeleteEntry = () => {
    const tle = tlEntries.find((tl) => tl.id === deletionDialog.id);
    pryvApiCall(props.patApiEndPoint, deleteEventCall(deletionDialog.id))
      .then((res) => {
        const newEntries = [];
        tlEntries.forEach((e) => {
          if (e.id !== deletionDialog.id) {
            newEntries.push(e);
          }
        });
        setTlEntries(newEntries);
        setDeletionDialog({ open: false, id: undefined });
      });

    if (tle.type === 'Fall') {
      const patientData = JSON.parse(sessionStorage.getItem('patientData'));

      // Original date of the tl entry to find id of macro event.
      const maEv = patientData.macroEvents.find((me) => me.time === tle.date);
      const maIdx = patientData.macroEvents.indexOf(maEv);
      pryvApiCall(
        props.patApiEndPoint,
        [
          {
            'method': 'events.delete',
            'params': {
              'id': maEv.id,
            },
          },
        ],
      ).then((res) => {
        patientData.macroEvents.slice(maIdx, 1);
        sessionStorage.setItem('patientData', JSON.stringify(patientData));
      });
    }
  };

  const handleAddEntry = (entry) => {
    const e = generateEntryStr(
      entry.type, new Date(entry.date * 1000), entry.content,
    );
    pryvApiCall(
      props.patApiEndPoint,
      createEventCall(
        ['comments'],
        'note/txt',
        e,
        (new Date()).getTime() / 1000,
      ),
    ).then((res) => {
      entry.id = res[0].event.id;
      setTlEntries([entry, ...tlEntries]);
      setOpenCD(false);
    });

    // Generate macro event if it's a fall
    if (entry.type === 'Fall') {
      pryvApiCall(
        props.patApiEndPoint,
        [
          {
            'method': 'events.create',
            'params': {
              'streamIds': ['fall'],
              'type': 'activity/plain',
              'time': entry.date,
            },
          },
        ],
      ).then((res) => {
        const patientData = JSON.parse(sessionStorage.getItem('patientData'));
        patientData.macroEvents.push(res[0].event);
        sessionStorage.setItem('patientData', JSON.stringify(patientData));
      });
    }
  };

  const handleEditEntry = (entry) => {
    const e = generateEntryStr(
      entry.type, new Date(entry.date * 1000), entry.content,
    );

    pryvApiCall(props.patApiEndPoint, updateEventCall(entry.id, e))
      .then((res) => {
      });
    const newEntries = [...tlEntries];
    const rIdx = newEntries
      .indexOf(newEntries.filter((et) => et.id === entry.id)[0]);
    newEntries[rIdx] = entry;
    setTlEntries(newEntries);

    if (entry.type === 'Fall') {
      const patientData = JSON.parse(sessionStorage.getItem('patientData'));

      // Original date of the tl entry to find id of macro event.
      const date = tlEntries.find((tl) => tl.id === entry.id).date;
      const maEv = patientData.macroEvents.find((me) => me.time === date);
      console.log(maEv);
      const maIdx = patientData.macroEvents.indexOf(maEv);
      pryvApiCall(
        props.patApiEndPoint,
        [
          {
            'method': 'events.update',
            'params': {
              'id': maEv.id,
              'update': {
                'time': entry.date,
              },
            },
          },
        ],
      ).then((res) => {
        patientData.macroEvents[maIdx] = res[0].event;
        sessionStorage.setItem('patientData', JSON.stringify(patientData));
      });
    }
  };

  if (tlEntries.length !== 0) {
    return (
      <React.Fragment>
        <div style={{ height: '100%', overflow: 'scroll' }}>
          <Timeline>
            {sortedEntries().map((entry, idx) => {
              const Icon = timelineDefs[entry.type].icon;
              const dd = new Date(entry.date * 1000);
              return (
                <TimelineItem key={`tl-e-${idx}`}>
                  <TimelineOppositeContent
                    style={{
                      maxWidth: 70,
                      padding: 0,
                    }}>
                    <div style={{ marginTop: 5 }}>
                      <Typography color="textSecondary"
                        style={{ fontSize: 10, paddingRight: 5 }}>
                        {makeDateString(dd, dateFormat)} <br/>
                        {makeTimeOfDayString(dd)}
                      </Typography>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <Avatar variant="rounded"
                      style={{ width: 30, height: 30, top: 5 }}>
                      <Icon style={{ fontSize: 20 }}/>
                    </Avatar>
                    {idx - sortedEntries().length !== -1 &&
                    <TimelineConnector />
                    }
                  </TimelineSeparator>
                  <TimelineContent>
                    <TimelineCard
                      entry = { entry }
                      index={ idx }
                      handleDeleteEntry={() => {
                        setDeletionDialog({ open: true, id: entry.id });
                      }}
                      handleEditEntry={handleEditEntry}/>
                  </TimelineContent>
                </TimelineItem>
              );
            },
            )}
          </Timeline>
        </div>
        <Tooltip placement="top" title="Add Entry">
          <IconButton
            color='primary'
            className={classes.iconButton}
            onClick={() => setOpenCD(true)}
            style={{
              position: 'absolute',
              right: 1,
              bottom: 1,
            }}>
            <AddCommentIcon />
          </IconButton>
        </Tooltip>
        <AddTimelineEntryDialog
          entry={{}}
          isOpen={openCD}
          onSubmit={handleAddEntry}
          onCancel={handleClose}
        />
        <Dialog open={deletionDialog.open}>
          <div style={{ padding: 10 }}>
            <DialogTitle>
              <Typography align="center">
                Warning
              </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent style={{ margin: 10 }}>
              Are you sure you want to delete the timeline entry?
            </DialogContent>
            <Divider />
            <DialogActions style={{ marginTop: 10 }}>
              <Button
                onClick={() => setDeletionDialog(
                  { open: false, id: undefined },
                )}
                variant="outlined"
                color="primary">
                Cancel
              </Button>
              <Button
                onClick={ () => handleDeleteEntry()}
                variant="contained"
                color="primary">
                Confirm
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div
          style={{
            left: '50%', top: '50%',
          }}>
          <CircularProgress color="secondary"/>
        </div>
      </React.Fragment>
    );
  }
}

PatientTimeline.propTypes = {
  patApiEndPoint: PropTypes.string,
  firstAssDate: PropTypes.object,
  regDate: PropTypes.object,
};

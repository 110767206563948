import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Bar, Radar } from 'react-chartjs-2';

import {
  ButtonGroup,
  Dialog,
  DialogTitle,
  Tooltip,
  Button,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faDiceD20,
} from '@fortawesome/free-solid-svg-icons';

import { gaitParameters } from '../definitions/gaitParameters.js';

const view = {
  BAR: 'bar',
  RADAR: 'radar',
};

/**
 * Renders the radar and bar chart for the variability indices.
 * @param {Object} props The component properties.
 * @return {jsx} The variabilty bar chart component.
 */
export default function VariabilityChartDialog(props) {
  const [chartView, setChartView] = useState(view.RADAR);

  const a = props.rowData;

  const leftData = gaitParameters.map( (gp) => a.L[gp.id].varIDX);
  const rightData = gaitParameters.map( (gp) => a.R[gp.id].varIDX);
  const dataLabels = gaitParameters.map( (gp) => gp.title);

  const handleViewChange = (event) => {
    setChartView(event.currentTarget.value);
  };

  const radarData = {
    labels: dataLabels,
    datasets: [
      {
        label: 'Left',
        lineTension: 0.0,
        backgroundColor: '#008fcc33',
        borderColor: '#008fcc',
        borderDashOffset: 0.5,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: leftData.map((value) => Number(value).toFixed(3)),
      },
      {
        label: 'Right',
        lineTension: 0.0,
        backgroundColor: '#4ebfff33',
        borderColor: '#4ebfff',
        borderDashOffset: 0.5,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: rightData.map((value) => Number(value).toFixed(3)),
      },
    ],
  };

  const barData = {
    labels: dataLabels,
    datasets: [{
      backgroundColor: '#008fcc',
      data: leftData.map((value) => Number(value).toFixed(3)),
      label: 'Left',
    }, {
      backgroundColor: '#4ebfff',
      data: rightData.map((value) => Number(value).toFixed(3)),
      label: 'Right',
    }],

  };
  const barOptions = {
    scales: {
      yAxes: [{
        categoryPercentage: 1.0,
        ticks: {
          min: 0,
          max: 1,
          stepSize: 0.2,
        },
      }],
    },
  };

  const radarOptions = {
    responsive: true,
    scale: {
      angleLines: {
        display: true,
      },
      beginAtZero: true,
      min: 0,
      max: 100,
      pointLabels: {
        fontSize: 14,
      },
      ticks: {
        display: false,
        maxTicksLimit: 5,
      },
    },
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}>
      <DialogTitle>
        Coefficients of Variablility
      </DialogTitle>
      <div
        style={{
          padding: 10,
          width: 500,
          height: 300,
        }}>
        {chartView === view.RADAR &&
          <Radar data={radarData} options={radarOptions} />}
        {chartView === view.BAR &&
          <Bar data={barData} options={barOptions} />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: 10,
          }}>
          <ButtonGroup color="primary">
            <Tooltip placement="top" title="Show radar chart" arrow>
              <Button
                value={view.RADAR}
                onClick={handleViewChange}
                variant={chartView === view.RADAR ? 'contained' : 'outlined'}
              >
                <FontAwesomeIcon icon={faDiceD20} />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Show bar chart" arrow>
              <Button
                value={view.BAR}
                onClick={handleViewChange}
                variant={chartView === view.BAR ? 'contained' : 'outlined'}
              >
                <FontAwesomeIcon icon={faChartBar} />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
    </Dialog>
  );
};

VariabilityChartDialog.propTypes = {
  rowData: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

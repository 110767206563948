/* eslint max-len: ["error", { "ignoreStrings": true }] */
/* eslint-disable max-len */
import React from 'react';
import { PropTypes } from 'prop-types';

import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

import {
  linspace,
  kernelDensityEstimation,
} from '../utils/mathUtils.js';

/**
 * Renders the "RowSelectorButtonGroup" component.
 * @param {Object} props of the page.
 * @return {jsx} The  RowSelectorButtonGroup component.
 */
export default function RowSelectorButtonGroup(props) {
  const _stepTimes = {};
  ['L', 'R'].forEach((side) => {
    _stepTimes[side] = [];
    props.analysisData.rows.forEach((row) => {
      _stepTimes[side].push(...row[side].t0.values);
    });
  });

  const allStepTimes = _stepTimes.R.concat(..._stepTimes.L);
  const t0 = Math.min(...allStepTimes);
  const tf = Math.max(...allStepTimes);

  const noDatapoints = Math.ceil(tf - t0);

  const padTime = 0;
  const xi = linspace(t0 - padTime, tf + padTime, noDatapoints);

  const datasets = [
    {
      label: 'Step Density',
      fill: true,
      borderColor: 'rgba(0, 143, 204)',
      backgroundColor: 'rgba(0, 143, 204, 0.25)',
      pointRadius: 0,
      data: kernelDensityEstimation(allStepTimes, xi, 3),
    },
  ];

  /**
   * Converts a number to a string and pads it with zeros.
   * @param {number} num number to convert to padded string.
   * @param {number} size size of zero padding.
   * @return {string} the padded number string.
   */
  function pad(num, size) {
    const s = '000000000' + num;
    return s.substr(-size);
  };

  const lineData = {
    labels: xi.map((x) => {
      const t = new Date(x * 1000.0);
      return `${pad(t.getHours(), 2)}:${pad(t.getMinutes(), 2)}`;
    }),
    datasets: datasets,
  };

  const yMax = Math.max(...datasets.map( (ds) => ds.data).flat());

  const makeAnnotations = props.analysisData.rows.map((row, idx) => {
    const tStart = Math.min(row['L'].t0.min, row['R'].t0.min);
    const tEnd = Math.max(row['L'].t0.max, row['R'].t0.max);
    const startIdx = Math.round(
      (tStart - t0 + padTime - 2) / (tf - t0 + 2 * padTime) * noDatapoints,
    );
    const endIdx = Math.round(
      (tEnd - t0 + padTime + 2) / (tf - t0 + 2 * padTime) * noDatapoints,
    );
    return {
      type: 'box',
      xMin: startIdx,
      xMax: endIdx,
      yMin: 0,
      yMax: yMax,
      backgroundColor: (props.selectedRow === idx) ? 'rgba(189, 189, 189, 0.25)': 'rgba(189, 189, 189, 0.03)',
      borderColor: 'rgba(189, 189, 189, 0)',
      xScaleID: 'x-axis-0',
      yScaleID: 'y-axis-0',
      onClick: function(e) {
        props.setSelectedRow(idx);
      },
    };
  });

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    scales: {
      xAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          display: true,
          drawOnChartArea: false,
          maxRotation: 0,
          minRotation: 0,
          callback: function(val, idx) {
            return idx % 2 === 0 ? val: '';
          },
        },
      }],
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          const dLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dLabel}`;
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    annotation: {
      drawTime: 'afterDatasetsDraw',
      events: ['click'],
      annotations: makeAnnotations,
    },
  };

  return (
    <div style={{ height: '100%', width: '100%', paddingRight: 10 }}>
      <Line data={lineData} options={lineOptions}
        width={null} height={null}/>
    </div>
  );
}

RowSelectorButtonGroup.propTypes = {
  analysisData: PropTypes.object,
  selectedRow: PropTypes.number,
  setSelectedRow: PropTypes.func,
};

import React from 'react';
import { PropTypes } from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Avatar,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  FaHourglassHalf,
  FaRoute,
  FaShoePrints,
  FaStopwatch,
  FaTachometerAlt,
} from 'react-icons/fa';

const parameterAuxDef = {
  'steps': {
    'title': 'steps',
    'icon': FaShoePrints,
    'unit': '-',
    'decimals': 0,
  },
  'cadence': {
    'title': 'cadence',
    'icon': FaStopwatch,
    'unit': 'SPM',
    'decimals': 0,
  },
  'distance': {
    'title': 'distance',
    'icon': FaRoute,
    'unit': 'm',
    'decimals': 0,
  },
  'duration': {
    'title': 'duration',
    'icon': FaHourglassHalf,
    'unit': 's',
    'decimals': 0,
  },
  'velocity': {
    'title': 'gait speed',
    'icon': FaTachometerAlt,
    'unit': 'm/s',
    'decimals': 2,
  },
};

/**
 * Show pie charts of performance stats in comp.
 * @param {Object} props The component properties.
 * @return {jsx} The chart component.
 */
export default function CompPerformanceChart(props) {
  const pIcon = parameterAuxDef[props.parameter].icon;
  const pTitle = parameterAuxDef[props.parameter].title;
  const pUnit = parameterAuxDef[props.parameter].unit;
  const pDec = parameterAuxDef[props.parameter].decimals;

  const absSum = Math.abs(props.data[0]) + Math.abs(props.data[1]);
  const chartData = {
    labels: ['Group A', 'Group B'],
    datasets: [
      {
        data: [props.data[0].toFixed(pDec), props.data[1].toFixed(pDec)],
        backgroundColor: [
          '#499f68',
          '#008fcc',
        ],
        borderWidth: 3,
        barPercentage: 0.5,
      },
    ],
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: Math.PI,
    circumference: Math.PI,
    usePlugin: 'N/A',
    layout: {
      padding: {
        bottom: 5,
      },
    },
    legend: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, chartData) {
          const dataset = chartData['datasets'][0];
          const value = dataset['data'][tooltipItem['index']];
          const unit = props.unit ? props.unit : '';
          const ratio = Math.abs(value / absSum);
          const percent = Math.round(ratio * 100);
          return `${value} ${unit} (${percent}%)`;
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Avatar
        variant="rounded"
        style={{
          top: 16,
          width: 30,
          height: 30,
          zIndex: 10,
        }}>
        { [pIcon].map((icon, jj) => {
          const Icon = icon;
          return (
            <Icon key={jj}/>
          );
        })}
      </Avatar>
      <LinearProgress
        value={0} variant="determinate"
      />
      <Paper style={{
        padding: 13,
        overflow: 'hidden',
        height: '100%',
        width: '99%',
        justifyContent: 'center',
        position: 'relative',
      }}>
        <div
          style={{
            position: 'absolute',
            height: '30%',
            width: '100%',
            left: 0,
            top: '50%',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Divider orientation="vertical"/>
        </div>
        <div style={{ position: 'relative' }}>
          <Typography
            color="primary"
            align="center"
            variant="overline"
            style= {{ backgroundColor: 'white', fontSize: 9 }}>
            {pTitle}
          </Typography>
          <div style={{ height: 60 }}>
            <Doughnut data={chartData} options={options} />
          </div>
          <Typography
            color="primary"
            align="center" variant="caption"
            style= {{ backgroundColor: 'white' }}>
            {pUnit}
          </Typography>
          <Grid container
            style={{
              position: 'absolute',
              bottom: 0,
            }}>
            <Grid item xs={6}>
              <Typography color="primary" align="center"
                style={{ fontSize: 14 }}>
                {props.data[0].toFixed(pDec)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography color="primary" align="center"
                style={{ fontSize: 14 }}>
                {props.data[1].toFixed(pDec)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </React.Fragment>
  );
}

CompPerformanceChart.propTypes = {
  parameter: PropTypes.string,
  data: PropTypes.array,
  unit: PropTypes.string,
};

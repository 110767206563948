import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import { linspace, linearRegression } from '../utils/mathUtils';
import Overlay from '../components/Overlay.js';

/**
 * Renders trend chart.
 * @param {Object} props The component properties.
 * @return {jsx} The trend chart component.
 */
export default function TrendChart(props) {
  const [overlay, setOverlay] = useState(false);

  let withoutZeros = true;
  if (props.dataLabel === 'Assessments' || props.dataLabel === 'Steps') {
    withoutZeros = false;
  }
  const n = props.dataValues.length;
  const nonZeroes = props.dataValues.filter( (x) => {
    if (x !== 0) {
      return x;
    }
  }).length;


  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.dataLabel,
        fill: false,
        lineTension: 0.0,
        backgroundColor: '#4ebfffaa',
        borderDashOffset: 0.5,
        borderJoinStyle: 'round',
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: props.dataValues.map((value) => Number(value).toFixed(2)),
      },
    ],
  };

  // Make trend lines only if there's not a massive amount of datapoints
  if (n < 100 && nonZeroes > 3) {
    data.datasets.unshift(
      {
        label: 'Trend',
        borderColor: '#3a464c',
        borderDash: [3, 2],
        data:
        linearRegression(
          linspace(0, n - 1, n),
          props.dataValues,
          withoutZeros,
        )
          .map((value) => value.toFixed(2)),
        fill: false,
        type: 'line',
      },
    );
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [{
        barPercentage: (n < 100) ? 1: 2,
        scaleLabel: {
          display: false,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: props.yAxisLabel,
        },
        ticks: {
          beginAtZero: true,
          callback: function(value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      }],
    },
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setOverlay(true)}
        style={{
          height: '100%',
          paddingBottom: 10,
        }}>
        <Bar data={data} options={options}/>
      </div>
      <Overlay
        open={overlay}
        onClose={() => setOverlay(false)}
        title={props.yAxisLabel}>
        <Bar data={data} options={options}/>
      </Overlay>
    </React.Fragment>
  );
};

TrendChart.propTypes = {
  dataLabel: PropTypes.string.isRequired,
  dataValues: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};

/* eslint max-len: ["error", { "ignoreStrings": true }] */
import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Avatar,
  CircularProgress,
  Dialog,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  MdOutlineHelpOutline,
} from 'react-icons/md';
import {
  HiOutlineDocumentSearch,
} from 'react-icons/hi';
import {
  AiFillFilePdf,
  AiOutlineFileUnknown,
} from 'react-icons/ai';
import {
  FaFileDownload,
} from 'react-icons/fa';

import { downloadPdf } from '../utils/pdfTools.js';

import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

/* eslint-disable-next-line max-len*/
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const getFile = async ({ id, type }) => {
  const url = `https://docs.google.com/${type}/d/${id}/export?format=pdf`;
  const pdfFileAb = await fetch(url)
    .then((res) => res.arrayBuffer());

  return new Uint8Array(pdfFileAb);
};

const DEFINITION_FILE= { id: '1woWwBwusmBbM5jbBBqTPl73MGb4m1-EY', type: 'document' };
const QUICK_GUIDE_FILE = { id: '1frRkkDZ858ADaAQtvix0D_Si95JClvsl', type: 'presentation' };

/**
 * Renders the "About" page. Gives general info about Magnes and Nushu.
 * @return {jsx} The about page component.
 */
export default function Documentation() {
  const defaultShowPdf = { 'Gait Parameters': false, 'Quick Guide': false };

  const [docuPdf, setDocuPdf] = useState(undefined);
  const [qGuidePdf, setQGuidePdf] = useState(undefined);
  const [showPdf, setShowPdf] = useState(defaultShowPdf);
  const [numPages, setNumPages] = useState(null);

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: '/documentation', title: 'Documentation Page',
    });
  }, []);

  useEffect( async () => {
    if (!docuPdf || docuPdf !== 'loading') {
      setDocuPdf('loading');
      getFile(DEFINITION_FILE)
        .then((res) => setDocuPdf(res))
        .catch((err) => setDocuPdf('failed'));
    };

    if (!qGuidePdf || qGuidePdf !== 'loading') {
      setQGuidePdf('loading');
      getFile(QUICK_GUIDE_FILE)
        .then((res) => setQGuidePdf(res))
        .catch((err) => setQGuidePdf('failed'));
    };
  }, []);

  const items = [
    {
      title: 'Gait Parameters',
      description: 'Documentation on how the shown gait parameter are defined and how they are calculated by our NUSHU system.',
      pdf: docuPdf,
      icon: HiOutlineDocumentSearch,
    },
    {
      title: 'Quick Guide',
      description: 'A guide to the dashboard, detailing all available features.',
      pdf: qGuidePdf,
      icon: MdOutlineHelpOutline,
    },
  ];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleShowPdfClick = (title) => {
    const _showPdf = { ...defaultShowPdf };
    _showPdf[title] = true;
    setShowPdf(_showPdf);
  };

  const handleClosePdfClick = () => {
    setShowPdf(defaultShowPdf);
  };

  const isDialogOpen = () => {
    const open = !Object.values(showPdf).every((val) => val === false);
    return open;
  };

  const dialogPdf = () => {
    const dp = items.filter((x) => (showPdf[x.title]));
    if (dp.length !== 1) {
      return undefined;
    } else {
      return dp[0].pdf;
    }
  };

  const pdfFilename = () => {
    const dp = items.filter((x) => showPdf[x.title]);
    if (dp.length !== 1) {
      return undefined;
    } else {
      const filename = 'Nushu' + dp[0].title.replaceAll(' ', '');
      return filename;
    }
  };

  return (
    <div>
      <Grid container justifyContent="center" align="center" spacing={4}>
        {
          items.map( (x, idx) => {
            const Icon = x.icon;
            return (
              <Grid item xs={4} key={`doc-file-idx-${idx}`}>
                <Avatar
                  variant="rounded"
                  style={{
                    top: 16,
                    width: 30,
                    height: 30,
                    zIndex: 10,
                  }}>
                  <Icon />
                </Avatar>
                <LinearProgress value={0} variant="determinate" />
                <Paper style={{ position: 'relative', padding: 10 }}>
                  <div style={{ margin: 5 }}>
                    <Typography variant="overline" style={{ fontSize: 13 }}>
                      {x.title}
                    </Typography>
                  </div>
                  <Divider/>
                  <div style={{ padding: 10 }}>
                    <Typography variant="subtitle2" align="left">
                      {x.description}
                    </Typography>
                  </div>
                  <Divider/>
                  <div
                    style={{
                      paddingRight: 10,
                      paddingTop: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {x.pdf === 'loading' && <CircularProgress color="secondary"/>}
                    {x.pdf === 'failed' && <AiOutlineFileUnknown style={{ fontSize: 40 }} />}
                    {typeof x.pdf === 'object' &&
                      <Tooltip title="View File">
                        <IconButton
                          color="primary"
                          onClick={() => handleShowPdfClick(x.title)}>
                          <AiFillFilePdf style={{ fontSize: 40 }}/>
                        </IconButton>
                      </Tooltip>
                    }
                  </div>
                </Paper>
              </Grid>
            );
          })
        }
      </Grid>
      <React.Fragment>
        <Dialog
          open={isDialogOpen()}
          onClose={handleClosePdfClick}>
          <div style={{ padding: 10, overflow: 'scroll' }}>
            <Document
              file={{ data: dialogPdf() }}
              options={{ workerSrc: '/pdf.worker.js' }}
              onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
          <div>
            <Tooltip title="Download">
              <IconButton color="primary"
                onClick={
                  () => downloadPdf(dialogPdf(), pdfFilename())
                }
                style={{
                  position: 'absolute',
                  right: 10,
                  bottom: 10,
                }}>
                <FaFileDownload
                  style={{ fontSize: 30 }}/>
              </IconButton>
            </Tooltip>
          </div>
        </Dialog>
      </React.Fragment>
    </div>
  );
}

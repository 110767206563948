import React from 'react';
import { PropTypes } from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faChartArea,
} from '@fortawesome/free-solid-svg-icons';

import CompHistogramChart from './CompHistogramChart.js';
import Overlay from './Overlay.js';

import { useStyles } from '../useStyles.js';

/**
 * Show left and right symmetry.
 * @param {Object} props The component properties.
 * @return {jsx} The symmetry chart component.
 */
export default function CompSymmetryChart(props) {
  const classes = useStyles();

  const [chartType, setChartType] = React.useState('bar');
  const [openDialog, setOpenDialog] = React.useState(false);

  const Icon = props.parameter.icon;
  const pTitle = props.parameter.title;
  const pUnit = props.parameter.unit;
  const pDec = 2;

  const statsA = props.data['Group A'];
  const statsB = props.data['Group B'];

  const ubAvg = Math.max(
    statsA['L'].avg,
    statsB['L'].avg,
    statsA['R'].avg,
    statsB['R'].avg,
  );
  const ubStd = Math.max(
    statsA['L'].std,
    statsB['L'].std,
    statsA['R'].std,
    statsB['R'].std,
  );

  const barData = (set, stat, group) => (
    {
      labels: ['Left', 'Right'],
      datasets: [
        {
          data: [set['L'][stat].toFixed(pDec), set['R'][stat].toFixed(pDec)],
          backgroundColor: (group === 'A') ?
            ['#499f68', '#54dea9']:
            ['#008fcc', '#4ebfff'],
          barThickness: 20,
        },
      ],
    }
  );

  const barOptions = (stat) => (
    {
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
      scales: {
        yAxes: [{
          display: false,
          ticks: {
            display: false,
            beginAtZero: true,
            max: (stat === 'avg') ? ubAvg : ubStd,
          },
        }],
        xAxes: [{
          display: false,
        }],
      },
    }
  );

  const changeChartType = (event) => {
    setChartType(event.currentTarget.value);
  };

  return (
    <React.Fragment>
      <Avatar
        variant="rounded"
        style={{
          top: 16,
          width: 30,
          height: 30,
          zIndex: 10,
        }}>
        < Icon />
      </Avatar>
      <LinearProgress
        value={0} variant="determinate"
      />
      <Paper className={classes.paperFlex}
        style={{ padding: 13, height: 340 }}>
        <Typography
          color="primary"
          align="center"
          variant="overline"
          style={{ fontSize: 9 }}>
          {pTitle}
        </Typography>
        <Grid container style={{ }}>
          {
            (chartType === 'bar') &&
              <React.Fragment>
                <Grid container style={{ marginTop: 0 }}>
                  <Grid container justify="center">
                    <Grid item xs>
                      <Divider style={{ position: 'relative', top: '50%' }}/>
                    </Grid>
                    <Grid item xs justify="center" style={{ display: 'flex' }}>
                      <Typography
                        align="center" color="primary" style={{ fontSize: 11 }}>
                      AVG
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Divider style={{ position: 'relative', top: '50%' }}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid container item xs={5} justify="center">
                    <Grid item xs={12} justify="center"
                      style={{
                        width: '100%',
                        height: 90,
                        justifyContent: 'center',
                      }}>
                      <Bar
                        data={barData(statsA, 'avg', 'A')}
                        options={barOptions('avg')}
                        style={{ margin: 'auto' }}/>
                    </Grid>
                    <Grid item xs={6} justify="center">
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsA['L'].avg.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center">
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsA['R'].avg.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} justify="center"
                    style={{
                      display: 'flex',
                    }}>
                    <Divider orient="vertical" flexItem
                      style={{
                        width: '1px',
                        align: 'center',
                      }}/>
                  </Grid>
                  <Grid container item xs={5} justify="center">
                    <Grid item xs={12} justify="center"
                      style={{
                        width: '100%',
                        height: 90,
                        justifyContent: 'center',
                      }}>
                      <Bar
                        data={barData(statsB, 'avg', 'B')}
                        options={barOptions('avg')}
                        style={{ margin: 'auto' }}/>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsB['L'].avg.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsB['R'].avg.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid container xs={12} justify="center">
                    <Grid item xs>
                      <Divider style={{ position: 'relative', top: '50%' }}/>
                    </Grid>
                    <Grid item xs justify="center" style={{ display: 'flex' }}>
                      <Typography
                        align="center" color="primary" style={{ fontSize: 11 }}>
                      STDEV
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Divider style={{ position: 'relative', top: '50%' }}/>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justify="center">
                  <Grid container item xs={5} justify="center">
                    <Grid item xs={12} justify="center"
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '30px',
                        justifyContent: 'center',
                      }}>
                      <Bar
                        data={barData(statsA, 'std', 'A')}
                        options={barOptions('std')}
                        style={{ margin: 'auto' }}/>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsA['L'].std.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsA['R'].std.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} justify="center"
                    style={{
                      display: 'flex',
                    }}>
                    <Divider orient="vertical" flexItem
                      style={{
                        width: '1px',
                        align: 'center',
                      }}/>
                  </Grid>
                  <Grid container item xs={5} justify="center">
                    <Grid item xs={12} justify="center"
                      style={{
                        display: 'flex',
                        width: '100%',
                        height: '30px',
                        justifyContent: 'center',
                      }}>
                      <Bar
                        data={barData(statsB, 'std', 'B')}
                        options={barOptions('std')}
                        style={{ margin: 'auto' }}/>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsB['L'].std.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="primary"
                        align="center" variant="subtitle2">
                        {statsB['R'].std.toFixed(pDec)}<small>{pUnit}</small>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container justify="center"
                  style={{ marginTop: '10px' }}>
                  <Grid item xs={6} justify="center">
                    <Divider variant="middle"/>
                  </Grid>
                  <Grid item xs={6} justify="center">
                    <Divider variant="middle"/>
                  </Grid>
                  <Grid container xs justify="center">
                    <Grid item xs={5} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block">
                        Left
                      </Typography>
                    </Grid>
                    <Grid item xs={5} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block">
                        Right
                      </Typography>
                    </Grid>
                    <Grid item xs={12} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block"
                        style={{ marginTop: '10px' }}>
                        Group A
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} justify="center"
                    style={{
                      display: 'flex',
                    }}>
                    <Divider orient="vertical" flexItem
                      style={{
                        width: '1px',
                        align: 'center',
                      }}/>
                  </Grid>
                  <Grid container xs justify="center">
                    <Grid item xs={6} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block">
                        Left
                      </Typography>
                    </Grid>
                    <Grid item xs={6} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block">
                        Right
                      </Typography>
                    </Grid>
                    <Grid item xs={12} justify="center">
                      <Typography color="primary"
                        align="center" variant="caption" display="block"
                        style={{ marginTop: '10px' }}>
                        Group B
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </React.Fragment>
          }
          {
            (chartType === 'hist') &&
            <div
              style={{ height: 250, width: '100%' }}
              onClick={() => setOpenDialog(true)}>
              <CompHistogramChart
                data={props.data}
                unit={pUnit}/>
            </div>
          }
          <Overlay
            title={pTitle}
            open={openDialog}
            onClose={() => setOpenDialog(false)}>
            <CompHistogramChart
              data={props.data}
              unit={pUnit}/>
          </Overlay>
          <Grid item xs={12} justify="center"
            style={{
              display: 'flex',
              marginTop: '20px',
            }}>
            <ButtonGroup color="primary" style={{ align: 'center' }}>
              <Tooltip placement="top" title="Show Statistics" arrow>
                <Button
                  value={'bar'}
                  onClick={changeChartType}
                  variant={chartType === 'bar' ? 'contained' : 'outlined'}
                >
                  <FontAwesomeIcon icon={faChartBar} />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title="Show Histogram" arrow>
                <Button
                  value={'hist'}
                  onClick={changeChartType}
                  variant={chartType === 'hist' ? 'contained' : 'outlined'}
                >
                  <FontAwesomeIcon icon={faChartArea} />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

CompSymmetryChart.propTypes = {
  parameter: PropTypes.object,
  data: PropTypes.object,
};

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';

import {
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdModeEdit,
  MdOutlineClose,
} from 'react-icons/md';

import CustomSnackbar from './CustomSnackbar.js';

import { useStyles } from '../useStyles.js';
import {
  pryvApiCall,
  eventsFromStreams,
  updateEventCall,
  createEventCall,
} from '../data/apiCalls.js';

import affiliations from '../definitions/affiliations/affiliations.json';


const defaultProfileInfo = {
  'name': {
    'first-name': '',
    'last-name': '',
  },
  'address': {
    'street': '',
    'zip': 0,
    'town': '',
    'country': '',
  },
  'phone': '',
};

const eventTypes = {
  'name': 'contact/magnes-v1',
  'address': 'address/magnes-v1',
  'phone': 'call/telephone',
};

/**
 * Renders the UserInfoDialog element, which displays the docs
 user info, such as the QR code, TODO: add editable vcard info.
 * @param {Object} props The component properties.
 * @return {jsx} The user dialog component.
 */
export default function AccountInfoDialog(props) {
  const classes = useStyles();

  const [calls, setCalls] = useState(undefined);
  const [accountInfo, setAccountInfo] = useState(defaultProfileInfo);
  const [isFetching, setIsFetching] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false,
  });

  const apiEndpoint = sessionStorage.getItem('docEP');

  const extractInfo = (res) => {
    const info = defaultProfileInfo;
    const aCalls = {};
    for (const para of ['name', 'address', 'phone']) {
      try {
        const event = res[0].events.filter(
          (x) => x.streamIds.includes(para),
        )[0];
        const eId = event.id;
        info[para] = event.content;

        aCalls[para] = async (content) => {
          const res = await pryvApiCall(
            apiEndpoint,
            updateEventCall(eId, content),
          );
          return res;
        };
      } catch (e) {
        aCalls[para] = async (content) => {
          const ts = Math.round((new Date()).getTime() / 1000);
          const res = await pryvApiCall(
            apiEndpoint,
            createEventCall([para], eventTypes[para], content, ts),
          );
          return res;
        };
      }
    };
    return [info, aCalls];
  };

  useEffect(() => {
    if (!isFetching && !hasFetched) {
      setIsFetching(true);
      pryvApiCall(apiEndpoint, eventsFromStreams(['profile']))
        .then( (res) => {
          const [info, ct] = extractInfo(res);
          setAccountInfo(info);
          setCalls(ct);

          setHasFetched(true);
          setIsFetching(false);
        });
    }
  }, [hasFetched]);
  const startEditing = () => {
    setEditing(true);
  };

  const exitWithSave = () => {
    const info = accountInfo;
    const aCalls = calls;
    for (const para of Object.keys(accountInfo)) {
      let ip;
      const op = accountInfo[para];
      if (para === 'phone') {
        ip = document.getElementById(para).value;
      } else {
        ip = {};
        for (const sp of Object.keys(accountInfo[para])) {
          if (sp === 'middle-name') {
            continue;
          }
          ip[sp] = document.getElementById(`${para}_${sp}`).value;
          if (sp === 'zip') {
            ip[sp] = Number(ip[sp]);
          }
        };
      };
      if (JSON.stringify(op) !== JSON.stringify(ip)) {
        calls[para](ip).then( (res) => {
          try {
            const eId = res[0].event.id;
            info[para] = res[0].event.content;
            aCalls[para] = async (content) => {
              const res = await pryvApiCall(
                apiEndpoint,
                updateEventCall(eId, content),
              );
              return res;
            };
          } catch (e) {
            console.log(e);
          };
        });
      };
    };
    setCalls(aCalls);
    setAccountInfo(info);
    setEditing(false);
  };

  const exitWithoutSaving = () => {
    setEditing(false);
    setWarningOpen(false);
    props.onClose();
  };

  const onDialogClose = () => {
    if (isEditing) {
      setWarningOpen(true);
      return;
    };
    props.onClose();
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        content={snackbar}
        handleClose={() => setSnackbar({ show: false })}/>
      <Dialog open={props.isOpen}
        fullWidth maxWidth="sm">
        <IconButton
          onClick={onDialogClose}
          className={classes.iconButton}
          style={{ position: 'absolute', right: 1, top: 1 }}>
          <MdOutlineClose />
        </IconButton>
        <DialogTitle>
          Account Information for
          <strong> {props.userInfo['.username']}</strong>
        </DialogTitle>
        <Divider style={{ marginBottom: 15 }}/>
        <DialogContent style={{ height: 380 }}>
          { hasFetched ?
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    label="First Name"
                    defaultValue={accountInfo.name['first-name']}
                    disabled={!isEditing}
                    fullWidth
                    id="name_first-name"/>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    variant="outlined"
                    label="Last Name"
                    defaultValue={accountInfo.name['last-name']}
                    disabled={!isEditing}
                    fullWidth
                    id="name_last-name"/>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    variant="outlined"
                    label="E-Mail"
                    defaultValue={props.userInfo['.email']}
                    disabled
                    fullWidth/>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    label="Phone No."
                    defaultValue={accountInfo.phone}
                    disabled={!isEditing}
                    fullWidth
                    id="phone"/>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 30 }}>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    label="Affiliation"
                    defaultValue={
                      affiliations[props.userInfo['.affiliation']].name
                    }
                    disabled
                    fullWidth />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    variant="outlined"
                    label="Street & No."
                    defaultValue={accountInfo.address.street}
                    disabled={!isEditing}
                    fullWidth
                    id="address_street"/>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    variant="outlined"
                    label="ZIP"
                    defaultValue={
                      (accountInfo.address.zip === 0) ? '' :
                        accountInfo.address.zip
                    }
                    disabled={!isEditing}
                    fullWidth
                    id="address_zip"/>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    label="City"
                    defaultValue={accountInfo.address.town}
                    disabled={!isEditing}
                    fullWidth
                    id="address_town"/>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    variant="outlined"
                    label="Country"
                    defaultValue={accountInfo.address.country}
                    disabled={!isEditing}
                    fullWidth
                    id="address_country"/>
                </Grid>
              </Grid>
              <Grid item xs={12} align="right" justify="center">
                <Divider style={{ marginTop: 20 }}/>
                { (!isEditing) ?
                  <Tooltip title="Edit Information">
                    <IconButton
                      onClick={startEditing}
                      className={classes.iconButton}>
                      <MdModeEdit/>
                    </IconButton>
                  </Tooltip> :
                  <div>
                    <Tooltip title="Save Changes">
                      <IconButton
                        className={classes.iconButton}
                        onClick={exitWithSave}>
                        <FaSave />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard Changes">
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => setEditing(false)}>
                        <FaTrashAlt />
                      </IconButton>
                    </Tooltip>
                  </div>
                }
              </Grid>
            </Grid>:
            <div
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                display: 'flex',
              }}>
              <CircularProgress color="secondary"
                style={{ position: 'absolute', top: '50%' }}/>
            </div>
          }
        </DialogContent>
      </Dialog>
      <Dialog open={warningOpen} fullWidth maxWidth="xs"
        style={{ zIndex: 1500 }}>
        <DialogTitle align="center">
          Warning
        </DialogTitle>
        <Divider/>
        <DialogContent>
          <DialogContentText>
            There may be unsaved changes, are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Button variant="contained"
            color="primary" onClick={exitWithoutSaving} style={{ margin: 10 }}>
            Yes
          </Button>
          <Button variant="contained" color="primary"
            onClick={() => setWarningOpen(false)} style={{ margin: 10 }}>
            No
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

AccountInfoDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userInfo: PropTypes.object,
};


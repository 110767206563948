import React from 'react';
import { PropTypes } from 'prop-types';
import { Radar } from 'react-chartjs-2';


/**
 * Renders a radar (spider web) chart of a patient.
 * @param {Object} props containing the patient data.
 * @return {jsx} The patient radar chart component.
 */
export default function PatientRadar(props) {
  const overall = props.overall.map((x) => Math.round(x * 100));

  const labels = [
    'Symmetry',
    'Consistency',
    'Endurance',
    'Activity',
  ];

  const radarData = {
    labels: labels.map( (x) => x[0]),
    datasets: [
      {
        label: 'Overall Score',
        lineTension: 0.0,
        backgroundColor: '#4ebfff33',
        borderColor: '#4ebfff',
        borderDashOffset: 0.5,
        pointBackgroundColor: '#4ebfff',
        pointBorderColor: '#fff',
        pointBorderWidth: 0.8,
        pointRadius: 3,
        pointHitRadius: 10,
        pointHoverBackgroundColor: '#4ebfff33',
        data: overall,
      },
    ],
  };

  const radarOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'top',
    },
    maintainAspectRatio: false,
    scale: {
      angleLines: {
        display: true,
      },
      beginAtZero: true,
      pointLabels: {
        fontSize: 14,
      },
      ticks: {
        display: false,
        max: 100,
        min: 0,
        stepSize: 25,
      },
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label: function(tooltipItem, chartData) {
          return `${labels[tooltipItem.index]} ${tooltipItem.label}`;
        },
        title: function(tooltipItem, data) {
          return;
        },
      },
    },
  };

  return (
    <Radar data={radarData} options={radarOptions} />
  );
};

PatientRadar.propTypes = {
  overall: PropTypes.array,
};


import React, { useEffect } from 'react';
import {
  Avatar,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core';

import {
  CgWebsite,
} from 'react-icons/cg';
import {
  BiCertification,
} from 'react-icons/bi';
import {
  BsFileRuledFill,
} from 'react-icons/bs';
import {
  MdOutlinePrivacyTip,
  MdPermContactCalendar,
  MdOutlineEmail,
} from 'react-icons/md';
import { useStyles } from '../useStyles.js';
import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

/**
 * Renders the "About" page. Gives general info about Magnes and Nushu.
 * @return {jsx} The about page component.
 */
function About() {
  const classes = useStyles();
  const swLabelPath = process.env.PUBLIC_URL + '/sw-labels-WEB.png';

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: window.location.pathname, title: 'About Page',
    });
  }, []);

  return (
    <Grid container spacing={5} justifyContent="center" align="center">
      <Grid item xs={3}>
        <Avatar className={classes.avatar} variant="rounded">
          <BiCertification style={{ fontSize: 30 }}/>
        </Avatar>
        <LinearProgress
          value={0} variant="determinate"
        />
        <div style={{ position: 'relative' }}>
          <Paper className={classes.paper} style={{ padding: 10 }}>
            <div style={{ height: 300 }}>
              <div style={{ padding: 10 }}>
                <Typography variant="overline" align="center"
                  style={{ fontSize: 14 }}>
                  Medical Certification
                </Typography>
                <Divider style={{ margin: 5 }}/>
                <Typography style={{ fontSize: 14 }} align="center">
                  Together under the guidance of EffectumMedical, the Nushu gait
                  analysis system is certified as a grade II medical product by
                  swissmedic.
                  The certification label is shown below.
                </Typography>
                <Divider style={{ marginTop: 5, marginBottom: 5 }}/>
              </div>
              <div style={{ position: 'relative', width: '80%' }}>
                <img src={swLabelPath} width="40%" />
              </div>
            </div>
          </Paper>
          <div style={{
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
          }}>
            <div style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: 20,
              backgroundColor: 'white',
              borderWidth: 10,
              borderColor: 'black',
            }}>
              <Typography style={{ fontSize: 20 }}>
                Currently intended for
              </Typography>
              <Typography color="secondary" style={{ fontSize: 25 }}>
                <strong>Clinical Research</strong>
              </Typography>
              <Divider style={{ margin: 10 }}/>
              <Typography style={{ fontSize: 20 }}>
                Medical certification is in the works.
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Avatar className={classes.avatar} variant="rounded">
          <MdOutlinePrivacyTip style={{ fontSize: 30 }}/>
        </Avatar>
        <LinearProgress
          value={0} variant="determinate"
        />
        <Paper className={classes.paper} style={{ padding: 10 }}>
          <div style={{ height: 300 }}>
            <div style={{ padding: 5, marginTop: 5 }}>
              <Typography variant="overline" align="center"
                style={{ fontSize: 14 }}>
                Data Privacy
              </Typography>
            </div>
            <Divider style={{ margin: 5 }}/>
            <div style={{ padding: 10 }}>
              <Typography style={{ fontSize: 16 }} align="center">
                We give the utmost your and your patients&apos; data privacy and
                security. That is why we built our platform on
                Pryv, which is a Swiss data & privacy management software. For
                more information on them and our data privacy policy see the
                links below.
              </Typography>
            </div>
            <Divider style={{ marginBottom: 15 }}/>
            <Grid container align="center" justifyContent="center" spacing={0}>
              <Grid item xs={5}>
                <a href="https://www.pryv.com/" target="_blank" rel="noreferrer noopener">
                  <img src="https://www.pryv.com/wp-content/themes/pryv2019/assets/img/Logo-Pryv@3x.svg"/>
                </a>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation="vertical"/>
              </Grid>
              <Grid item xs={5} align="center"
                style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '50%',
                  transform: 'translateY(-50%)' }}>
                  <p>
                    <a href="https://www.magnes.ch/magnes_privacy_notice_nushu.html"
                      target="_blank" rel="noreferrer noopener"
                      style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<BsFileRuledFill/>}>
                        Our Policy
                      </Button>
                    </a>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Avatar className={classes.avatar} variant="rounded">
          <MdPermContactCalendar style={{ fontSize: 30 }}/>
        </Avatar>
        <LinearProgress
          value={0} variant="determinate"
        />
        <Paper className={classes.paper}>
          <div style={{ height: 300 }}>
            <div style={{ padding: 5, marginTop: 5 }}>
              <Typography variant="overline" align="center"
                style={{ fontSize: 14 }}>
                Contact Us
              </Typography>
            </div>
            <Divider style={{ margin: 5 }}/>
            <div style={{ padding: 10 }}>
              <Typography style={{ fontSize: 16 }} align="center">
                Feel free to touch bases with us, with any questions, bug
                reports and/or feedback you may have.
              </Typography>
            </div>
            <Divider style={{ marginBottom: 15 }}/>
            <Grid container spacing={2} align="center"
              justifyContent="center">
              <Grid item xs={5}>
                <Typography style={{ fontSize: 14 }} align="right">
                  MAGNES AG <br/>
                  Selnaustrasse 5 <br/>
                  8001 Zürich <br/>
                  Switzerland <br/>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item xs={5} align="left"
                style={{ position: 'relative' }}>
                <a href='mailto:support@magnes.ch'>
                  <IconButton color="primary"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translate(0%, -50%)',
                    }}>
                    <MdOutlineEmail style={{ fontSize: 40 }}/>
                  </IconButton>
                </a>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Avatar className={classes.avatar} variant="rounded">
          <CgWebsite style={{ fontSize: 25 }}/>
        </Avatar>
        <LinearProgress
          value={0} variant="determinate"
        />
        <iframe
          src='https://www.magnes.ch/nushu/'
          title="NUSHU by Magnes AG"
          height={window.innerHeight - 150}
          width='100%'
        />
      </Grid>
    </Grid>
  );
}

export default About;

import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import CalendarHeatmap from 'react-calendar-heatmap';
import ReactTooltip from 'react-tooltip';

import {
  Divider,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckedIcon from '@material-ui/icons/AssignmentTurnedIn';
import WarningIcon from '@material-ui/icons/AssignmentLate';

import {
  MdVibration,
} from 'react-icons/md';

import '../custom.css';
import { useStyles } from '../useStyles';
import CustomSnackbar from './CustomSnackbar';
import CalendarLegend from './CalendarLegend';

import {
  makeDateString,
  dateTimeString,
} from '../utils/auxFunctions.js';
import { vibId2TitleMap } from '../definitions/vibrationTypes.js';
import { id2TitleMap } from '../definitions/activityTypes.js';

/**
 * Renders the "CalendarHistory" component.
 * @return {jsx} The patient page component.
 */
export default function CalendarHistory({ activities }) {
  const classes = useStyles();
  const history = useHistory();


  const [snackbar, setSnackbar] = React.useState({
    message: '',
    severity: '',
    show: false,
  });
  const [isExpanded, setExpanded] = React.useState(false);
  const [dispList, setDispList] = React.useState(activities);
  const [listTitle, setListTitle] = React.useState('All Assessments');

  // eslint-disable-next-line max-len

  const today = new Date();

  const handleClick = (value) => {
    if (!value) {
      setSnackbar({
        message: 'There was no assessment on that day.',
        severity: 'warning',
        show: true,
      });

      setDispList(activities);
      setListTitle('All Assessments');
      setExpanded(false);
    } else {
      setSnackbar({
        // eslint-disable-next-line max-len
        message: `${value.count} items on ${makeDateString(value.date)}`,
        severity: 'info',
        show: true,
      });
      setDispList(value.list);
      setListTitle(`Assessments on ${makeDateString(value.date)}`);
      setExpanded(true);
    };
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(false);
  };

  const calendarContainer = useRef(null);
  const handleClickOutside = (event) => {
    if (
      calendarContainer.current &&
      !calendarContainer.current.contains(event.target)
    ) {
      setExpanded(false);
    };
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  const toAssessmentClick = (x) => {
    history.push({ pathname: '/assessment/' + x.content['activity-id'] });
  };

  const readTime = (utcTime) => {
    const dt = new Date(utcTime * 1000);
    return dateTimeString(dt).split('@')[1];
  };

  const scrollContainer = useRef(null);
  useEffect(() => {
    scrollContainer.current.scrollLeft = 1000;
  }, []);

  /* eslint-disable max-len*/
  return (
    <React.Fragment>
      <div style={{ position: 'relative' }} ref={calendarContainer}>
        <div
          hidden={!isExpanded}
          style={{
            position: 'fixed',
            top: 'calc(22vh + 200px)',
            right: '32vw',
            zIndex: 100,
          }}>
          <Paper elevation={10}
            style={{
              width: 400,
              overflow: 'hidden',
            }}>
            <div
              style={{
                backgroundColor: 'lightgrey',
                diplay: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 40,
              }}>
              <Typography variant="body2" color="primary"
                style={{
                  top: '50%',
                  transform: 'translate(0%, 50%)',
                }}>
                {listTitle}
              </Typography>
            </div>
            <div
              style={{
                minHeight: 100,
                maxHeight: 220,
                overflow: 'scroll',
                padding: 5,
                paddingTop: 0,
              }}>
              <List dense>
                {dispList.map((x) =>
                  <React.Fragment key={x.id}>
                    <ListItem
                      button={x.content.isAnalyzed}
                      onClick={() => {
                        if (!x.content.isAnalyzed) {
                          return;
                        }
                        toAssessmentClick(x);
                      }}
                      style={{ position: 'relative' }}>
                      <div style={{ paddingRight: 5, paddingLeft: 5 }}>
                        {x.content.isAnalyzed ?
                          <CheckedIcon className={classes.successColor} /> :
                          <WarningIcon className={classes.warnColor} />
                        }
                      </div>
                      <Typography variant="subtitle1" color="textSecondary"
                        style={{ fontSize: 13 }}>
                        {readTime(x.time)}
                      </Typography>
                      <Typography style={{ color: 'white' }}>
                        {'_'}
                      </Typography>
                      <Typography variant="subtitle1" style={{ fontSize: 13 }}>
                        {id2TitleMap(x.content.title)}
                      </Typography>
                      { x.content.vibrationType &&
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <Typography align="center"
                            variant="h3" color="primary"
                            style={{
                              fontSize: 13,
                              marginLeft: 5,
                            }}>
                            +
                          </Typography>
                          <Tooltip
                            title={
                              vibId2TitleMap(x.content.vibrationType)
                            }>
                            <div style={{ display: 'flex' }}>
                              <MdVibration
                                style={{
                                  marginLeft: 5,
                                  fontSize: 13,
                                }}/>
                            </div>
                          </Tooltip>
                        </div>
                      }
                      { x.content.isAnalyzed &&
                        <ChevronRightIcon align="right"
                          className={classes.primaryColor}
                          style={{ position: 'absolute', right: 10 }}/>
                      }
                    </ListItem>
                    <Divider />
                  </React.Fragment>)}
              </List>
            </div>
          </Paper>
        </div>
        <div style={{ height: 160, overflow: 'scroll' }} ref={scrollContainer}>
          <div style={{ width: 1000, right: 0 }}>
            <CalendarHeatmap
              startDate={shiftDate(today, -364)}
              endDate={today}
              values={getDateActivityCount(activities)}
              showWeekdayLabels={false}
              classForValue={(value) => {
                if (!value) return 'color-empty';
                if (value.count < 5) return 'color-gitlab-1';
                if (value.count < 10) return 'color-gitlab-2';
                if (value.count < 20) return 'color-gitlab-3';
                return 'color-gitlab-4';
              }}
              tooltipDataAttrs={(value) => {
                if (!value.date || !value.count) {
                  return;
                }
                let date;
                if (value.date instanceof Date) {
                  date = makeDateString(value.date);
                }
                const s = value.count === 1 ? '' : 's';
                return {
                  'data-tip': `${value.count} assessment${s} <br /> on ${date}`,
                };
              }}
              onClick={(value) => handleClick(value)}
            />
          </div>
        </div>
        <CalendarLegend />
        <ReactTooltip html={true} />
        {snackbar.show &&
        <CustomSnackbar
          content={snackbar}
          handleClose={handleClose}
        />}
      </div>
    </React.Fragment>
  );
}

/**
 * Checks if two dates are on the same day
 @param {Date} first first date
 @param {Date} second second date
 @return {Bool} if the first and second date refer to the same day.
*/
function datesAreOnSameDay(first, second) {
  return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
}

/**
 * Gets the number of activities per date.
 @param {Arr} activityArray of all activities
 @return {Arr} of dates with the number of activities for each.
*/
function getDateActivityCount(activityArray) {
  const dateActivityCount = [];
  activityArray.forEach( (item) => {
    const iDate = new Date(item.time * 1000);
    const idx = dateActivityCount
      .findIndex((dac) => datesAreOnSameDay(dac.date, iDate));
    if (idx === -1) {
      dateActivityCount.push({ date: iDate, count: 1, list: [item] });
    } else {
      dateActivityCount[idx].count++;
      dateActivityCount[idx].list.push(item);
    }
  });
  return dateActivityCount;
}


/**
 * Shifts a date by the given amount of days.
 * @param {date} date The initial date.
 * @param {int} days The days to count back.
 * @return {date} The shifted date.
 */
function shiftDate(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

CalendarHistory.propTypes = {
  activities: PropTypes.array,
};

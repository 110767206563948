import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LastPageIcon from '@material-ui/icons/LastPage';
import CompareIcon from '@material-ui/icons/Compare';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import {
  FaCalendarAlt,
  FaShoePrints,
  FaTags,
  FaRoute,
} from 'react-icons/fa';
import {
  MdAssessment,
} from 'react-icons/md';
import {
  VscListSelection,
} from 'react-icons/vsc';
import {
  AiOutlineExpandAlt,
} from 'react-icons/ai';

import { useStyles } from '../useStyles';

import {
  makeDistanceString,
  makeTimeString,
  loadPatientData,
} from '../utils/auxFunctions.js';

import PatientRadar from '../components/PatientRadar.js';
import CalendarHistory from '../components/CalendarHistory.js';
import PatientTimeline from '../components/PatientTimeline.js';
import TrendChart from '../components/TrendChart.js';
import TrendChartExtended from '../components/TrendChartExtended.js';
import PatientInfo from '../components/PatientInfo.js';
import CommentField from '../components/CommentField.js';
import ActivityTypeToggle from '../components/ActivityTypeToggle.js';
import AssessmentListDialog from '../components/AssessmentListDialog.js';
import ExtendedPatientInfoDialog from '../components/ExtendedPatientInfoDialog';

import {
  pryvApiCall,
  eventsFromStreams,
  allActivitiesMetadataCall,
  statsApiCall,
} from '../data/apiCalls.js';
import {
  formatRawStats,
} from '../utils/dataFetchFunctions.js';
import { gaitParameters } from '../definitions/gaitParameters.js';
import {
  vibrationTypes,
} from '../definitions/vibrationTypes.js';

import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

const trendTypes = [
  {
    'stat-id': 'steps',
    'title': 'Steps',
    'icon': FaShoePrints,
    'unit': '',
  },
  {
    'stat-id': 'assessments',
    'title': 'Assessments',
    'icon': FaTags,
    'unit': '',
  },
].concat(gaitParameters);

/**
 * Renders the "Patient" page. Displays information about a patient.
 * @param {Object} props of the page of which patient data is the main thing.
 * @return {jsx} The patient page component.
 */
function Patient(props) {
  const classes = useStyles();
  const history = useHistory();

  // Check storage for stored patient's data.
  const patientData = loadPatientData();

  const getIntialView = () => {
    const dsl = patientData.daysSinceLast;
    if (dsl <= 7) {
      return 'weekly';
    };
    if (7 < dsl <= 14) {
      return 'monthly';
    };
    if (14 > dsl) {
      return 'yearly';
    };
  };

  /* eslint-disable max-len */
  const [hasAssessments, setHasAssessments] = useState(false);
  const [trendView, setTrendView] = useState(getIntialView());
  const [resLoaded, setResLoaded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [activityType, setActivityType] = useState('overall-stats');
  const [openAlDialog, setOpenAlDialog] = useState(false);
  const [openEPDialog, setOpenEPDialog] = useState(false);
  /* eslint-enable max-len */

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: '/patient', title: 'Patient Page',
    });
  }, []);

  useEffect( async () => {
    const tk = patientData.apiToken;
    if (!isLoading) {
      setLoading(true);

      Promise.all([
        pryvApiCall(tk, statsApiCall),
        pryvApiCall(tk, allActivitiesMetadataCall),
        pryvApiCall(tk, eventsFromStreams(['macro-events'])),
      ]).then((res) => {
        console.log(res);
        // Format all stats
        patientData.stats = formatRawStats(res[0]);

        // Format activities if analyzed or not + vib mode
        const activities = res[1][0].events;
        const vtps = vibrationTypes.map((vt) => vt.id);
        activities.map((act) => {
          if (act.streamIds.includes('analyzed')) {
            act.content.isAnalyzed = true;
          } else {
            act.content.isAnalyzed = false;
          }
          act.streamIds.map((sid) => {
            if (vtps.includes(sid)) {
              act.content.vibrationType = sid;
            };
          });
        });

        if (activities.length > 0) {
          setHasAssessments(true);
        }
        patientData.activities = activities;

        // Set patient's macro events
        patientData.macroEvents = res[2][0].events;

        sessionStorage.setItem(
          'patientData', JSON.stringify(patientData),
        );
        setLoading(false);
        setResLoaded(true);
      });
    };
    // check which patient was looked at last
    const lastLookedAtPatient = sessionStorage.getItem('lastPatient');
    // if it's a different user, remove the list of marked activities
    if (lastLookedAtPatient !== patientData.username) {
      sessionStorage.removeItem('markedActivities');
      sessionStorage.setItem('lastPatient', patientData.username);
    }
  }, []);

  const getRadarData = () => {
    const os = patientData['stats']['overall-stats'];
    const rd = [
      os.symmetry,
      (1 - os.consistency >= 0) ? 1 - os.consistency: 0,
      Math.abs((1 - os.endurance)),
      1 /(1 + Math.exp(-4 * (os.activity - 1))),
    ];

    rd.forEach((item, i) => {
      if (item < 0) {
        radarData[i] = 0;
      };
    });
    return rd;
  };

  const makeWeeklyLabels = () => {
    const weekDays = [
      'SUN', 'MON', 'TUE',
      'WED', 'THU', 'FRI',
      'SAT'];

    const weeklyLabels = [...Array(7)].map((_, i) => {
      const day = new Date();
      day.setDate(day.getDate() - i);
      return weekDays[day.getDay()];
    });
    return weeklyLabels.reverse();
  };

  const handleTrendViewChange = (event) => {
    setTrendView(event.currentTarget.value);
  };

  const fullName = patientData['firstName'] + ' ' + patientData['lastName'];

  const toAssessmentClick = () => {
    if (isLoading) {
      return;
    };
    if (patientData.activities.length === 0) {
      setDialogOpen(true);
    } else {
      const ac = patientData.activities.filter((act) => act.content.isAnalyzed);
      history.push({
        pathname: '/assessment/' + ac[0].content['activity-id'],
        state: {
          aMetaData: ac,
          currentIdx: 0,
        },
      });
    }
  };

  const toCompareClick = () => {
    if (isLoading) {
      return;
    };
    const ac = patientData.activities.filter((act) => act.content.isAnalyzed);
    if (ac.length < 2) {
      return;
    }
    const name = `${patientData.firstName}_${patientData.lastName}`;
    history.push({
      pathname: '/compare/' + name,
    });
  };

  const plotDefMap = {
    'weekly': {
      tm: -7,
      lb: makeWeeklyLabels(),
    },
    'monthly': {
      tm: -28,
      lb: Array.from({ length: 28 }, (v, i) => {
        const dayInMs = 86400000;
        const date = new Date((new Date().getTime() - i * dayInMs));
        const ds = date.toISOString()
          .slice(5, 10)
          .split('-')
          .reverse()
          .join('.');

        return ds;
      }).reverse(),
    },
    'yearly': {
      tm: -365,
      lb: Array.from({ length: 365 }, (v, i) => {
        const dayInMs = 86400000;
        const date = new Date((new Date().getTime() - i * dayInMs));
        const ds = date.toISOString()
          .slice(5, 10)
          .split('-')
          .reverse()
          .join('.');

        return ds;
      }).reverse(),
    },
  };

  const getTotalStats = () => {
    const os = patientData['stats']['overall-stats'];
    return [
      {
        title: 'Total Steps',
        id: 'total-steps',
        icon: FaShoePrints,
        total: {
          value: os['total-steps'],
          unit: '',
        },
        new: {
          value: os['new-steps'],
          unit: '',
        },
      },
      {
        title: 'Total Assessments',
        id: 'total-assessmets',
        icon: FaTags,
        total: {
          value: os['total-assessments'],
          unit: '',
        },
        new: {
          value: os['new-assessments'],
          unit: '',
        },
      },
      {
        title: 'Total Distance',
        id: 'total-distance',
        icon: FaRoute,
        total: makeDistanceString(os['total-distance']),
        new: makeDistanceString(os['new-distance']),
      },
      {
        title: 'Total Duration',
        id: 'total-duration',
        icon: FaTags,
        total: makeTimeString(os['total-duration']),
        new: makeTimeString(os['new-duration']),
      },
    ];
  };

  /* eslint-disable max-len */
  return (
    (!resLoaded) ?
      <React.Fragment>
        <div
          style={{
            position: 'absolute', left: '50%', top: '40%',
            transform: 'translate(-50%, -50%)',
          }}>
          <CircularProgress color="secondary"/>
        </div>
      </React.Fragment>:
      <React.Fragment>
        <div
          style={{
            width: 'calc(100vw - 20px)',
            height: 'calc(100vh - 120px)',
            position: 'absolute',
            left: 10,
            top: 100,
            overflow: 'hidden',
          }}>
          <Grid container spacing={2}
            justifyContent="center" align="center">
            <Grid item xs={8} style={{ position: 'relative' }}>
              { !hasAssessments ?
                <div className={classes.margin}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    left: 0,
                  }}>
                  <div className={classes.margin}
                    style={{
                      position: 'relative',
                      transform: 'translate(0%, 300%)',
                    }}>
                    <ErrorOutlineIcon
                      style={{ fontSize: 60 }}
                      className = { classes.errorColor }/>
                    <Typography color="primary">
                        Currently, {fullName} has no analysis results
                        in the database.
                    </Typography>
                  </div>
                </div>:
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <LinearProgress value={0} variant="determinate"/>
                    <Paper className={classes.paper}
                      style={{ paddingBottom: 10 }}>
                      <div
                        style={{
                          marginTop: 5,
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <Typography align="center" variant="overline"
                          style={{ fontSize: 13 }}>
                            Gait Progression
                        </Typography>
                      </div>
                      <Divider />
                      <div style={{ height: `calc(100vh - ${255}px)`,
                        overflow: 'scroll' }}>
                        <Grid container justifyContent="center" align="center" spacing={2}
                          style={{ width: '100%' }}>
                          {trendTypes.map( (x, idx) => {
                            const defs = plotDefMap[trendView];
                            const data = patientData['stats'][activityType][x['stat-id']];
                            const lrData = Object.keys(data).includes('values-left');
                            const Icon = x.icon;
                            return (
                              <Grid item xs={6} key={`trend-chart-${idx}`}>
                                <Avatar variant="rounded"
                                  style={{
                                    top: 16,
                                    width: 30,
                                    height: 30,
                                    zIndex: 10,
                                    marginTop: 0,
                                    marginBottom: 0,
                                  }}>
                                  <Icon style={{ fontSize: 18 }}/>
                                </Avatar>
                                <LinearProgress value={0} variant="determinate"/>
                                <Paper className={classes.paper}>
                                  <div
                                    style={{
                                      marginTop: 5,
                                      height: 170,
                                    }}>
                                    <Typography align="center" variant="overline"
                                      style={{ fontSize: 9 }}>
                                      {x.title}
                                    </Typography>
                                    <div style={{ height: 160 }}>
                                      {lrData ?
                                        <TrendChartExtended
                                          leftData={data['values-left'].slice(defs.tm)}
                                          rightData={data['values-right'].slice(defs.tm)}
                                          labels={defs.lb}
                                          yAxisLabel={`${x.title} [${x.unit}]`}
                                        /> :
                                        <TrendChart
                                          dataLabel={x.title}
                                          dataValues={data['values'].slice(defs.tm)}
                                          labels={defs.lb}
                                          yAxisLabel={x.title}
                                        />
                                      }
                                    </div>
                                  </div>
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                      <Divider />
                      <div style={{ marginTop: 15 }}>
                        <Grid container align="center" justifyContent="center">
                          <Grid item xs={5}>
                            <ActivityTypeToggle
                              activityType={activityType}
                              setActivityType={setActivityType}
                              size={20}
                              popperPlacement="top"/>
                          </Grid>
                          <Grid item xs={2} style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}>
                              <Typography
                                style={{
                                  fontSize: 15,
                                }}>
                                Filters
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={5}
                            style={{ position: 'relative' }}>
                            <ButtonGroup
                              color="primary"
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}>
                              <Tooltip placement="top" title="Show weekly progress" arrow>
                                <Button
                                  value="weekly"
                                  onClick={handleTrendViewChange}
                                  variant={trendView === 'weekly' ?
                                    'contained' : 'outlined'}
                                  style={{ fontSize: 12 }}
                                >
                              weekly
                                </Button>
                              </Tooltip>
                              <Tooltip placement="top" title="Show monthly progress" arrow>
                                <Button
                                  value="monthly"
                                  onClick={handleTrendViewChange}
                                  variant={trendView === 'monthly' ?
                                    'contained' : 'outlined'}
                                  style={{ fontSize: 12 }}
                                >
                              monthly
                                </Button>
                              </Tooltip>
                              <Tooltip placement="top" title="Show yearly progress" arrow>
                                <Button
                                  value="yearly"
                                  onClick={handleTrendViewChange}
                                  variant={trendView === 'yearly' ?
                                    'contained' : 'outlined'}
                                  style={{ fontSize: 12 }}
                                >
                              yearly
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </Grid>
                        </Grid>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={12}>
                        <LinearProgress value={0} variant="determinate"/>
                        <Paper className={classes.paper}
                          style={{ paddingBottom: 20 }}>
                          <div style={{ marginTop: 5 }}>
                            <Typography align="center" variant="overline"
                              style={{ fontSize: 13 }}>
                            Summary Stats
                            </Typography>
                            <Divider />
                          </div>
                          <div
                            style={{
                              maxHeight: 300,
                              overflow: 'scroll',

                            }}>
                            <Grid container spacing={0} align="center" justifyContent="center">
                              { getTotalStats().map( (x) => {
                                const Icon = x.icon;
                                return (
                                  <Grid item xs={6} key={x.id}>
                                    <Avatar variant="rounded"
                                      style={{
                                        top: 16,
                                        width: 30,
                                        height: 30,
                                        zIndex: 10,
                                        marginTop: 0,
                                        marginBottom: 0,
                                      }}>
                                      <Icon style={{ fontSize: 18 }}/>
                                    </Avatar>
                                    <LinearProgress value={0} variant="determinate"/>
                                    <Paper className={classes.paper} style={{ paddingBottom: 0 }}>
                                      <div style={{ marginTop: 5 }}>
                                        <Typography align="center" variant="overline"
                                          style={{ fontSize: 9 }}>
                                          {x.title}
                                        </Typography>
                                      </div>
                                      <Typography align="center"
                                        variant="h3" color="secondary"
                                        style={{ fontSize: 20 }}>
                                        {x.total.value}<a className={classes.cardUnit}>{x.total.unit}</a>
                                      </Typography>
                                      {(Number(x.new.value) !== 0) ?
                                        <Typography align="center"
                                          className={classes.cardTrendSuccess}
                                          style={{ fontSize: 10 }}>
                                          {`+${x.new.value} ${x.new.unit}`}
                                        </Typography>:
                                        <Typography align="center"
                                          style={{ fontSize: 10, color: 'transparent' }}>
                                        `xx`
                                        </Typography>
                                      }
                                    </Paper>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </div>
                        </Paper>
                      </Grid>
                      <Grid item xs={12}>
                        <LinearProgress value={0} variant="determinate"/>
                        <Paper className={classes.paper}
                          style={{
                            paddingBottom: 0,
                            position: 'relative',
                          }}>
                          <Typography align="center" variant="overline"
                            style={{ fontSize: 13 }}>
                          Timeline
                          </Typography>
                          <Divider />
                          <div
                            style={{
                              height: `calc(100vh - ${495}px)`,
                              marginTop: 5,
                              position: 'relative',
                            }}>
                            <PatientTimeline
                              patApiEndPoint={patientData.apiToken}
                              firstAssDate = {
                                new Date(patientData.activities.slice(-1)[0].time * 1000)
                              }
                              regDate = {patientData.regDate}/>
                          </div>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={4}>
              <LinearProgress
                value={0} variant="determinate"
              />
              <Paper
                style={{
                  padding: 10,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <div style={{ marginTop: -5, marginBottom: 10 }}>
                  <Typography align="center" variant="overline"
                    style={{ fontSize: 10 }}>
                    {`${patientData.username}`}
                  </Typography>
                  <Typography align="center" variant="h6"
                    style={{ fontSize: 15, marginTop: -5 }}>
                    {fullName}
                  </Typography>
                  <Divider />
                </div>
                <div
                  style={{
                    height: `calc(80vh - 120px)`,
                    overflow: 'scroll',
                    padding: 10,
                  }}>
                  <Grid container align="center" justifyContent="center" spacing={2}
                    style={{
                      marginTop: '0px',
                      marginBottom: '3px',
                    }}>
                    <Grid item xs={12}
                      style={{
                        marginTop: '-15px',
                        marginBottom: '-10px',
                      }}>
                      <Grid container justifyContent="center">
                        <Grid item xs={4}>
                          <Avatar
                            variant="rounded"
                            style={{
                              top: 16,
                              width: 30,
                              height: 30,
                              zIndex: 10,
                              marginTop: -10,
                            }}>
                            <MdAssessment style={{
                              fontSize: 18,
                            }}/>
                          </Avatar>
                          <LinearProgress value={0} variant="determinate"/>
                          <Paper className={classes.paper}
                            style={{
                              height: '85%',
                            }}>
                            <div style={{ marginTop: 5 }}>
                              <Typography align="center" variant="overline"
                                style={{ fontSize: 9 }}>
                                Performance Scores
                              </Typography>
                            </div>
                            <div
                              style={{ marginTop: 5 }}>
                              <PatientRadar
                                overall = {getRadarData()} />
                            </div>
                          </Paper>
                        </Grid>
                        <Grid item xs={8}>
                          <Avatar
                            variant="rounded"
                            style={{
                              top: 16,
                              width: 30,
                              height: 30,
                              zIndex: 10,
                              marginTop: -10,
                            }}>
                            <AccountCircleIcon style={{
                              fontSize: 18,
                            }}/>
                          </Avatar>
                          <LinearProgress value={0} variant="determinate"/>
                          <Paper className={classes.paper}
                            style={{
                              height: '85%',
                              position: 'relative',
                            }}>
                            <div style={{ marginTop: 5 }}>
                              <Typography align="center" variant="overline"
                                style={{ fontSize: 9 }}>
                                Info
                              </Typography>
                            </div>
                            <PatientInfo />
                            <IconButton
                              color="primary"
                              onClick={() => setOpenEPDialog(true)}
                              style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                              }}>
                              <AiOutlineExpandAlt />
                            </IconButton>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Avatar
                        variant="rounded"
                        className={classes.summaryStatsAvatar}>
                        <FaCalendarAlt style={{
                          fontSize: 18,
                        }}/>
                      </Avatar>
                      <LinearProgress value={0} variant="determinate"
                        className={classes.summaryStatsLinearProgress}/>
                      <Paper className={classes.paper}
                        style={{
                          overflow: 'visible',
                          position: 'relative',
                        }}>
                        <div style={{ marginTop: 5 }}>
                          <Typography align="center" variant="overline"
                            style={{ fontSize: 9 }}>
                            Calendar
                          </Typography>
                        </div>
                        <CalendarHistory
                          activities={patientData.activities}
                        />
                      </Paper>
                    </Grid>
                    <Grid item align="center" xs={12}>
                      <React.Fragment>
                        <CommentField
                          apiToken={patientData.apiToken}
                          title="Patient Comments"
                          key={'P-CommentField'}/>
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </div>
              </Paper>

              {hasAssessments &&
                <div style={{ marginTop: 20 }}>
                  <Grid container algin="center" justifyContent="center"
                    spacing={1}>
                    <Grid item align="center" xs={4}>
                      <Paper style={{ height: '100%', padding: 5 }}>
                        <Grid container algin="center" justifyContent="center"
                          style={{ height: '100%', padding: 0 }}>
                          <Grid item xs={12}>
                            <Typography variant="overline" align="center"
                              style={{ fontSize: 13 }}>
                              Plot Labels
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
                              <div style={{ position: 'absolute', bottom: 5 }}>
                                <Typography variant="body2" style={{ fontSize: 12 }}>
                                  Left
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={3} align="center" style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                backgroundColor: '#008fcc',
                                height: 30,
                                width: 30,
                                bottom: 5,
                                right: 6,
                              }} />
                          </Grid>
                          <Grid item xs={1} style={{ position: 'relative' }}>
                            <Divider orientation="vertical" style={{ height: 30, position: 'absolute', bottom: 5, left: 4 }} />
                          </Grid>
                          <Grid item xs={3} align="center" style={{ position: 'relative' }}>
                            <div
                              style={{
                                position: 'absolute',
                                backgroundColor: '#4ebfff',
                                height: 30,
                                width: 30,
                                left: 2,
                                bottom: 5,
                              }} />
                          </Grid>
                          <Grid item xs={2}>
                            <div style={{ display: 'flex', height: '100%', position: 'relative' }}>
                              <div style={{ position: 'absolute', bottom: 5 }}>
                                <Typography variant="body2"
                                  style={{ fontSize: 12 }}>
                                  Right
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item align="center" xs={8}>
                      <Paper
                        style={{
                          padding: 10,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                        }}>
                        <Grid item xs={2} align="right">
                          <Tooltip title="Comparison Tool">
                            <IconButton
                              color="primary"
                              onClick={toCompareClick}>
                              <CompareIcon style={{ fontSize: 35 }}/>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1} align="center" style={{ height: '80%' }}>
                          <Divider orientation="vertical"
                            style={{ height: '100%' }}/>
                        </Grid>
                        <Grid item xs={2} align="center">
                          <Tooltip title="Select Specific Assessment">
                            <IconButton
                              color="primary"
                              onClick={() => setOpenAlDialog(true)}>
                              <VscListSelection style={{ fontSize: 25 }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={1} align="center" style={{ height: '80%' }}>
                          <Divider orientation="vertical"
                            style={{ height: '100%' }}/>
                        </Grid>
                        <Grid item xs={2} align="left">
                          <Tooltip title="Go to latest">
                            <IconButton
                              variant="contained"
                              color="primary"
                              onClick={toAssessmentClick}>
                              <LastPageIcon style={{ fontSize: 35 }}/>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
              }
            </Grid>
          </Grid>
        </div>
        <AssessmentListDialog
          open={openAlDialog}
          setOpen={setOpenAlDialog}
          type="C"
        />
        <ExtendedPatientInfoDialog
          open={openEPDialog}
          setOpen={setOpenEPDialog}
        />
      </React.Fragment>
  );
};

export default Patient;

/* eslint max-len: ["error", { "ignoreStrings": true }] */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  CssBaseline,
  Divider,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  FaBookMedical,
  FaCalendarAlt,
  FaClock,
  FaWalking,
  FaShoePrints,
  FaRoute,
  FaHourglassHalf,
  FaTachometerAlt,
  FaPercentage,
  FaStopwatch,
  FaAngleLeft,
  FaAngleRight,
  FaFileDownload,
} from 'react-icons/fa';
import {
  BsBookmarkStarFill,
  BsBookmarkStar,
} from 'react-icons/bs';
import {
  BiArrowFromBottom,
} from 'react-icons/bi';
import {
  VscListSelection,
} from 'react-icons/vsc';
import {
  MdVibration,
} from 'react-icons/md';


import { useStyles } from '../useStyles';
import GaitParameterCard from '../components/GaitParameterCard';
import CustomSnackbar from '../components/CustomSnackbar.js';
import RowSelectorButtonGroup from '../components/RowSelectorButtonGroup.js';
import CommentField from '../components/CommentField.js';
import AssessmentListDialog from '../components/AssessmentListDialog.js'
import VariabilityChartDialog from '../components/VariabilityChartDialog.js';

import {
  pryvApiCall,
  assessmentCall,
  eventsFromStreams,
} from '../data/apiCalls.js';
import {
  formatAssessmentData,
} from '../utils/dataFetchFunctions.js';
import {
  fetchPdf,
  annotatePdf,
  downloadPdf,
} from '../utils/pdfTools.js';

import {
  makeTimeString,
  makeDistanceString,
  makeDateString,
  loadPatientData,
  loadDoctorData,
  loadAssessmentComment,
} from '../utils/auxFunctions.js';
import {
  sumStatistics,
} from '../utils/mathUtils.js';
import { referenceRanges } from '../definitions/gaitParameterRefRanges.js';
import { gaitParameters } from '../definitions/gaitParameters.js';
import { id2TitleMap, title2IdMap } from '../definitions/activityTypes.js';

import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

/**
 * Displays information about a single assessment.
 * @return {jsx} The assessment page component.
 */
function Assessment(props) {
  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const [isFetching, setFetching] = useState(false);
  const [isFetched, setFetched] = useState(false);
  const [analysisData, setAnalysisData] = useState(undefined);
  const [pdfData, setPdfData] = useState(null);
  const [selectedRow, setSelectedRow] = useState(0);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [markedForComparison, setMarkedForComparison] = useState(false);
  const [openSpiderPlot, setOpenSpiderPlot] = useState(false);

  const patientData = loadPatientData();
  const patientName = patientData.firstName + ' ' + patientData.lastName;
  const patientAge = patientData.age;
  const patientSex = patientData.sex;
  const patientHeight = patientData.height;

  const doctorData = loadDoctorData();

  const activityID = location.pathname.split('/').reverse()[0];
  const apiToken = patientData.apiToken;

  const aMetaData = patientData.activities.filter(
    (item) => item.content.isAnalyzed,
  );

  const currentIdx = aMetaData.findIndex(
    (a) => a.content['activity-id'] === activityID,
  );

  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: '/assessment', title: 'Assessment Page',
    });
  }, [activityID]);

  useEffect( () => {
    setSelectedRow(0);
    setOpenDialog(false);
    setFetching(false);
    // check if the new current index was added to marked activities
    const markedActs = JSON.parse(sessionStorage.getItem('markedActivities'));
    setMarkedForComparison(new Set(markedActs).has(currentIdx));
  }, [activityID]);

  // Hack as selecting Row leads to analysisdata.row 'loosing' data.
  useEffect(() => {
    const storedAD = JSON.parse(sessionStorage.getItem('analysisData'));
    if (storedAD !== null && analysisData) {
      const newAD = analysisData;
      newAD.rows = storedAD.rows;
      setAnalysisData(newAD);
    };
  }, [selectedRow]);

  // Fetch and format assessment data
  if (!isFetching) {
    (async function fetchAssessment() {
      setFetching(true);
      pryvApiCall(apiToken, assessmentCall(activityID))
        .then((rawData) => {
          const data = formatAssessmentData(rawData);
          // Add assessment type
          data.type = aMetaData[currentIdx].content['title'];
          // Add rec time if tug test
          if (data.type === 'tug') {
            data.totalStats.recordingTime = rawData[0].events[0].content['recording-time'].L;
          };
          // Check for events
          for (let row of data.rows) {
            const lTs = row.L.t0;
            const rTs = row.R.t0;

            const events = patientData.macroEvents
              .filter( (mE) => {
                const tsMin = Math.min(lTs.min, rTs.min);
                const tsMax = Math.min(lTs.max, rTs.max);
                return (
                  mE.streamId === 'fog' &&
                  tsMin <= mE.time &&
                  mE.time <= tsMax
                )
              })
              .map( (mE) => {
                const dts = lTs.values.map((ts) => Math.abs(ts - mE.time));
                const minIdx = dts.indexOf(Math.min(...dts));

                const strideNo = lTs.values[minIdx] - mE.time <= 0 ?
                  minIdx + 0.5: minIdx - 0.5;

                return {
                  type: mE.streamId,
                  strideNo: strideNo,
                  time: mE.time - Math.min(lTs.min, rTs.min),
                }
              })
            row.macroEvents = events;
          };
          sessionStorage.setItem('analysisData', JSON.stringify(data));
          setAnalysisData(data);
          setFetched(true);
        })
    })();
  }

  const getDistanceString = () => {
    return makeDistanceString(analysisData.totalStats.distance);
  };

  const getDurationString = () => {
    return makeTimeString(analysisData.totalStats.duration);
  };

  const makeTime = (dd) => {
    let min = dd.getMinutes().toString();
    if (min.length < 2) {
      min = '0' + min;
    }
    return dd.getHours().toString() + ':' + min;
  };

  const getColorCoding = (para) => {
    if (!analysisData) {
      return;
    }
    /* eslint-disable-next-line max-len*/
    const deltaSym = Math.abs(analysisData.rows[selectedRow]['L'][para].symIDX - 50);
    if (deltaSym <= 5) {
      return classes.success;
    } else if (5 < deltaSym && deltaSym <= 10) {
      return;
    } else if (10 < deltaSym && deltaSym <= 15) {
      return classes.warn;
    } else {
      return classes.error;
    }
  };

  const toAssessment = (direction) => {
    const newIdx = currentIdx + direction;

    if (newIdx >= aMetaData.length) {
      setSnackbar({
        message: 'No prior assessments!',
        severity: 'warning',
        show: true,
      });
      return;
    }

    if (newIdx < 0) {
      setSnackbar({
        message: 'This is the most recent assessment!',
        severity: 'warning',
        show: true,
      });
      return;
    }
    sessionStorage.removeItem('analysisData');
    setSelectedRow(0);
    setFetching(false);
    const newPath = '/assessment/' + aMetaData[newIdx].content['activity-id'];
    history.push({ pathname: newPath });
  };

  const getRanges = (param, activity_type='generic') => {
    if (param.includes('angle') || param.includes('heel')) {
      return;
    }

    let ageIDX = Math.floor(patientAge / 10);
    if (ageIDX > 8) ageIDX = 8;

    let uc = 1;
    if (param.includes('length') || param.includes('velocity')) {
      uc = 0.01;
    };

    let stats;
    if (patientSex !== 'male' || patientSex !=='female') {
      stats = sumStatistics([
        {
          avg: uc * referenceRanges[param]['male']['avg'][ageIDX],
          std: uc * referenceRanges[param]['male']['std'][ageIDX],
        },
        {
          avg: uc * referenceRanges[param]['female']['avg'][ageIDX],
          std: uc * referenceRanges[param]['female']['std'][ageIDX],
        },
      ]);
    } else {
      stats = {
        avg: uc * referenceRanges[param][patientSex]['avg'][ageIDX],
        std: uc * referenceRanges[param][patientSex]['std'][ageIDX],
      };
    }
    return [stats.avg - 1 * stats.std, stats.avg + 1 * stats.std, stats.std];
  };

  const getNormalizedSpeed = () => {
    return analysisData.totalStats.velocity / (patientHeight / 100);
  };

  const ipr = 6;
  const gridRows = () => {
    const noOfRows = Math.ceil(gaitParameters.length / ipr);
    return [...Array(noOfRows).keys()];
  };

  const dateFormat = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const generalDetails = () => ([
    {
      title: 'Patient',
      icon: AccountCircleIcon,
      value: patientName,
    },
    {
      title: 'Date',
      icon: FaCalendarAlt,
      value: makeDateString(analysisData.date, dateFormat),
    },
    {
      title: 'Time',
      icon: FaClock,
      value: makeTime(analysisData.date),
    },
    {
      title: 'Type',
      icon: FaWalking,
      value: id2TitleMap(analysisData.type),
    },
  ]);

  const _ipr = 4;
  const statByRow = () => {
    const noOfRows = Math.ceil(7 / _ipr);
    return [...Array(noOfRows).keys()];
  };

  const formatSummaryStats = (x, pHeight) => {
    const activitySpecfifcStats = x.type !== 'tug' ?
      [
        {
          title: 'Normed Speed',
          icon: FaTachometerAlt,
          extract: function() {
            this.value = (x.velocity / (pHeight / 100)).toFixed(2);
            this.unit = '/s';
          },
        },
        {
          title: 'Walk Ratio',
          icon: FaPercentage,
          extract: function() {
            this.value = x['walk-ratio'].toFixed(2);
            this.unit = 'm/h';
          },
        },
      ] :
      [
        {
          title: 'TUG Time',
          icon: FaHourglassHalf,
          extract: function() {
            this.value = x.recordingTime.toFixed(1);
            this.unit = 's';
          },
        },
        {
          title: 'Walking',
          icon: FaPercentage,
          extract: function() {
            this.value = ((x.duration / x.recordingTime) * 100).toFixed(0);
            this.unit = '%';
          },
        },
      ];

    const allStats = [
      {
        title: 'Steps',
        icon: FaShoePrints,
        extract: function() {
          this.value = x.steps;
          this.unit = '';
        },
      },
      {
        title: 'Distance',
        icon: FaRoute,
        extract: function() {
          const ds = makeDistanceString(x.distance);
          this.value = ds.value;
          this.unit = ds.unit;
        },
      },
      {
        title: 'Duration',
        icon: FaHourglassHalf,
        extract: function() {
          const ds = makeTimeString(x.duration);
          this.value = ds.value;
          this.unit = ds.unit;
        },
      },
      ...activitySpecfifcStats,
      {
        title: 'Cadence',
        icon: FaStopwatch,
        extract: function() {
          this.value = x.cadence.toFixed(0);
          this.unit = 'SPM';
        },
      },
      {
        title: 'Gait speed',
        icon: FaTachometerAlt,
        extract: function() {
          this.value = x.velocity.toFixed(2);
          this.unit = 'm/s';
        },
      },
    ];

    allStats.forEach( (st) => {
      st.extract();
    });

    return allStats;
  };

  const summaryStatsRanges = {
    'Cadence': getRanges('cadence').map( (x) => x.toFixed(0)),
    'Gait speed': getRanges('gait velocity').map( (x) => x.toFixed(2)),
  };

  const onDownloadPdfClick = async () => {
    const aDate = analysisData.date.toISOString().slice(0, -8)
    const pn = patientName.split(' ')
    const filename = (pn[0][0] + pn[1] + '_' + analysisData.type + '_' + aDate)
      .replaceAll('-', '')
      .replaceAll(':', '');
    const commentText = loadAssessmentComment();

    fetchPdf(apiToken, activityID, filename)
      .then( (pdf) => annotatePdf(pdf, {patientData: patientData,
                                        doctorData: doctorData,
                                        comment: commentText}))
      .then( (aPdf) => downloadPdf(aPdf, filename))
  };

  const onMarkForComparisonClick = () => {
    // 1. add activity that was selected to a list in session storage
    const markedActs = new Set(JSON.parse(sessionStorage.getItem('markedActivities')));
    !markedForComparison ? markedActs.add(currentIdx) : markedActs.delete(currentIdx);
    sessionStorage.setItem('markedActivities', JSON.stringify(Array.from(markedActs)));
    // 2. toggle icon from outlined to full
    setMarkedForComparison(!markedForComparison);

    return;
  }

  const toPatientClick = () => {
    history.push({ pathname: '/patient/' + patientData.username });
  };

  /* eslint-disable max-len*/
  if (!isFetched) {
    return (
      <React.Fragment>
        <div
          align="center"
          style={{
            position: 'absolute', left: '50%', top: '40%',
            transform: 'translate(-50%, -50%)',
          }}>
          <CircularProgress color="secondary"/>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <div
        style={{
          width: 'calc(100vw - 20px)',
          height: 'calc(100vh - 100px)',
          position: 'absolute',
          left: 10,
          top: 70,
          overflow: 'hidden',
        }}>
        <Grid container spacing={2}
          justifyContent="center" align="center">
          <Grid item xs={8} style={{ position: 'relative' }}>
           <div style={{ overflow: 'scroll', height: 'calc(100vh - 100px)' }}>
              <Grid container justifyContent="center" spacing={1}
                style={{
                  position: 'relative',
                  height: 800,
                 }}>
                {gaitParameters.map( (gp, idx) => {
                  const Icon = gp.icon;
                  return (
                    <Grid item align="center" xs={4}
                      key={`GP - ${idx}`}>
                      <Avatar
                        variant="rounded"
                        className={getColorCoding(gp.id)}
                        style={{
                          top: 16,
                          width: 30,
                          height: 30,
                          zIndex: 10,
                        }}>
                        <Icon />
                      </Avatar>
                      <LinearProgress
                        value={0}
                        variant="determinate"
                        className={getColorCoding(gp.id)}
                      />
                      <Paper
                        className={classes.paper}>
                        <div style={{ marginTop: 5 }}>
                          <Typography align="center" variant="overline"
                            style={{ fontSize: 13 }}>
                            {gp.title}
                          </Typography>
                        </div>
                        <Divider />
                        <GaitParameterCard
                          title={gp.title}
                          data={analysisData.rows[selectedRow]}
                          events={ {} }
                          param={gp.id}
                          unit={gp.unit}
                          range={getRanges(gp.id)}
                          openSpiderPlotClick={() => setOpenSpiderPlot(true)}/>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={4} style={{ marginTop: 30 }}>
            <LinearProgress
              value={0} variant="determinate"
            />
            <Paper
              style={{
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}>
              <Tooltip title="Go to Patient Page">
                <IconButton
                  size="medium"
                  color="primary"
                  onClick={() => toPatientClick()}
                  style={{
                    position: 'absolute',
                    top: 5,
                    right: 10,
                  }}>
                    <BiArrowFromBottom />
                </IconButton>
              </Tooltip>
              <div
                style={{
                  height: 30,
                  width: 80,
                  position: 'absolute',
                  top: 5,
                  left: 10,
                }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Tooltip title={
                      markedForComparison ?
                      "Unmark From Comparison" :
                      "Mark For Comparison"
                    }>
                      <IconButton
                      color="primary"
                      onClick={() => onMarkForComparisonClick()}>
                      {
                        markedForComparison ?
                        <BsBookmarkStarFill /> :
                        <BsBookmarkStar />
                      }
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title="Download Report">
                      <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => onDownloadPdfClick()}>
                          <FaFileDownload />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
              <div style={{marginTop: 10, marginBottom: 10 }}>
                <Typography align="center" variant="overline"
                  style={{ fontSize: 13 }}>
                  Assessment Details
                </Typography>
                <Divider />
              </div>
              <div
                style={{
                  height: (analysisData.rows.length <= 1) ?
                    `calc(80vh - ${125}px)`: `calc(80vh - ${285}px)`,
                  overflow: 'scroll',
                }}>
                <Grid container align="center" justifyContent="center" spacing={2}
                  style={{
                    padding: 10,
                  }}>
                  <Grid item xs={12}
                    style={{
                      marginTop: '-15px',
                      marginBottom: '-10px',
                    }}>
                    <Grid container justifyContent="center" align="center">
                      {generalDetails().map( (dt, idx) => {
                        const Icon = dt.icon;
                        return (
                          <Grid item xs align="center" key={`dt-${idx}`}>
                            <Avatar
                              variant="rounded"
                              style={{
                                top: 16,
                                width: 30,
                                height: 30,
                                zIndex: 10,
                              }}>
                              <Icon style={{ fontSize: 18 }}/>
                            </Avatar>
                            <LinearProgress value={0} variant="determinate" />
                            <Paper className={classes.paper}
                              style={{
                                height: 80,
                                width: '100%',
                                paddingTop: 10,
                                margin: 0,
                              }}>
                              <Typography align="center" variant="overline"
                                style={{ fontSize: 9 }}>
                                {dt.title}
                              </Typography>
                              <div style={{ display: 'flex', height: 30, alignItems: 'center', justifyContent: 'center' }}>
                                {(dt.title === 'Patient') ?
                                  <Typography align="center"
                                    variant="h3" color="primary"
                                    style={{ fontSize: 14 }}>
                                    {dt.value.split(' ')[0]} <br />
                                    {dt.value.split(' ')[1]}
                                  </Typography> :
                                  <div style={{ display: 'flex' }}>
                                    <Typography align="center"
                                      variant="h3" color="primary"
                                      style={{ fontSize: 14 }}>
                                      {dt.value}
                                    </Typography>
                                    {(aMetaData[currentIdx].content.vibrationType &&
                                    dt.title === 'Type') &&
                                    <>
                                      <Typography align="center"
                                        variant="h3" color="primary"
                                        style={{ fontSize: 14, marginLeft: 5 }}>
                                        +
                                      </Typography>
                                      <Tooltip title={aMetaData[currentIdx].content.vibrationType}>
                                        <div>
                                          <MdVibration
                                            style={{
                                              marginLeft: 5, fontSize: 18 }}/>
                                        </div>
                                      </Tooltip>
                                    </>
                                    }
                                  </div>
                                }
                              </div>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <React.Fragment>
                      {statByRow().map( (gr) => (
                        <Grid container align="center" justifyContent="center" key={`gr-${gr}`}
                          style={{marginBottom: '10px',}}>
                          {formatSummaryStats(
                            analysisData.totalStats,
                            patientData.height,
                          ).slice(gr * _ipr, (gr + 1) * _ipr)
                            .map( (st, idx) => {
                              const Icon = st.icon;
                              return (
                                <Grid item xs align="center" key={`dt-${idx}`}
                                  style={{ marginBottom: -5, marginTop: -5 }}>
                                  <Avatar variant="rounded"
                                    className={classes.summaryStatsAvatar}>
                                    <Icon style={{ fontSize: 18 }}/>
                                  </Avatar>
                                  <LinearProgress value={0} variant="determinate"
                                    className={classes.summaryStatsLinearProgress}/>
                                  <Paper className={classes.paper}
                                    style={{
                                      paddingTop: 10,
                                      margin: 0,
                                    }}>
                                    <Typography align="center" variant="overline"
                                      style={{ fontSize: 9 }}>
                                      {st.title}
                                    </Typography>
                                    <Typography align="center"
                                      variant="h3" color="secondary"
                                      style={{ fontSize: 20 }}>
                                      {st.value}<a className={classes.cardUnit}>{st.unit}</a>
                                    </Typography>
                                    {(['Cadence', 'Gait speed'].includes(st.title)) &&
                                      <Tooltip title="Reference Range">
                                        <div align="center">
                                          <Typography align="center" variant="caption"
                                            style={{
                                              fontSize: 10,
                                              color: 'lightgrey',
                                            }}>
                                            <big>—[</big>
                                            <Typography align="center" variant="caption"
                                              style={{
                                                fontSize: 10,
                                                color: '#3a464c',
                                              }}>
                                              {summaryStatsRanges[st.title][0]} - {summaryStatsRanges[st.title][1]}
                                            </Typography>
                                            <big>]—</big>
                                          </Typography>
                                        </div>
                                      </Tooltip>
                                    }
                                  </Paper>
                                </Grid>
                              );
                            })}
                        </Grid>
                      ))}
                    </React.Fragment>
                  </Grid>
                  <Grid item align="center" xs={12}>
                    <React.Fragment>
                      <CommentField
                        apiToken={apiToken}
                        title="Assessment Comments"
                        key={currentIdx}
                        size={(analysisData.rows.length <= 1) ? 'large': ''}/>
                    </React.Fragment>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            {(analysisData.rows.length > 1) &&
              <Grid container xs={12} align="center" justifyContent="center"
                style={{ marginTop: 10, height: 150 }}>
                <Paper alignItems="center"
                  style={{ width: '100%' }}>
                  <Typography align="center" variant="overline"
                    style={{ fontSize: 13 }}>
                    Regions of Walking
                  </Typography>
                  <div style={{ height: 120 }}>
                    <RowSelectorButtonGroup
                      analysisData={analysisData}
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow} />
                  </div>
                </Paper>
              </Grid>
            }
            <Grid container align="center" justifyContent="center" spacing={1}>
              <Grid item align="center" xs={4} >
                <Paper
                  style={{
                    padding: 10,
                    marginTop: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90%',
                  }}>
                  <Typography align="center" variant="overline"
                    style={{ fontSize: 13 }}>
                    Plot Labels
                  </Typography>
                  <Grid container align="center" justifyContent="center">
                    <Grid item xs={2}>
                      <Typography variant="body2"
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: '50%',
                        }}>
                        Left
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        style={{
                          backgroundColor: '#008fcc',
                          height: 30,
                          width: 30,
                        }} />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={3}>
                      <div
                        style={{
                          backgroundColor: '#4ebfff',
                          height: 30,
                          width: 30,
                        }} />
                    </Grid>
                    <Grid item xs={2} align="center">
                      <Typography variant="body2"
                        style={{
                          fontSize: 12,
                          position: 'relative',
                          top: '50%',
                        }}>
                        Right
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper
                  style={{
                    padding: 10,
                    marginTop: 10,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90%',
                  }}>
                  <Grid item xs={2} align="right">
                    <Tooltip title="Show Previous Assessment">
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => toAssessment(1)}>
                        <FaAngleLeft style={{ fontSize: 25 }}/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} align="center" style={{ height: '80%' }}>
                    <Divider orientation="vertical"
                      style={{ height: '100%' }}/>
                  </Grid>
                  <Grid item xs={2} align="center">
                    <Tooltip title="Select Specific Assessment">
                      <IconButton
                        color="primary"
                        onClick={() => setOpenDialog(true)}>
                        <VscListSelection style={{ fontSize: 25 }} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} align="center" style={{ height: '80%' }}>
                    <Divider orientation="vertical"
                      style={{ height: '100%' }}/>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Show Next Assessment">
                      <IconButton
                        onClick={() => toAssessment(-1)}
                        className={classes.iconButton}>
                        <FaAngleRight style={{ fontSize: 25 }}/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <AssessmentListDialog
              open={openDialog}
              setOpen={setOpenDialog}
              type="D"/>
          <VariabilityChartDialog
            open={openSpiderPlot}
            onClose={() => setOpenSpiderPlot(false)}
            rowData={analysisData.rows[selectedRow]}/>
        </div>
      </div>
    )
  }
}

export default Assessment;

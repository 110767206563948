import Pryv from 'pryv';

import { activityTypes } from '../definitions/activityTypes.js';
import { vibrationTypes } from '../definitions/vibrationTypes.js';
import { statTypes } from '../definitions/statTypes.js';

const unixFuture = ~~(+new Date() / 1000) + 60 * 60 * 24;
const unixZero = 0;

/**
 * Fetch Data
 * @param {String} apiEndpoint
 * @param {Object} apiCall using pryv's call
 * @return {Object} containing value and unit as strings.
 */
export function pryvApiCall(apiEndpoint, apiCall) {
  const connection = new Pryv.Connection(apiEndpoint);
  return connection.api(apiCall);
};

export const doctorDataApiCall = [
  {
    'method': 'events.get',
    'params': {
      'fromTime': unixZero,
      'toTime': unixFuture,
      'streams': [
        '.affiliation',
        '.email',
        'profile',
        'patient-accesses',
      ],
    },
  },
];

export const deletePatientAccess = (id) =>{
  const call = [
    {
      'method': 'events.delete',
      'params': {
        'id': id,
      },
    },
  ];
  return call;
};

export const generalPatientDataApiCall = [
  {
    'method': 'events.get',
    'params': {
      'fromTime': unixZero,
      'toTime': unixFuture,
      'streams': ['profile'],
    },
  },
];

export const allActivitiesMetadataCall = [
  {
    'method': 'events.get',
    'params': {
      'fromTime': unixZero,
      'toTime': unixFuture,
      'streams': process.env.REACT_APP_CERTIFICATION === 'certified' ? {
        any: ['certified'],
        not: ['not-certified'],
      } : [
        'certified',
        'non-certified',
      ],
      'types': ['activity/magnes-v2', 'activity/magnes-v1'],
    },
  },
];

export const minStatsApiCall = [
  {
    'method': 'callBatch',
    'params': [
      ...allActivitiesMetadataCall,
      {
        'method': 'events.get',
        'params': {
          'limit': 1,
          'streams': {
            'any': ['steps'],
            'all': [process.env.REACT_APP_CERTIFICATION],
            'not': [
              ...activityTypes.map( (at) => at.id),
              ...vibrationTypes.map((vt) => vt.id),
            ],
          },
        },
      },
    ],
  },
];

export const statsApiCall = [
  {
    'method': 'callBatch',
    'params':
      activityTypes.map( (at) => statTypes.map( (st) => {
        return {
          'method': 'events.get',
          'params': {
            'limit': 1,
            'streams': {
              'any': [at.id],
              'all': [st, process.env.REACT_APP_CERTIFICATION],
            },
          },
        };
      })).flat(),
  },
  {
    'method': 'events.get',
    'params': {
      'limit': 8,
      'streams': {
        'any': ['overall-stats'],
        'all': [process.env.REACT_APP_CERTIFICATION],
        'not': activityTypes.map( (at) => at.id),
      },
    },
  },
];


export const fullActivityBatchCall = (idArray) => {
  const call = [
    {
      'method': 'events.get',
      'params': {
        'fromTime': unixZero,
        'toTime': unixFuture,
        'streams': idArray.map( (id) => `activity-${id}`),
        'types': [
          'series:sensor-magnes/flat-nushu-v1',
          'series:sensor-magnes/flat-nushu-v2',
        ],
      },
    },
  ];
  return call;
};

export const assessmentCall = (id) => {
  const call = [
    {
      'method': 'events.get',
      'params': {
        'streams': {
          'all': [`activity-${id}`],
          'any': ['result-data'],
        },
        'types': [
          'result-magnes/nushu-v3',
        ],
      },
    },
  ];
  return call;
};

export const pdfReportsCall = (id) => {
  const call = [
    {
      'method': 'events.get',
      'params': {
        'streams': {
          'all': [`activity-${id}`],
          'any': ['reports'],
        },
      },
    },
  ];
  return call;
};

export const deleteEventCall = (id) => {
  const call = [
    {
      'method': 'events.delete',
      'params': {
        'id': id,
      },
    },
  ];
  return call;
};

export const eventsFromStreams = (streams) => {
  return [
    {
      'method': 'events.get',
      'params': {
        'fromTime': unixZero,
        'toTime': unixFuture,
        'streams': streams,
      },
    },
  ];
};

export const updateEventCall = (id, content) => {
  const call = [
    {
      'method': 'events.update',
      'params': {
        'id': id,
        'update': {
          'content': content,
        },
      },
    },
  ];
  return call;
};

export const createEventCall = (streamIds, type, content, ts) => {
  const call = [
    {
      'method': 'events.create',
      'params': {
        'streamIds': streamIds,
        'type': type,
        'content': content,
        'time': ts,
      },
    },
  ];
  return call;
};

import Pryv from 'pryv';
import {
  average,
  stdDev,
  arraySum,
  shiftArray,
} from './mathUtils.js';

import { activityTypes } from '../definitions/activityTypes.js';
import { statTypes } from '../definitions/statTypes.js';
// Data fetch helper functions

/**
 * Fetch Data
 * @param {String} apiEndpoint
 * @param {Object} apiCall using pryv's call
 * @return {Object} containing value and unit as strings.
 */
export function fetchData(apiEndpoint, apiCall) {
  const connection = new Pryv.Connection(apiEndpoint);
  return connection.api(apiCall);
};

/**
 * Get Content
 * @param {Array} callResponse reulting from an apicall
 * @param {String} streamId streamId by which to filter the response by.
 * @return {Array} of all events matching of callResponse with streamId
 */
function getContent(callResponse, streamId) {
  try {
    const res = callResponse.filter((item) => item.streamId === streamId);
    return res[0]['content'];
  } catch {
    return undefined;
  };
}

/**
 * Extract General Patient Data
 * @param {Array} callResponse reulting from an apicall
 * @return {Object} containing general patient data
 */
export function extractGeneralPatData(callResponse) {
  const eventArray = callResponse[0]['events'];
  const data = {};

  const name = getContent(eventArray, 'name');
  const bdate = new Date(getContent(eventArray, 'birthdate'));

  data.firstName = name['first-name'];
  data.lastName = name['last-name'];
  data.age = new Date(new Date() - bdate).getFullYear() - 1970;
  data.birthdate = bdate;
  data.impairedSide = getContent(eventArray, 'impaired-side');
  data.weight = getContent(eventArray, 'weight');
  data.height = getContent(eventArray, 'height');
  data.address = getContent(eventArray, 'address');
  data.shoeSize = getContent(eventArray, 'shoe-size');
  data.sex = getContent(eventArray, 'sex');
  data.phoneNo = getContent(eventArray, 'phone');
  data.regDate = new Date(
    callResponse[0].events
      .filter((item) => item.streamId === 'name')[0].created * 1000,
  );

  const diagnosis = eventArray.filter( (i) => i.streamId === 'diagnosis');
  data.diagnosis = (diagnosis.length === 0) ? 'none' : diagnosis[0];

  return data;
};

/**
* Extract General Doctor Data
* @param {Array} callResponse reulting from an apicall
* @return {Object} containing general doctor data
*/
export function extractGeneralDocData(callResponse) {
  const eventArray = callResponse[0]['events'];
  const data = {};

  const name = getContent(eventArray, 'name');

  data.firstName = name ? name['first-name']: undefined;
  data.lastName = name ? name['last-name']: undefined;
  data.email = getContent(eventArray, '.email');
  data.affiliation = getContent(eventArray, '.affiliation');
  data.address = getContent(eventArray, 'address');
  data.phoneNo = getContent(eventArray, 'phone');

  return data;
}

/**
 * Extract MinStats NOTE that returned events are in the order of the callBatch
 * @param {Array} response from an minStatsApiCallapi call
 * @return {Object} of formatted data
 */
export function formatMinStats(response) {
  const result = response[0]['results'];
  const data = {};

  const currentDate = new Date();
  const lastActDate = new Date(result[0].events[0].time * 1000);
  const firstActDate = new Date(result[0].events.reverse()[0].time * 1000);

  data['daysSinceLast'] = getDateDayDifference(lastActDate, currentDate);
  data['daysInRehab'] = getDateDayDifference(firstActDate, currentDate);
  data['lastAssessment'] = lastActDate;
  data['firstAssessment'] = firstActDate;

  data['total-assessments'] = result[0].events.length;

  // overall steps per day
  data['stats'] = {
    'overall-stats': {
      'steps': result[1].events[0].content,
    },
  };
  // Get trend from weekly stats
  const ct = new Date(result[1].events[0].time * 1000);
  const shift = getDateDayDifference(ct, currentDate);

  const spd = [
    ...result[1].events[0].content['values'],
    ...new Array(shift).fill(0),
  ];
  data.trend = computeStepTrend(spd);
  return data;
};

/**
 * Computes the trend (proxied by patient card color)
 * @param {Array} spd step per day
 * @return {String}
 */
export function computeStepTrend(spd) {
  const stepsLW = spd
    .slice(-7)
    .reduce( (a, b) => a + b);

  const stepsLLW = spd
    .slice(-14, -7)
    .reduce( (a, b) => a + b);

  const relStepTrend = ((stepsLLW === 0) ?
    stepsLW: (stepsLW - stepsLLW) / stepsLLW);

  if (relStepTrend >= 0.1) {
    return '↗';
  } else if (relStepTrend <= -0.1) {
    return '↘';
  } else {
    return '=';
  };
}

/**
 * Format Raw Statistics into JSON and compute some overall numbers
 * @param {Array} rawStats reulting from an apicall
 * @return {Object}
 */
export function formatRawStats(rawStats) {
  const trendStats = [
    'duration',
    'distance',
    'assessments',
    'steps',
    'stride-duration',
    'stride-velocity',
    'stride-length',
    'stride-swing-stance-ratio',
    // 'stride-supination-angle',
    'stride-strike-angle',
    'stride-heel-clearance',
  ];

  // Extract stats by activity
  const byActivityStats = rawStats[0]['results'];
  const deltaDays = getDateDayDifference(
    new Date(byActivityStats[0].events[0].time * 1000),
    new Date(),
  );

  const formattedStats = {};
  let idx = 0;
  activityTypes.forEach( (at) => {
    formattedStats[at.id] = {};
    statTypes.forEach( (st) => {
      formattedStats[at.id][st] = {};
      // Shift stats by one day if necessary
      if (trendStats.includes(st)) {
        if (trendStats.indexOf(st) < 4) {
          formattedStats[at.id][st].values = shiftArray(
            byActivityStats[idx].events[0].content.values,
            deltaDays,
          );
        } else {
          ['left', 'right'].forEach( (side) => {
            formattedStats[at.id][st][`values-${side}`] = shiftArray(
              byActivityStats[idx].events[0].content[`values-${side}`],
              deltaDays,
            );
          });
        }
      } else {
        formattedStats[at.id][st] = byActivityStats[idx].events[0].content;
      };
      // Flip left supination angle
      if (st.includes('supination')) {
        const toFlip = formattedStats[at.id][st]['values-left'];
        formattedStats[at.id][st]['values-left'] = toFlip.map( (x) => x * -1);
      };
      // Convert heel clearance to cm
      if (st.includes('heel-clearance')) {
        for (const key of Object.keys(formattedStats[at.id][st])) {
          const toc = formattedStats[at.id][st][key];
          formattedStats[at.id][st][key] = toc.map( (x) => x * 100);
        }
      };
      idx ++;
    });
  });

  // Overall stats
  const summedStats = rawStats[1]['events'];
  const overallStats = {};
  summedStats.forEach( (ss) => {
    overallStats[ss.streamId] = ss.content;
  });

  trendStats.forEach( (st, idx) => {
    if (idx < 4) {
      overallStats[st] = {
        values: new Array(365).fill(0),
      };
    } else {
      overallStats[st] = {
        'values-left': new Array(365).fill(0),
        'values-right': new Array(365).fill(0),
      };
    };
  });
  trendStats.forEach( (ts, idx) => {
    activityTypes.forEach( (at) => {
      if (idx < 4) {
        overallStats[ts].values = arraySum(
          overallStats[ts].values, formattedStats[at.id][ts].values,
        );
      } else {
        ['left', 'right'].forEach( (side) => {
          for (let n = 0; n < 365; n++) {
            /* eslint-disable-next-line max-len*/
            const wVal = formattedStats[at.id][ts][`values-${side}`][n] * formattedStats[at.id].steps.values[n] / 2;
            overallStats[ts][`values-${side}`][n] += wVal;
          };
        });
      };
    });
  });

  // Norm summed trends
  trendStats.slice(4).forEach( (ts) => {
    ['left', 'right'].forEach( (side) => {
      for (let n = 0; n < 365; n++) {
        const div = overallStats.steps.values[n] / 2;
        overallStats[ts][`values-${side}`][n] /= (div === 0) ? 1: div;
      };
    });
  });

  // Compute new assessments, steps, time, distance.
  const types = [
    'assessments',
    'steps',
    'distance',
    'duration',
  ];
  types.forEach( (type) => {
    const lastWeek = overallStats[type]['values'].slice(-7);
    overallStats['new-' + type] = lastWeek.reduce(
      (a, b) => a + b,
    );
  });

  formattedStats['overall-stats'] = overallStats;
  return formattedStats;
}

/**
 * Format Assessment Data
 * By extracting data from the apicall and computing stats such as the
 * variability indices and
 * @param {Array} rawData of the apicall to fetch an assessment.
 * @return {Object} of formatted assessment data
 */
export function formatAssessmentData(rawData) {
  const eventArray = rawData[0]['events'][0];
  const data = {};
  data.date = new Date(eventArray.time * 1000);

  for (const [key, value] of Object.entries(eventArray['content'])) {
    data[key] = value;
  }

  addTotalStats(data);
  flipSupAngles(data);
  convertClearanceToCm(data);
  addVariabilityIdx(data);
  addSymmetryIdx(data);

  return data;
}

/**
 * Add Total Stats
 * which consist of cadence, distance duration and steps to a data object. This
 * is done by looping over all rows and summing up the respective stats.
 * Also computes the walk ratio ( stride length / cadence )
 * @param {Object} data of the apicall to fetch an assessment.
 */
export function addTotalStats(data) {
  const totalStats = {};
  const parametersList = [
    'steps',
    'cadence',
    'distance',
    'duration',
    'velocity',
  ];

  for (const p of parametersList) {
    totalStats[p] = 0;
  };
  const allStrideLengths = [];

  for (const row of data.rows) {
    parametersList.forEach((p) => {
      if ((p === 'cadence') || (p === 'velocity')) {
        totalStats[p] += row['row-stats'][p] * row['row-stats']['steps'];
      } else {
        totalStats[p] += row['row-stats'][p];
      }
    });
    const strideLengths = row.L['length'].values.concat(row.R.length.values);
    allStrideLengths.push(...strideLengths);
  };

  totalStats['cadence'] /= totalStats['steps'];
  totalStats['velocity'] /= totalStats['steps'];

  const avgStrideLength = average(allStrideLengths);
  totalStats['walk-ratio'] = avgStrideLength / totalStats['cadence'] * 60;

  data.totalStats = totalStats;
}

/**
 * Add variabily IDX
 * to the data object.
 * @param {Obj} data from and to which variability indices are added.
 */
export function addVariabilityIdx(data) {
  for (const row of data.rows) {
    ['L', 'R'].forEach( (side) => {
      for (const [para, value] of Object.entries(row[side])) {
        /* eslint-disable-next-line max-len*/
        row[side][para]['varIDX'] = (value.avg === 0) ? value.std: value.std / Math.abs(value.avg);
      }
    });
  }
}

/**
 * Add symmetry IDX
 * computed by SL = 100 * |L| / |L + R| & SR = 100 - SL, where L & R in this
 * this case refer to the left and right mean of a parameter (e.g. stride
 * length) to the data object.
 * @param {Obj} data from and to which symmetry indices are added.
 */
export function addSymmetryIdx(data) {
  for (const row of data.rows) {
    Object.keys(row['L']).forEach( (para) => {
      const avgL = row['L'][para].avg;
      const avgR = row['R'][para].avg;
      if (Math.abs(avgL + avgR) === 0) {
        row['L'][para]['symIDX'] = 50;
      } else {
        row['L'][para]['symIDX'] = 100 * Math.abs(avgL) / Math.abs(avgL + avgR);
      }
      row['R'][para]['symIDX'] = 100 - row['L'][para]['symIDX'];
    });
  }
}

/**
 * Flip supination Angles
 * of the left side, aka multiply by (-1).
 * @param {Obj} data of an assessment in which angles are to be flipped.
 */
export function flipSupAngles(data) {
  // Abbreviations
  const oss = 'overall-step-stats';
  const sa = 'supination_angle';
  // Handle overall step stats
  for (const [para, val] of Object.entries(data[oss]['L'][sa])) {
    let newVal = val;
    if (!para.includes('std')) {
      newVal = val * (-1);
    }
    data[oss]['L'][sa][para] = newVal;
  }
  // Handle each row
  for (const row of data.rows) {
    for (const [para, val] of Object.entries(row['L'][sa])) {
      let newVal = val;

      if (para === 'values') {
        newVal = val.map((x) => x * (-1));
      } else if (!para.includes('std')) {
        newVal = val * (-1);
      }
      row['L'][sa][para] = newVal;
    }
  }
}

/**
 * Convert heel clearance from m to cm
 * @param {Obj} data of an assessment in which heel clearance to be converted.
 */
export function convertClearanceToCm(data) {
  // Abbreviations
  const oss = 'overall-step-stats';
  const sa = 'heel-clearance';

  for (const side of ['L', 'R']) {
    // Handle overall step stats
    for (const [para, val] of Object.entries(data[oss][side][sa])) {
      data[oss][side][sa][para] = 100 * val;
    }
    // Handle each row
    for (const row of data.rows) {
      for (const [para, val] of Object.entries(row[side][sa])) {
        if (para === 'values') {
          row[side][sa][para] = val.map((x) => x * 100);
        } else {
          row[side][sa][para] *= 100;
        }
      }
    }
  }
}

/**
 * Get Date Day Difference
 * Difference between two dates in days, i.e. 1/1 @ 23:10 vs. 2/1 @ 00:10
 * corresponds to one day.
 * @param {Date} date1 the first date.
 * @param {Date} date2 the second date (occuring later than date 1).
 * @return {number} difference between the two dates in 'days'
 */
export function getDateDayDifference(date1, date2) {
  const d1 = new Date(date1.getTime());
  d1.setHours(12, 0, 0, 0);
  const d2 = new Date(date2.getTime());
  d2.setHours(12, 0, 0, 0);

  return Math.round((d2.getTime() - d1.getTime()) / (1000 * 24 * 3600));
}

/**
 * aggreggateStats runs over a list of assessments and compiles
 * their overall stats
 * @param {array} assessments formatted by formatAssessmentData()
 * @return {object} of similar structure like a single formatted assessment.
 */
export function aggreggateStats(assessments) {
  // Construct aggreggate stats object
  const ss = 'step-stats';
  const os = {
    'totalStats': {
      steps: 0,
      distance: 0,
      duration: 0,
      cadence: 0,
      velocity: 0,
    },
    'step-stats': {},
  };

  for (const side of ['L', 'R']) {
    os[ss][side] = {};
    for (const param of Object.keys(assessments[0]['rows'][0][side])) {
      os[ss][side][param] = { values: [] };
    };
  };

  // Compute total steps, i.e. number of steps, distance , etc...
  assessments.forEach((assessment) => {
    for (const key of Object.keys(os.totalStats)) {
      // Compute total steps, i.e. number of steps, distance , etc...
      os.totalStats[key] += assessment.totalStats[key];
      // Get parameters for each step of selection
      for (const row of assessment.rows) {
        for (const side of ['L', 'R']) {
          for (const para of Object.keys(os[ss][side])) {
            if (['distance', 'stability'].includes(para)) {
              continue;
            }
            os[ss][side][para].values.push(...row[side][para]['values']);
          }
        }
      }
    }
  });

  // 'Clean up'
  const N = os.totalStats.steps;

  os.totalStats.velocity = os.totalStats.distance / os.totalStats.duration;
  os.totalStats.cadence = N / os.totalStats.duration * 60;

  for (const side of ['L', 'R']) {
    for (const para of Object.keys(os[ss][side])) {
      if (['distance', 'stability'].includes(para)) {
        continue;
      }
      const vals = os[ss][side][para]['values'];
      const avg = average(vals);
      os[ss][side][para]['avg'] = avg;
      os[ss][side][para]['std'] = stdDev(vals, avg, vals.length);
      os[ss][side][para]['min'] = Math.min(...vals);
      os[ss][side][para]['max'] = Math.max(...vals);
    }
  }

  // Modified os object to be able to get varIDX and symIDX
  addVariabilityIdx({ 'rows': [os[ss]] });
  addSymmetryIdx({ 'rows': [os[ss]] });
  return os;
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Typography,
} from '@material-ui/core';

/**
 * Renders the "Home Button" component.
 * @return {jsx} The button component.
 */
export default function HomeButton() {
  const history = useHistory();
  const redirectToHome = () => history.push('/');

  return (
    <Button
      variant = "outlined"
      color = 'secondary'
      onClick={redirectToHome}>
      <Typography
        component="h1"
        variant="h5">
        <i><b>NUSHU</b></i>
      </Typography>
    </Button>
  );
}

import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import { linspace, linearRegression } from '../utils/mathUtils';
import Overlay from '../components/Overlay.js';

/**
 * Renders an extended trend chart for left and right data series.
 * @param {Object} props The component properties.
 * @return {jsx} The trend chart component.
 */
export default function TrendChartExtended(props) {
  const [overlay, setOverlay] = useState(false);

  const nLeft = props.leftData.length;
  const nRight = props.rightData.length;

  const nonZeroes = props.rightData.filter( (x) => {
    if (x !== 0) {
      return x;
    }
  }).length;

  const data = {
    labels: props.labels,
    datasets: [
      {
        label: 'Left',
        fill: false,
        backgroundColor: '#008fccaa',
        borderColor: '#008fcc',
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: props.leftData.map((value) => Number(value).toFixed(2)),
      },
      {
        label: 'Right',
        fill: false,
        backgroundColor: '#4ebfffaa',
        borderColor: '#4ebfff',
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: props.rightData.map((value) => Number(value).toFixed(2)),
      },
    ],
  };

  // Make trend lines only if there's not a massive amount of datapoints
  if (nRight < 100 && nonZeroes > 3) {
    data.datasets.unshift(
      {
        label: 'Left Trend',
        backgroundColor: '#3a464c',
        borderColor: '#3a464c',
        borderDash: [3, 2],
        fill: false,
        type: 'line',
        data: linearRegression(linspace(0, nLeft - 1, nLeft), props.leftData)
          .map((value) => value.toFixed(2)),
      },
      {
        label: 'Right Trend',
        borderColor: '#bdbdbd',
        borderDash: [3, 2],
        fill: false,
        type: 'line',
        data: linearRegression(linspace(0, nRight - 1, nRight), props.rightData)
          .map((value) => value.toFixed(2)),
      },
    );
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [{
        barPercentage: (nRight < 100) ? 1: 2,
        scaleLabel: {
          display: false,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: props.yAxisLabel,
        },
        ticks: {
          beginAtZero: true,
        },
      }],
    },
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setOverlay(true)}
        style={{
          height: '100%',
          paddingBottom: 10,
        }}>
        <Bar data={data} options={options} />
      </div>
      <Overlay
        open={overlay}
        onClose={() => setOverlay(false)}
        title={props.yAxisLabel}>
        <Bar data={data} options={options}/>
      </Overlay>
    </React.Fragment>
  );
};

TrendChartExtended.propTypes = {
  leftData: PropTypes.array.isRequired,
  rightData: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  yAxisLabel: PropTypes.string,
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Divider,
  IconButton,
  Grid,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  IoGitCompareOutline,
} from 'react-icons/io5';
import {
  BiArrowFromBottom,
} from 'react-icons/bi';
import {
  AiFillSave,
  AiOutlineCloudDownload,
} from 'react-icons/ai';

import CustomSnackbar from '../components/CustomSnackbar.js';
import SelectAssessmentsList from '../components/SelectAssessmentsList.js';
import CompPerformanceChart from '../components/CompPerformanceChart.js';
import CompSymmetryChart from '../components/CompSymmetryChart.js';
import SaveComparisonDialog from '../components/SaveComparisonDialog.js';
import LoadComparisonDialog from '../components/LoadComparisonDialog.js';

import {
  formatAssessmentData,
  aggreggateStats,
} from '../utils/dataFetchFunctions.js';
import {
  pryvApiCall,
  assessmentCall,
  eventsFromStreams,
} from '../data/apiCalls.js';
import {
  loadPatientData,
} from '../utils/auxFunctions.js';

import { gaitParameters } from '../definitions/gaitParameters.js';
import { useStyles } from '../useStyles.js';

import ReactGA from 'react-ga4';
import { getCookieConsentValue } from 'react-cookie-consent';

if (getCookieConsentValue()) {
  ReactGA.initialize(
    process.env.REACT_APP_ANALYTICS_ID,
    { testMode: process.env.NODE_ENV === 'test' },
  );
}

/**
 * Displays information about a single assessment.
 * @param {Object} props The component properties.
 * @return {jsx} The assessment page component.
 */
function Comparison(props) {
  const classes = useStyles();
  const history = useHistory();

  const patientData = loadPatientData();
  const docToken = sessionStorage.getItem('docEP');

  const [isComputing, setComputing] = React.useState(false);
  const [selectionA, setSelectionA] = React.useState([]);
  const [selectionB, setSelectionB] = React.useState([]);
  const [statsA, setStatsA] = React.useState();
  const [statsB, setStatsB] = React.useState();
  const [snackbar, setSnackbar] = React.useState({
    message: '',
    severity: '',
    show: false,
  });
  const [savedComparisons, setSavedComparisons] = React.useState([]);
  const [isSaveDialogOpen, setSaveDialogOpen] = React.useState(false);
  const [isLoadDialogOpen, setLoadDialogOpen] = React.useState(false);
  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview', page: '/compare', title: 'Comparison Page',
    });
  }, []);

  useEffect(() => {
    pryvApiCall(docToken, eventsFromStreams(['comparisons']))
      .then( (res) => {
        setSavedComparisons(res[0].events.filter(
          (x) => x.content.patient === patientData.username),
        );
      });
  }, []);

  const computeClick = (_selectionA, _selectionB) => {
    if (isComputing) {
      return;
    };
    setComputing(true);
    if (_selectionA.length < 1 || _selectionB.length < 1) {
      setSnackbar({
        message: `Nothing to compare!
          Make sure to have selected assessments from each group!`,
        severity: 'warning',
        show: true,
      });
      setComputing(false);
      return;
    }

    Promise.all([
      compileOverallStats(_selectionA),
      compileOverallStats(_selectionB),
    ])
      .then((values) => {
        setStatsA(values[0]);
        setStatsB(values[1]);
        setComputing(false);
      });
  };

  const compileOverallStats = async (selection) => {
    // Load all selected assessments
    const allStatsArray = await Promise.all(
      selection.map((act) => (
        pryvApiCall(
          patientData.apiToken,
          assessmentCall(act.content['activity-id']),
        )
      )),
    );
    // Format assemssments
    allStatsArray.forEach((rawData, idx) => {
      allStatsArray[idx] = formatAssessmentData(rawData);
    });
    // Add up totalStats
    return aggreggateStats(allStatsArray);
  };

  const formatStats = (param) => {
    const fs = {
      'Group A': {
        'L': statsA['step-stats']['L'][param],
        'R': statsA['step-stats']['R'][param],
      },
      'Group B': {
        'L': statsB['step-stats']['L'][param],
        'R': statsB['step-stats']['R'][param],
      },
    };
    return fs;
  };

  const ipr = 3;
  const gridRows = () => {
    const noOfRows = Math.ceil(gaitParameters.length / ipr);
    return [...Array(noOfRows).keys()];
  };

  const toPatientClick = () => {
    history.push({ pathname: '/patient/' + patientData.username });
  };

  const loadComparisonClick = () => {
    setLoadDialogOpen(true);
  };

  const saveComparisonClick = () => {
    if (selectionA.length === 0 || selectionB.length === 0) {
      setSnackbar({
        message: 'To save, first select assessments from both groups',
        severity: 'warning',
        show: true,
      });
      return;
    }
    setSaveDialogOpen(true);
  };

  return (
    <div
      style={{
        width: '100vw',
        height: 'calc(100vh - 90px)',
        position: 'absolute',
        left: 0,
        top: 90,
        overflow: 'hidden',
      }}>
      {snackbar.show &&
        <CustomSnackbar
          content={snackbar}
          handleClose={() => setSnackbar({
            message: '',
            severity: '',
            show: false,
          })}/>
      }
      <div
        style={{
          display: 'flex',
          position: 'relative',
          left: 30,
          top: 0,
          width: `calc(100vw - ${630}px)`,
          height: `calc(100vh - ${80}px)`,
          justifyContent: 'center',
        }}>
        { (!statsA && !isComputing) &&
          <div className={classes.margin}
            style={{
              position: 'absolute',
              top: '30%',
            }}>
            <ErrorOutlineIcon
              style={{ fontSize: 60 }}
              className = { classes.errorColor }/>
            <Typography color="primary">
              Select what datasets you want to compare first.
            </Typography>
          </div>
        }
        { isComputing &&
          <div className={classes.margin}
            style={{
              position: 'absolute',
              top: '30%',
            }}>
            <CircularProgress
              style={{ fontSize: 60 }}
              color="secondary"/>
            <Typography color="primary">
              ...Compiling Stats...
            </Typography>
          </div>
        }
        { (statsA && statsB) &&
          <Grid container justifyContent="center" align="center" spacing={2}>
            <Grid item xs={10}>
              <LinearProgress value={0} variant="determinate"/>
              <Paper className={classes.paper}
                style={{ paddingBottom: 10 }}>
                <div style={{ marginTop: 5 }}>
                  <Typography align="center" variant="overline"
                    style={{ fontSize: 13 }}>
                  Stride-based Parameters
                  </Typography>
                  <Divider />
                </div>
                <div style={{ height: `calc(100vh - ${200}px)`,
                  overflow: 'scroll', padding: 10 }}>
                  {gridRows().map( (gr) => (
                    <Grid container key={`Row-${gr}`}
                      spacing={3} justifyContent="center">
                      { gaitParameters.slice(gr * ipr, (gr + 1) * ipr)
                        .map((para, idx) => (
                          <Grid item xs={4} key={`GP-${idx}`}
                            style={{ marginTop: -10 }}>
                            <CompSymmetryChart
                              parameter={para}
                              data={formatStats(para.id)}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  ))}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <LinearProgress value={0} variant="determinate"/>
              <Paper style={{ padding: 10 }}>
                <div style={{ marginTop: 5 }}>
                  <Typography align="center" variant="overline"
                    style={{ fontSize: 13 }}>
                  Overall Stats
                  </Typography>
                  <Divider />
                </div>
                <div style={{ height: `calc(100vh - ${200}px)`,
                  overflowY: 'scroll' }}>
                  <Grid container spacing={2}>
                    {Object.keys(statsA.totalStats).map((para, idx) => (
                      <Grid item xs={12} key={`OGP-${idx}`}
                        style={{
                          height: 140,
                          marginTop: (idx !== 0) ? 10: 0,
                        }}>
                        <CompPerformanceChart
                          parameter={para}
                          data={
                            [statsA.totalStats[para], statsB.totalStats[para]]
                          }/>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Paper>
            </Grid>
          </Grid>
        }
      </div>
      <div
        style={{
          width: '600px',
          height: '100%',
          position: 'absolute',
          right: 0,
          top: 0,
          justifyContent: 'center',
          display: 'flex',
        }}>
        <div
          style={{
            width: '90%',
          }}>
          <LinearProgress
            value={0} variant="determinate"
          />
          <Paper
            style={{
              padding: 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}>
            <Tooltip title="Go to Patient Page">
              <IconButton
                size="medium"
                color="primary"
                onClick={() => toPatientClick()}
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 10,
                }}>
                <BiArrowFromBottom />
              </IconButton>
            </Tooltip>
            <Grid container align="center" justifyContent="center" spacing={2}
              style={{
                marginBottom: '10px',
              }}>
              <Grid item xs={12} style={{ marginTop: 0 }}>
                <Typography align="center" variant="overline"
                  style={{ fontSize: 13 }}>
                  Setup Comparison
                </Typography>
              </Grid>
              <Grid item xs={11}
                style={{
                  height: 350,
                  display: 'flex',
                  paddingBottom: 10,
                  position: 'relative',
                }}>
                <Grid item xs={6} style={{ paddingRight: 15 }}>
                  <SelectAssessmentsList
                    group={'A'}
                    setSelection={setSelectionA}
                    isComputing={isComputing}
                    selectedActs={selectionA}/>
                </Grid>
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: 10,
                    left: 'calc(50% - 5px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <Divider orientation="vertical" />
                </div>
                <Grid item xs={6}
                  style={{ paddingLeft: 15 }}>
                  <SelectAssessmentsList
                    group={'B'}
                    setSelection={setSelectionB}
                    isComputing={isComputing}
                    selectedActs={selectionB}/>
                </Grid>
              </Grid>
              <Grid item xs={4} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}>
                  <Typography style={{ fontSize: 14 }}>
                    Group A
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Tooltip
                  title={
                    !isComputing ? 'Run Comparison': 'Compiling'
                  }>
                  <IconButton
                    className={classes.outlinedIconButton}
                    onClick={() => computeClick(selectionA, selectionB)}>
                    {!isComputing ?
                      <IoGitCompareOutline style={{ fontSize: 40 }}/> :
                      <CircularProgress color="secondary" />
                    }
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={4} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}>
                  <Typography style={{ fontSize: 14 }}>
                    Group B
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
          <div style={{ marginTop: 30 }}>
            <Grid container algin="center" justifyContent="center"
              spacing={1}>
              <Grid item align="center" xs={4}>
                <Paper style={{ height: '100%', padding: 5 }}>
                  <Grid container algin="center" justifyContent="center"
                    style={{ height: '100%', padding: 0 }}>
                    <Grid item xs={12}>
                      <Typography variant="overline" align="center"
                        style={{ fontSize: 13 }}>
                        Plot Labels
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        style={{
                          display: 'flex',
                          height: '100%',
                          position: 'relative',
                        }}>
                        <div style={{ position: 'absolute', bottom: 5 }}>
                          <Typography variant="body2" style={{ fontSize: 12 }}>
                            Left
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3} align="center"
                      style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'darkgrey',
                          height: 30,
                          width: 30,
                          bottom: 5,
                          right: 6,
                        }} />
                    </Grid>
                    <Grid item xs={1} style={{ position: 'relative' }}>
                      <Divider orientation="vertical"
                        style={{
                          height: 30,
                          position: 'absolute',
                          bottom: 5, left: 4 }} />
                    </Grid>
                    <Grid item xs={3} align="center"
                      style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: 'lightgrey',
                          height: 30,
                          width: 30,
                          left: 2,
                          bottom: 5,
                        }} />
                    </Grid>
                    <Grid item xs={2}>
                      <div
                        style={{
                          display: 'flex',
                          height: '100%',
                          position: 'relative',
                        }}>
                        <div style={{ position: 'absolute', bottom: 5 }}>
                          <Typography variant="body2"
                            style={{ fontSize: 12 }}>
                            Right
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item align="center" xs={8}>
                <Paper style={{ height: '100%', padding: 10 }}>
                  <Grid container algin="center" justifyContent="center">
                    <Grid item xs={2} align="right">
                      <Tooltip title="Load a saved Comparison">
                        <IconButton
                          color="primary"
                          onClick={loadComparisonClick}>
                          <AiOutlineCloudDownload style={{ fontSize: 35 }}/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={1}
                      style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <Divider orientation="vertical"/>
                    </Grid>
                    <Grid item xs={2} align="center">
                      <div
                        style={{ height: '100%', display: 'flex', top: '50%' }}>
                        <Tooltip title="Save this Comparison">
                          <IconButton
                            color="primary"
                            onClick={saveComparisonClick}>
                            <AiFillSave style={{ fontSize: 30 }}/>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <SaveComparisonDialog
        selectedActivities={{
          'Group A': selectionA.map((x) => x.content['activity-id']),
          'Group B': selectionB.map((x) => x.content['activity-id']),
        }}
        isOpen={isSaveDialogOpen}
        setOpen={setSaveDialogOpen}
        savedComparisons={savedComparisons}
        setSavedComparisons={setSavedComparisons} />
      <LoadComparisonDialog
        isOpen={isLoadDialogOpen}
        setOpen={setLoadDialogOpen}
        savedComparisons={savedComparisons}
        setSavedComparisons={setSavedComparisons}
        onLoad={ (grpA, grpB) => {
          setSelectionA(grpA);
          setSelectionB(grpB);
          computeClick(grpA, grpB);
          setSnackbar({
            show: true,
            message: 'Loaded Comparison',
            severity: 'success',
          });
        }}
      />
    </div>
  );
}

export default Comparison;

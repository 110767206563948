import React from 'react';
import { PropTypes } from 'prop-types';
import { Dialog } from '@material-ui/core';
import { DialogTitle } from '@material-ui/core';

Chart.defaults.global.legend.display = false;

/**
 * Shows detailed charts zoomed in.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export default function Overlay(props) {
  return (
    <Dialog
      onClose={props.onClose}
      open={props.open}
      fullWidth
      maxWidth="xl">
      <DialogTitle>{props.title}</DialogTitle>
      <div
        style={{
          height: '40vh',
          padding: 20,
        }}>
        { props.children }
      </div>
    </Dialog>
  );
};

Overlay.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
};

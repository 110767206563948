import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  Divider,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  IoMdDownload,
} from 'react-icons/io';

import {
  pryvApiCall,
  eventsFromStreams,
} from '../data/apiCalls.js';

import SelectAssessmentsList from './SelectAssessmentsList.js';

import {
  fetchPdf,
  annotatePdf,
} from '../utils/pdfTools.js';

import {
  loadPatientData,
  loadDoctorData,
} from '../utils/auxFunctions.js';

import { saveAs } from 'file-saver';
/**
 * A big number.
 * @param {Object} props The component properties.
 * @return {jsx} The title component.
 */
export default function AssessmentListDialog(props) {
  const [selected, setSelected] = useState([]);

  const patientData = loadPatientData();
  const doctorData = loadDoctorData();

  const handleClose = () => {
    setSelected([]);
    props.setOpen(false);
  };

  const onDownloadActivitiesClick = async () => {
    const patientName = patientData.firstName + ' ' + patientData.lastName;
    const pn = patientName.split(' ');

    const zip = require('jszip')();

    for (const act of selected) {
      const aId = act.content['activity-id'];
      const aType = act.content.title;
      const aDate = (new Date(act.time * 1000)).toISOString().slice(0, -8);

      const filename = (pn[0][0] + pn[1] + '_' + aType + '_' + aDate)
        .replaceAll('-', '')
        .replaceAll(':', '');

      const res = await pryvApiCall(patientData.apiToken,
        eventsFromStreams(
          { 'all': ['activity-comments', `activity-${aId}`] },
        ));

      let commentText = '';
      if (res[0]['events'][0]) {
        commentText = res[0]['events'][0]['content'];
      } else {
        commentText = 'No Comments yet, author your own comments here!';
      }

      await fetchPdf(patientData.apiToken, aId, filename)
        .then( (pdf) => annotatePdf(pdf, {
          patientData: patientData,
          doctorData: doctorData,
          comment: commentText,
        }))
        .then( (aPdf) => {
          zip.file(filename + '.pdf', aPdf, { binary: true });
        });
    };

    zip.generateAsync({ type: 'blob' })
      .then((content) => {
        const cd = (new Date()).toISOString().slice(0, -8)
          .replaceAll('-', '')
          .replaceAll(':', '');
        saveAs(content, `pdf_files_${cd}.zip`);
      });
  };

  return (

    <Dialog
      open={props.open}
      onClose={handleClose}
      disableEnforceFocus>
      <Typography align="center" variant="h6"
        style={{
          margin: 10,
          marginTop: 20,
        }}>
        Select a Specific Assessment
      </Typography>
      <Divider
        style={{
          margin: 5,
        }}/>
      <div
        style={{
          width: 500,
        }}>
        <SelectAssessmentsList
          group={props.type}
          setSelection={(props.type === 'C') ? setSelected : () => {}}
          isComputing={false}
          setOpen = {props.setOpen}
        />
      </div>
      <Tooltip title="Download all selected reports">
        <Fade in={selected.length > 0}>
          <IconButton color="primary"
            style={{ position: 'absolute', left: 10, bottom: 15 }}
            onClick={onDownloadActivitiesClick}>
            <IoMdDownload style={{ fontSize: 30 }}/>
          </IconButton>
        </Fade>
      </Tooltip>
    </Dialog>
  );
}

AssessmentListDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  type: PropTypes.string,
};

import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';

import SelectDiagnosis from './SelectDiagnosis.js';
import {
  makeDateString,
  loadPatientData,
} from '../utils/auxFunctions.js';

import {
  AiFillEdit,
} from 'react-icons/ai';

/**
 * Shows detailed info about a patient.
 * @param {Object} props containing the patient profile data.
 * @return {jsx} The patient info component.
 */
export default function ExtendedPatientInfoDialog(props) {
  const patientData = loadPatientData();
  const hasAssessments = patientData['total-assessments'] > 0;

  let hasDiagnosis = false;
  try {
    hasDiagnosis = patientData.diagnosis.content.length > 0;
  } catch {
  }

  const [chooseCondition, setChooseCondition] = useState(!hasDiagnosis);
  const address = [
    patientData.address.street,
    `${patientData.address.zip} ${patientData.address.town}`,
    patientData.address.country,
  ];

  const handleEditClick = () => {
    setChooseCondition(true);
  };

  let diagnosis;
  if (typeof patientData.diagnosis === 'object') {
    if (patientData.diagnosis.content.length === 0) {
      diagnosis = ['none'];
    } else {
      diagnosis = patientData.diagnosis.content.map( (condition) => {
        if (condition.includes('Other')) {
          return `${condition.split('-')[0]} (Other)`;
        } else {
          return condition.split('-')[1];
        }
      });
    }
  } else {
    diagnosis = ['none'];
  }

  const leftTableItems = [
    {
      label: 'Username',
      value: patientData.username,
    },
    {
      label: 'Address',
      value: address,
    },
    {
      label: 'Phone No.',
      value: patientData.phoneNo,
    },
    {
      label: 'Date of Birth',
      value: makeDateString(patientData.birthdate),
    },
    {
      label: 'Height',
      value: `${patientData.height} cm`,
    },
    {
      label: 'Weight',
      value: `${patientData.weight} kg`,
    },
  ];

  const rightTableItems = [
    {
      label: 'Condition',
      value: diagnosis.join('\n'),
    },
    {
      label: 'Shoe Size',
      value: `${patientData.shoeSize} (EU)`,
    },
    {
      label: 'Impaired Side',
      value: patientData.impairedSide,
    },
    {
      label: 'Signed up',
      value: hasAssessments ?
        makeDateString(patientData.regDate) :
        'n/a',
    },
    {
      label: 'First Assessment',
      value: hasAssessments ?
        makeDateString(patientData.firstAssessment) :
        'n/a',
    },
    {
      label: 'Latest Assessment',
      value: hasAssessments ?
        makeDateString(patientData.lastAssessment) :
        'n/a',
    },
    {
      label: 'Total No. of Assessments',
      value: patientData['total-assessments'],
    },
  ];

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        maxWidth>
        <div style={{ width: 630, height: 350, overflow: 'hidden' }}>
          <DialogTitle style={{ paddingBottom: 10 }}>
            <Typography align="center" style={{ fontSize: 18 }}>
              {`${patientData.firstName} ${patientData.lastName}`}
            </Typography>
          </DialogTitle>
          <Divider style={{ marginBottom: 10 }}/>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item xs={6}>
              <Table size="small">
                <TableBody>
                  {
                    leftTableItems.map( (x, idx) => (
                      <TableRow key={`LTRE - ${idx}`}
                        style={{ position: 'relative' }}>
                        <TableCell align="left" style={{
                          position: 'absolute',
                          borderBottom: 'none',
                        }}>
                          <Typography style={{ fontSize: 12 }}
                            color="primary">
                            {x.label}
                          </Typography>
                        </TableCell>
                        <TableCell align="right"
                          style={{ position: 'relative' }}>
                          {
                            x.label === 'Address' ?
                              <Typography style={{ fontSize: 12 }}>
                                {x.value[0]}
                                <br/> {x.value[1]}
                                <br/> {x.value[2]}
                              </Typography> :
                              <Typography style={{ fontSize: 12 }}>
                                {x.value}
                              </Typography>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={6}>
              <Table size="small">
                <TableBody>
                  {
                    rightTableItems.map( (x, idx) => (
                      <TableRow key={`LTRE - ${idx}`}>
                        <TableCell align="left" style={{
                          position: 'absolute',
                          borderBottom: 'none',
                        }}>
                          <Typography style={{ fontSize: 12 }}
                            color="primary">
                            {x.label}
                          </Typography>
                        </TableCell>
                        <TableCell align="right"
                          style={{
                            display: 'flex',
                            position: 'relative',
                            maxHeight: 100,
                            overflow: 'scroll',
                          }}>
                          {
                            x.label === 'Condition' &&
                            <Tooltip title="Edit">
                              <IconButton
                                color="primary"
                                style={{ transform: 'translate( 10%, -10%)' }}
                                onClick={handleEditClick}>
                                <AiFillEdit style={{ fontSize: 20 }}/>
                              </IconButton>
                            </Tooltip>
                          }
                          <Typography
                            style={{ fontSize: 12, whiteSpace: 'pre-line' }}>
                            {x.value}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </div>
      </Dialog>
      <Modal
        open={chooseCondition}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return;
          };
        }}
        style={{
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <SelectDiagnosis
          force={!hasDiagnosis}
          onSave={() => setChooseCondition(false)}
          onCancel={() => setChooseCondition(false)}
        />
      </Modal>
    </div>
  );
};

ExtendedPatientInfoDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export const vibrationTypes = [
  {
    id: 'hs-ho-feedback',
    shorthand: 'HH',
    title: 'HS-HO-Vibration',
  },
  {
    id: 'metronome',
    shorthand: 'M',
    title: 'Metronome Vibration',
  },
  {
    id: 'swing-vibrate',
    shorthand: 'S',
    title: 'Swing Vibration',
  },
  {
    id: 'ground-vibrate',
    shorthand: 'G',
    title: 'Ground Vibration',
  },
];


export const vibId2TitleMap = (iid) => {
  return vibrationTypes.filter( (at) => at.id === iid)[0].title;
};

import React, { useRef, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  ClickAwayListener,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  FaClone,
} from 'react-icons/fa';
import {
  MdVibration,
} from 'react-icons/md';

import {
  activityTypes,
  category2IconMap,
} from '../definitions/activityTypes.js';

import { vibrationTypes } from '../definitions/vibrationTypes.js';

const extendedCategory2IconMap = { 'Overall': FaClone };
Object.assign(extendedCategory2IconMap, category2IconMap);

const extendedActivityTypes = [
  {
    id: 'overall-stats',
    shorthand: 'n/a',
    title: 'Overall',
    category: 'Overall',
  },
].concat(activityTypes);

const extendedVibrationTypes = vibrationTypes
  .concat(
    {
      id: 'any',
      shorthand: 'any',
      title: 'Any',
    },
    {
      id: 'without',
      shorthand: 'w/o',
      title: 'Without',
    },
  );

/**
 * Renders the "Activity type" toggle.
 * @param {Object} props of the toggle
 * @return {jsx} The patient page component.
 */
function ActivityTypeToggle(props) {
  const [selectedCategory, setCategory] = useState(
    extendedActivityTypes.filter(
      (at) => at.id === props.activityType,
    )[0].category,
  );
  const [popperOpen, setPopperOpen] = useState(false);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const [popperToggles, setPopperToggles] = useState([]);

  const [popperOpenVT, setPopperOpenVT] = useState(false);
  const [popperAnchorVT, setPopperAnchorVT] = useState(null);


  const size = (props.size !== undefined) ? props.size: 30;

  const onTbClick = (event, value) => {
    if (value === 'Overall') {
      props.setActivityType('overall-stats');
      setCategory('Overall');
      return;
    }
    openPopper(event.currentTarget);
    setPopperToggles(
      extendedActivityTypes.filter((at) => at.category === value),
    );
  };

  const onActivityTypeChange = (event, value) => {
    if (value !== null) {
      props.setActivityType(value);
      setCategory(extendedActivityTypes.filter(
        (at) => at.id === value)[0].category);
    };
  };

  const openPopper = (anchor) => {
    setPopperAnchor(anchor);
    setPopperOpen(true);
  };

  const onVibTbClick = (event, value) => {
    if (props.vibrationType === 'none') {
      openPopperVT(event.currentTarget);
    } else {
      props.setVibrationType('none');
    };
  };
  const openPopperVT = (anchor) => {
    setPopperAnchorVT(anchor);
    setPopperOpenVT(true);
  };

  const onVibrationTypeChange = (event, value) => {
    if (value !== null) {
      props.setVibrationType(value);
    };
  };

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setPopperOpen(false);
    };
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
  }, []);

  return (
    <React.Fragment>
      <ToggleButtonGroup>
        { Object.keys(extendedCategory2IconMap).map( (cat) => {
          const Icon = extendedCategory2IconMap[cat];
          let title = cat;
          if (cat === 'Overall' && props.replaceOverall !== undefined) {
            title = props.replaceOverall;
          };
          return (
            <Tooltip title={`${title}`} key={cat} placement="top">
              <ToggleButton key={cat} value={cat}
                onClick={onTbClick}
                style={{
                  fontSize: size,
                  color: (selectedCategory === cat) ? 'white': '#3a464c',
                  backgroundColor: (selectedCategory === cat) ? '#3a464c': null,
                }}>
                <Icon />
              </ToggleButton>
            </Tooltip>
          );
        })}
        <Popper
          open={popperOpen}
          anchorEl={popperAnchor}
          placement={props.popperPlacement}
          ref={ref}
          style={{
            zIndex: 1500,
            backgroundColor: 'white',
          }}>
          <ToggleButtonGroup
            value={props.activityType}
            exclusive
            onChange={onActivityTypeChange}>
            { popperToggles.map( (at, idx) => {
              const grey = 'rgba(230, 230, 230)';
              const bcColor = (at.id === props.activityType) ? grey : null;
              return (
                <Tooltip title={at.title} key={`TB-${idx}`}>
                  <ToggleButton value={at.id}
                    style={{
                      backgroundColor: bcColor,
                    }}>
                    <Typography style={{ fontSize: 0.6 * size }}>
                      <small> {at.shorthand} </small>
                    </Typography>
                  </ToggleButton>
                </Tooltip>
              );
            })}
          </ToggleButtonGroup>
        </Popper>
      </ToggleButtonGroup>
      { props.vibrationType &&
        <>
          <ToggleButtonGroup style={{ marginLeft: props.size }} >
            <Tooltip title="Vibration">
              <ToggleButton
                onClick={onVibTbClick}
                selected={!(props.vibrationType === 'none')}
                style={{
                  fontSize: size,
                  color: (props.vibrationType !== 'none') ? 'white': '#3a464c',
                  backgroundColor:
                    (props.vibrationType !== 'none') ? '#3a464c': null,
                }}>
                <MdVibration />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <Popper
            open={popperOpenVT}
            anchorEl={popperAnchorVT}
            placement={props.popperPlacement}
            style={{
              zIndex: 1500,
              backgroundColor: 'white',
            }}>
            <ClickAwayListener
              onClickAway={() => setPopperOpenVT(false)}>
              <ToggleButtonGroup
                value={props.vibrationType}
                exclusive
                onChange={onVibrationTypeChange}>
                { extendedVibrationTypes.map( (vt, idx) => {
                  const grey = 'rgba(230, 230, 230)';
                  const bcColor = (vt.id === props.vibrationType) ? grey : null;
                  let txtColor;
                  if ((['without', 'any'].includes(vt.id))) {
                    txtColor = '#3a464c';
                  }
                  return (
                    <Tooltip title={vt.title} key={`VTB-${idx}`}>
                      <ToggleButton value={vt.id}
                        style={{
                          backgroundColor: bcColor,

                        }}>
                        <Typography
                          variant={'caption'}
                          style={{
                            fontSize: 0.6 * size,
                            color: txtColor,
                            textTransform: 'none',
                          }}>
                          <small> {vt.shorthand} </small>
                        </Typography>
                      </ToggleButton>
                    </Tooltip>
                  );
                })}
              </ToggleButtonGroup>
            </ClickAwayListener>
          </Popper>
        </>
      }
    </React.Fragment>
  );
}

ActivityTypeToggle.propTypes = {
  replaceOverall: PropTypes.string,
  activityType: PropTypes.string,
  vibrationType: PropTypes.string,
  setActivityType: PropTypes.func,
  setVibrationType: PropTypes.func,
  size: PropTypes.number,
  popperPlacement: PropTypes.string,
};

export default ActivityTypeToggle;

import HealingIcon from '@material-ui/icons/Healing';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import CommentIcon from '@material-ui/icons/Comment';
import ForumIcon from '@material-ui/icons/Forum';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReportIcon from '@material-ui/icons/Report';
import {
  GiFalling,
  GiMedicines,
} from 'react-icons/gi';
import {
  FaNotesMedical,
} from 'react-icons/fa';

export const timelineDefs = {
  'Registration': {
    icon: AssignmentIndIcon,
  },
  'First Assessment': {
    icon: WalkIcon,
  },
  'New Diagnosis': {
    icon: FaNotesMedical,
  },
  'Appointment': {
    icon: ForumIcon,
  },
  'Accident': {
    icon: ReportIcon,
  },
  'Medication Adjustment': {
    icon: GiMedicines,
  },
  'Surgery': {
    icon: HealingIcon,
  },
  'Fall': {
    icon: GiFalling,
  },
  'Comment': {
    icon: CommentIcon,
  },
};

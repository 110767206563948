import React, { useState, useEffect } from 'react';
import {
  useHistory,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import clsx from 'clsx';
import Pryv from 'pryv';
import {
  AppBar,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Modal,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import HideIcon from '@material-ui/icons/FirstPage';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useStyles } from './useStyles.js';
import { extractGeneralDocData } from './utils/dataFetchFunctions.js';

import HomePage from './pages/Home.js';
import AboutPage from './pages/About.js';
import DocumentationPage from './pages/Documentation.js';
import PatientPage from './pages/Patient.js';
import AssessmentPage from './pages/Assessment.js';
import Comparison from './pages/Comparison.js';

import MenuList from './components/MenuList.js';
import Copyright from './components/Copyright.js';
import CustomSnackbar from './components/CustomSnackbar.js';
import HomeButton from './components/HomeButton.js';
import SelectDBinstance from './components/SelectDBinstance.js';
import AccountInfoDialog from './components/AccountInfoDialog.js';

import CookieConsent from 'react-cookie-consent';

import MyLoginButton from './components/CustomLoginButton.js';

import { doctorDataRequest } from './data/accessRights.js';

import {
  pryvApiCall,
  deleteEventCall,
  doctorDataApiCall,
} from './data/apiCalls.js';

import appSettings from './appSettings.js';
import theme from './theme.js';

/**
 * Exctract Event content from api response
 * @param {Array} apiResponse returned from an api call
 * @param {String} streamId stream Id to extract data for.
 * @return {Array} new array containing all events of the specified streamId
 */
function extractEventContent(apiResponse, streamId) {
  const newArray = [];
  const eventArray = apiResponse[0]['events'];
  const eventObj = eventArray.filter((x) => x.streamId === streamId);
  eventObj.forEach((item) => newArray.push({
    token: item.content,
    eventId: item.id,
  }));
  return newArray;
}

/**
 * Makes the "App" component.
 * @return {jsx} The app bar component.
 */
function App() {
  const classes = useStyles();
  const history = useHistory();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerHide, setDrawerHide] = useState(true);
  const [apiTokens, setApiTokens] = useState([]);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isDbLinked, setDbLinked] = useState(false);
  const [isCheckingDb, setCheckingDb] = useState(false);
  const [toLogout, setLogout] = useState(false);
  const [docData, setDocData] = useState(undefined);
  const [docApiToken, setDocApiToken] = useState(undefined);
  const [serviceUrl, setServiceUrl] = useState(undefined);
  const [dbInstance, setDbInstance] = useState(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [uidOpen, setUidOpen] = useState(false);


  const [isConnecting, setConnecting] = useState(false);

  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false,
  });

  const themeConfig = createTheme(theme);

  const authSettings =
    {
      spanButtonID: 'login-button',
      onStateChange: pryvAuthStateChange,
      authRequest: doctorDataRequest,
    };

  const appBarTheme = appSettings.style;

  // Launch DB instance Selection
  const onConfirm = (selection) => {
    window.sessionStorage.setItem(
      'serviceUrl', appSettings.aux.serviceUrl[selection],
    );
    window.sessionStorage.setItem(
      'dbInstance', selection,
    );
    setServiceUrl(appSettings.aux.serviceUrl[selection]);
    setDbInstance(selection);
    setDialogOpen(false);
  };


  const runChooseDB = () => {
    if (window.sessionStorage.getItem('serviceUrl') === null) {
      const bn = process.env.REACT_APP_GIT_BRANCH;
      if (bn !== 'staging' && bn !== 'master' && bn !== 'certified') {
        setDialogOpen(true);
      } else {
        setServiceUrl(appSettings.aux.serviceUrl);
      };
    } else {
      setServiceUrl(window.sessionStorage.getItem('serviceUrl'));
      setDbInstance(window.sessionStorage.getItem('dbInstance'));
    }
  };

  useEffect(() => {
    runChooseDB();
  }, []);

  useEffect(() => {
    if (!isConnecting && serviceUrl !== undefined) {
      startAuth();
    }
  }, [serviceUrl]);

  // This calls on setting up Authentication process, only done once!
  /**
   * Start Auth Process
   *  calls on setting up Authentication process.
   */
  async function startAuth() {
    setConnecting(true);
    fetch(serviceUrl)
      .then( (resp) => {
        return resp.json();
      })
      .then( (serviceInfoJson) => {
        Pryv.Auth.setupAuth(
          authSettings,
          null,
          serviceInfoJson,
          MyLoginButton,
        );
      })
      .catch( (e) => {
        alert('Failed to run startAuth', e);
      });
  }

  /**
   * establish connection to the database, which entails fetching the doc's
   info, checking all of the patient accesses. All 'results' are stored in
   corresponding React states.
   * @param {Array} apiEndpoint returned from an api call
   */
  async function establishDbConnection(apiEndpoint) {
    if (apiEndpoint === null || apiEndpoint === undefined) {
      return;
    }
    setCheckingDb(true);
    pryvApiCall(apiEndpoint, doctorDataApiCall)
      .then((result) => {
        console.log(result);
        // Hack missing username event
        result[0]['events'].push(
          {
            streamIds: ['.username'],
            streamId: '.username',
            content: apiEndpoint.split('@')[1].split('.')[0],
          },
        );

        setDocData(result);
        const dData = extractGeneralDocData(result);
        dData.username;
        sessionStorage.setItem('doctorData', JSON.stringify(dData));
        sessionStorage.setItem('docEP', apiEndpoint);
        sessionStorage.setItem(
          'affiliation',
          result[0].events.filter(
            (re) => re.streamIds.includes('.affiliation'),
          )[0].content,
        );
        setLoggedIn(true);
        return extractEventContent(result, 'patient-accesses');
      })
    // Check Api Endpoints & delete if necessary.
      .then((aData) => checkApiEndpoints(aData, apiEndpoint) )
      .then((verifiedTokens) => {
        // Set States Accordingly
        setApiTokens(verifiedTokens);
        setDbLinked(true);
        setCheckingDb(false);
      })
      .catch( (e) => {
        console.log(e);
      });
  }

  /**
   * Check Api Endpoints of a doc's patient accesses stream, by a simple fetch
   call. If this call does not succeed, the api endpoint is assumed to be
   invalid. NOTE; it currently not deleted from the doc's patient-accessess
   stream.
   * @param {Array} accessData an array of objects containing endpoint and id
  for each patient.
   * @param {String} docApiEP api endpoint to the doc's database.
   * @return {Array} of valid api endpoints.
   */
  async function checkApiEndpoints(accessData, docApiEP) {
    const ap = accessData.map( (ad) => {
      const connection = new Pryv.Connection(ad.token);
      return connection.accessInfo();
    });

    return Promise.allSettled(ap)
      .then((sap) => {
        const toKeepTokens = [];
        for (let ii = 0; ii < sap.length; ii++) {
          if (sap[ii].status === 'fulfilled') {
            toKeepTokens.push({
              tk: accessData[ii].token,
              userUpToDate:
                sap[ii].value.permissions
                  .map( (x) => x.streamId )
                  .includes('diagnosis'),
            });
          } else {
            if (sap[ii].reason.status === 403) {
              pryvApiCall(docApiEP, deleteEventCall(accessData[ii].eventId))
                .then((res) => console.log(res));
            }
          }
        }
        return toKeepTokens;
      });
  };
  /**
   * Pryv Autherisation State Change
   Handles changes in the state, such as fetching db data once a user is logged
   in, or handling the case when a user logs out.
   * @param {Object} state of pryv's autherisation process.
   */
  function pryvAuthStateChange(state) {
    if (state.id === Pryv.Auth.AuthStates.AUTHORIZED) {
      const docApiEndpoint = state.apiEndpoint;
      setDocApiToken(docApiEndpoint);
      establishDbConnection(docApiEndpoint);
    }
  }

  // Procedure initiated if logout initiated by click
  const confirmLogout = () => {
    setLogout(false);
    setLoggedIn(false);
    setDocApiToken(null);
    setApiTokens([]);
    setDbLinked(false);
    setConnecting(false);
    setDocData(undefined);
    setDbInstance(undefined);
    setServiceUrl(undefined);
    sessionStorage.clear();
    history.push('/');
    runChooseDB();
  };

  const cancelLogout = () => {
    setLogout(false);
  };

  const onAcceptCookies = (acceptedByScrolling) => {
    if (acceptedByScrolling) {
      // this is currently disabled anyway, just here for completeness
      // need to check with legal body if this is allowed
    } else {
      // default cookie acceptance is false, thus we need to reload in order
      // to propagate the setting if it is accepted
      window.location.reload(false);
    }
  };

  const onDeclineCookies = () => { };

  // Handle manual refresh
  useEffect(() => {
    const docApiEndpoint = sessionStorage.getItem('docEP');
    if (docApiEndpoint !== undefined) {
      establishDbConnection(docApiEndpoint);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const extractAccountInfo = () => {
    const info = {
      '.username': '',
      '.affiliation': '',
      '.email': '',
    };
    for (const k of Object.keys(info)) {
      info[k] = docData[0].events.filter( (x) => x.streamId === k)[0].content;
    }
    return info;
  };
  /* eslint-disable quote-props*/

  // Refresh persistent check if user is logged in
  const hasLoggedIn = sessionStorage.getItem('docEP') !== null;

  return (
    <div className={classes.root} style={{ backgroundColor: '#e3e4e6' }}>
      <CookieConsent
        location="bottom"
        enableDeclineButton={true}
        buttonText='I accept'
        buttonStyle={{
          background: '#4ebfff',
          fontWeight: 'bolder',
          fontSize: '14px',
          borderRadius: 5,
          height: 35,
        }}
        declineButtonStyle={{
          border: 'solid',
          borderWidth: 0.5,
          color: 'white',
          background: 'none',
          fontWeight: 'bolder',
          fontSize: '14px',
          borderRadius: 5,
          height: 35,
        }}
        onAccept={ (acceptedByScrolling) => {
          onAcceptCookies(acceptedByScrolling);
        }}
        onDecline={ () => {
          onDeclineCookies();
        }}
        overlay={true}
      >
        This website uses cookies 🍪 to enhance your user experience. Click
        &apos;I accept&apos; to continue, or &apos;I decline&apos;
        to opt out if you wish.
      </CookieConsent>
      <ThemeProvider theme={themeConfig}>
        <CssBaseline />
        <AppBar
          style={{ backgroundColor: appBarTheme.color }}
          className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setDrawerOpen(true);
                setDrawerHide(false);
              }}
              className={clsx(classes.menuButton,
                drawerOpen && classes.menuButtonHidden)}>
              <MenuIcon />
            </IconButton>
            <HomeButton/>
            <div className={classes.grow}/>
            <Typography variant="overline"
              style={{
                color: appBarTheme.textColor,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}>
              {appBarTheme.text}
            </Typography>
            { !['Staging Build', ''].includes(appSettings.style.text) &&
              <Typography variant="overline"
                style={{
                  color: appBarTheme.textColor,
                  position: 'absolute',
                  top: '80%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: 10,
                }}>
                {dbInstance}-DB
              </Typography>
            }
            <IconButton
              color="inherit"
              onClick={ () => (establishDbConnection(docApiToken))}>
              {isCheckingDb ?
                <CircularProgress color="secondary" size={24} /> :
                (isDbLinked ?
                  <LinkIcon className={classes.successColor} /> :
                  <LinkOffIcon color="error" />)}
            </IconButton>
            <div>
              <Button
                color="inherit"
                id="login-button"
                onClick={() => {
                  if (isLoggedIn) setLogout(true); else return;
                }}>
                { !isLoggedIn ? 'Login' : 'Logout' }
              </Button>
            </div>
            { isLoggedIn &&
              <React.Fragment>
                <IconButton
                  onClick={ () => setUidOpen(true)}>
                  <AccountCircleIcon
                    style={{ color: 'white' }}/>
                </IconButton>
                <AccountInfoDialog
                  isOpen={uidOpen}
                  onClose={() => setUidOpen(false)}
                  userInfo={extractAccountInfo()}/>
              </React.Fragment>
            }
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper,
              !drawerOpen && classes.drawerPaperClose,
              drawerHide && classes.drawerPaperHide),
          }}
          open={drawerOpen}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <MenuList />
          {!drawerOpen &&
            <ListItem
              button
              onClick={() => setDrawerHide(true)}
              style={{ position: 'absolute', bottom: 0 }}>
              <ListItemIcon>
                <HideIcon />
              </ListItemIcon>
            </ListItem>}
          {drawerOpen && <Copyright />}
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {snackbar.show &&
            <CustomSnackbar
              content={snackbar}
              handleClose={() => setSnackbar({ show: false })}
            />}
          <Container maxWidth="lg" className={classes.container} >
            <Switch>
              <Route exact path="/">
                <HomePage
                  isLoggedIn={ isLoggedIn }
                  apiTokens={ apiTokens }
                  doctorData={ docData }/>
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/documentation">
                <DocumentationPage />
              </Route>
              <Route path="/patient/:username">
                { hasLoggedIn ? <PatientPage /> :
                  <Redirect to={{ pathname: '/' }} />
                }
              </Route>
              <Route path="/assessment/:id?">
                { hasLoggedIn ?
                  <AssessmentPage
                    token={apiTokens.map((x) => x.tk)}
                    setSnackbar={setSnackbar} /> :
                  <Redirect to={{ pathname: '/' }} />
                }
              </Route>
              <Route path="/compare/:username">
                { hasLoggedIn ? <Comparison /> :
                  <Redirect to={{ pathname: '/' }} />
                }
              </Route>
              <Redirect to={{ pathname: '/' }} />
            </Switch>
          </Container>
        </main>
      </ThemeProvider>
      <SelectDBinstance
        onConfirm={onConfirm}
        isOpen={dialogOpen}
      />
      <div>
        <Modal
          open={toLogout}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return;
            }
          }}>
          <div
            style={{
              position: 'fixed',
              height: 155,
              width: 350,
              left: '50vw',
              top: '50vh',
              transform: 'translate(-50%, -50%)',
            }}>
            <Paper
              className={classes.paper}
              style={{ height: '100%', width: '100%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  variant="overline" align="center" style={{ fontSize: 15 }}>
                  Warning
                </Typography>
              </div>
              <Divider />
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                padding: 10,
              }}>
                <Typography color="textSecondary" align="left"
                  style={{ fontSize: 18 }}>
                  Are you sure you want logout?
                </Typography>
              </div>
              <Fab onClick={cancelLogout}
                style={{ top: 10, left: 110, height: 10, width: 10 }}>
                <div
                  style={{
                    border: 'solid',
                    borderWidth: 0.5,
                    borderColor: '#3a464c',
                    backgroundColor: 'white',
                    height: 35,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}>
                  <Grid container justifyContent="center" alignItems="center"
                    style={{ height: '100%' }}>
                    <Typography variant="button"
                      style={{ fontSize: 14, color: '3a464c' }}>
                      Cancel
                    </Typography>
                  </Grid>
                </div>
              </Fab>
              <Fab onClick={confirmLogout} id="confirm-logout-button"
                style={{ top: 10, left: 210, height: 10, width: 10 }}>
                <div
                  style={{
                    backgroundColor: '#3a464c',
                    height: 35,
                    borderRadius: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: -5,
                    paddingBottom: -5,
                  }}>
                  <Grid container justifyContent="center" alignItems="center"
                    style={{ height: '100%' }}>
                    <Typography variant="button"
                      style={{ fontSize: 13, color: 'white' }}>
                      Confirm
                    </Typography>
                  </Grid>
                </div>
              </Fab>
            </Paper>
          </div>
        </Modal>
      </div>
    </div >
  );
}

export default App;

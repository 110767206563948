/**
 * Make Time String
 * Generates a object containing the time and unit of the passed time.
 * @param {number} timeInS the time in seconds
 * @return {Object} containing the time and unit, both as strings.
 */
export function makeTimeString(timeInS) {
  const res = {};
  if (timeInS < 60) {
    res.value = timeInS.toFixed(0);
    res.unit = 's';
  } else if (timeInS / 60 < 60) {
    const min = Math.floor(timeInS / 60).toString();
    let sec = Math.round(timeInS % 60).toString();
    if (sec.length < 2) {
      sec = '0' + sec;
    }
    res.value = `${min}:${sec}`;
    res.unit = 'min';
  } else if (timeInS / (60 ** 2) > 1) {
    const hrs = Math.floor(timeInS / 60**2).toString();
    let min = Math.round((timeInS / 60**2) % 60).toString();
    if (min.length < 2) {
      min = '0' + min;
    }
    res.value = `${hrs}:${min}`;
    res.unit = 'h';
  } else {
    res.value = timeInS.toFixed(0);
    res.unit = '?';
  }
  return res;
};


/**
 * Make Distance String
 * @param {number} distanceInM distance in meters
 * @return {Object} containing value and unit as strings.
 */
export function makeDistanceString(distanceInM) {
  const res = {};
  if (distanceInM < 1000) {
    if (distanceInM < 100) {
      res.value = distanceInM.toFixed(1);
      res.unit = 'm';
    } else {
      res.value = distanceInM.toFixed(0);
      res.unit = 'm';
    };
  } else {
    res.value = (distanceInM / 1000).toFixed(2);
    res.unit = 'km';
  }
  return res;
}

/**
  * Equivalent to sleep in python
  * @param {number} ms time to pause in ms
  * @return {Promise} a promise that is fullfilled after x ms
  */
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const defaultDateFormat = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const makeDateString = (date, format = defaultDateFormat) => {
  const ds = date.toLocaleString('en-DE', format);
  return ds;
};

export const makeTimeOfDayString = (date) => {
  const hrs = date.getHours().toString();
  let min = date.getMinutes().toString();
  if (min.length < 2) min = '0' + min;
  return `${hrs}:${min}`;
};

export const dateTimeString = (dd) => {
  const ds = makeDateString(dd);
  const hrs = dd.getHours().toString();
  let min = dd.getMinutes().toString();
  if (min.length < 2) min = '0' + min;
  const t = hrs + ':' + min;

  return `${ds}  @  ${t}`;
};


export const loadPatientData = () => {
  const pd = JSON.parse(sessionStorage.getItem('patientData'));

  /*
    Read all dates in a patientData file and convert them from string to
    date obj.
  */
  const dateIds = [
    'birthdate',
    'firstAssessment',
    'lastAssessment',
    'regDate',
  ];

  dateIds.forEach( (id) => {
    pd[id] = new Date(pd[id]);
  });

  return pd;
};

export const loadDoctorData = () => {
  const dd = JSON.parse(sessionStorage.getItem('doctorData'));
  return dd;
};

export const loadAssessmentComment = () => {
  const ct = sessionStorage.getItem('assComment');
  return ct;
};

import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

Chart.defaults.global.legend.display = false;

/**
 * Plots timeseries data in a toggleable view
 * @param {Object} props The component properties.
 * @return {jsx} timeseries plot component.
 */
export default function TimeSeriesPlot(props) {
  const handleAxisToggle = (event, value) => {
    if (value === null) {
      return;
    };
    props.setXAxisType(value);
  };

  return (
    <React.Fragment>
      <div style={{ height: '100%' }}>
        <div onClick={props.onClick}
          style={{ display: 'flex', height: 'calc(100% - 50px)' }}>
          {props.xAxisType === 'Stride No' ?
            <Line data={props.data} options={props.options}
              lineAtIndex={[2, 4, 8]}/> :
            <Bar data={props.data} options={props.options}/>
          }
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', height: 50 }}>
          <ToggleButtonGroup
            align="center"
            value={props.xAxisType}
            exclusive onChange={handleAxisToggle}
            style={{ height: 20 }}>
            <ToggleButton value="Stride No">
              <Typography variant="subtitle1"
                style={{
                  fontSize: 11.5,
                  textTransform: 'none',
                }}>
                Stride No.
              </Typography>
            </ToggleButton>
            <ToggleButton value="Time">
              <Typography variant="subtitle1"
                style={{
                  fontSize: 11.5,
                  textTransform: 'none',
                }}>
                Time [s]
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

TimeSeriesPlot.propTypes = {
  data: PropTypes.object,
  options: PropTypes.object,
  xAxisType: PropTypes.string,
  setXAxisType: PropTypes.func,
  onClick: PropTypes.func,
};

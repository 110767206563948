import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import AddTimelineEntryDialog from './AddTimelineEntryDialog';
import { useStyles } from '../useStyles.js';

/**
 * Displays patients as a card.
 * @param {Object} props The component properties.
 * @return {jsx} The patient card component.
 */
export default function TimelineCard(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const isEditable = (
    props.entry.type !== 'Registration' &&
    props.entry.type !== 'First Assessment'
  );

  const buttonStyle = {
    'position': 'absolute',
    'top': 1,
    'right': 7,
  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    props.handleDeleteEntry(props.entry.id);
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpen(true);
  };

  const handleEditEntry = (entry) => {
    props.handleEditEntry(entry);
    setOpen(false);
  };
  return (
    <Card>
      <CardContent style={{ maxHeight: 100, padding: 10 }}>
        <Typography align="left" variant="h6" color="secondary"
          style={{ fontSize: 13 }}>
          { props.entry.type }
        </Typography>
        <div style={{ maxHeight: 100, overflow: 'scroll' }}>
          <Typography align="left" variant="body2" color="primary"
            style={{ fontSize: 11 }}>
            { props.entry.content }
          </Typography>
        </div>
      </CardContent>
      {isEditable &&
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          style={ buttonStyle }
          onClick={ handleClick }
          className={classes.iconButton}>
          <MoreVertIcon />
        </IconButton>
      }
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          style={{ 'display': 'inline' }}
          onClick={handleClose}>
          <IconButton
            onClick={handleEditClick}>
            <FontAwesomeIcon
              size='xs'
              icon={faEdit}/>
          </IconButton>
        </MenuItem>
        <MenuItem
          style={{ 'display': 'inline' }}>
          <IconButton
            onClick={ handleDeleteClick }>
            <FontAwesomeIcon
              size='xs'
              icon={faTrashAlt}
              color='error'/>
          </IconButton>
        </MenuItem>
      </Menu>
      <AddTimelineEntryDialog
        entry={props.entry}
        isOpen={open}
        onSubmit={handleEditEntry}
        onCancel={ () => {
          setOpen(false);
        } }
      />
    </Card>
  );
}

TimelineCard.propTypes = {
  entry: PropTypes.any,
  handleDeleteEntry: PropTypes.func,
  handleEditEntry: PropTypes.func,
};

import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

/**
 * Select DB dialog component
 * @param {Oject} props returned from an api call
 * @return {jsx} select db instance component
 */
export default function SelectDBinstance(props) {
  const [selection, setSelection] = useState('production');
  const handleChange = (event, value) => {
    if (value === null) {
      return;
    };
    setSelection(value);
  };

  const onConfirmClick = () => {
    props.onConfirm(selection);
  };

  const onCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      onCloseDialog(event, reason);
    }
  };


  return (
    <Dialog open={props.isOpen} onClose={onCloseDialog} maxWidth="xs">
      <DialogTitle align="center">
        <strong> Select DB-Instance </strong>
      </DialogTitle>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <Typography variant="subtitle1">
            You must select a DB instance to connect to. Once selected you
            cannot switch instances during a session. To start a new session,
            close your tab and open the website in a new one. This will prompt
            this dialog to be shown again.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center"
          style={{ marginTop: '20px', marginBottom: '20px' }}>
          <ToggleButtonGroup
            align="center"
            size="large"
            value={selection}
            exclusive onChange={handleChange}>
            <ToggleButton value="development">
              Development
            </ToggleButton>
            <ToggleButton value="staging">
              Staging
            </ToggleButton>
            <ToggleButton value="production">
              Production
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid container justifyContent="center"
          style={{ marginBottom: '20px' }}>
          <Button
            align="center"
            variant="contained"
            color="primary"
            onClick={onConfirmClick}>
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

SelectDBinstance.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

import React from 'react';
import { PropTypes } from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { useStyles } from '../useStyles';
import { Alert } from '@material-ui/lab';

/**
 * Shows success, warn, error or info messages.
 * @param {Object} props The component properties.
 * @return {jsx} The snackbar component.
 */
export default function CustomSnackbar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={3000}
      className={classes.snackbar}
      open={open}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={props.content.severity}
        variant="filled"
      >
        {props.content.message}
      </Alert>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  content: PropTypes.object,
  handleClose: PropTypes.func,
};

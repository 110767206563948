import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  TextField,
} from '@material-ui/core';

import {
  pryvApiCall,
  createEventCall,
  updateEventCall,
} from '../data/apiCalls.js';

import { withStyles } from '@material-ui/core/styles';

import CustomSnackbar from '../components/CustomSnackbar.js';

import {
  loadPatientData,
} from '../utils/auxFunctions.js';

const CustomTextField = withStyles({
  root: {
    '& .MuiFormLabel-root.Mui-error': {
      'color': 'orange !important',
    },
    '& .MuiFilledInput-underline.Mui-error:after': {
      'borderBottomColor': 'orange !important',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      'color': 'orange !important',
    },
  },
})(TextField);

/**
 * Renders the saveComparisonDialog element
 * @param {Object} props The component properties.
 * @return {jsx} The component.
 */
export default function SaveComparisonDialog(props) {
  const patientData = loadPatientData();

  const [comparisonName, setComparisonName] = useState('');
  const [snackbar, setSnackbar] = React.useState({
    message: '',
    severity: '',
    show: false,
  });

  const textRef = useRef();

  const savedCompNames = props.savedComparisons.map((x) => x.content.name);
  const onInputChange = () => {
    setComparisonName(textRef.current.value);
  };

  const onSaveClick = () => {
    if (comparisonName === '') {
      setSnackbar({
        message: 'Please Enter a Name',
        severity: 'error',
        show: true,
      });
      setComparisonName('');
      return;
    }

    const cContent = {
      patient: patientData.username,
      name: comparisonName,
      activities: props.selectedActivities,
    };

    let call;
    let callType;
    if (savedCompNames.includes(comparisonName)) {
      callType = 'updated';

      const cEvent = props.savedComparisons.filter(
        (x) => x.content.name === comparisonName,
      )[0];
      call = updateEventCall(
        cEvent.id,
        cContent,
      );
    } else {
      callType = 'created';
      call = createEventCall(
        ['comparisons'],
        'comparison-magnes/nushu-v1',
        cContent,
        (new Date()).getTime() / 1000,
      );
    };

    const tk = sessionStorage.getItem('docEP');
    pryvApiCall(tk, call)
      .then((res) => {
        if (Object.keys(res).includes('error')) {
          throw Error(`Bad api call, DB returned ${res}`);
        }
        const newEvent = res[0].event;

        // If event already exists, replace it, otherwise append.
        const _savedComparisons = [...props.savedComparisons];
        if (callType === 'updated') {
          const eIdx = _savedComparisons.findIndex( (x) => {
            return x.content.name === newEvent.content.name;
          });
          _savedComparisons.splice(eIdx, 1, newEvent);
        }
        _savedComparisons.push(newEvent);

        props.setSavedComparisons(_savedComparisons);

        setSnackbar({
          message: `Comparison successfully ${callType}`,
          severity: 'success',
          show: true,
        });
        props.setOpen(false);
      })
      .catch((err) => {
        setSnackbar({
          message: `Ooops, something went wrong.`,
          severity: 'error',
          show: true,
        });
        console.log(err);
      });
  };

  const onCancelClick = () => {
    setComparisonName('');
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      {snackbar.show &&
        <CustomSnackbar
          content={snackbar}
          handleClose={() => setSnackbar({
            message: '',
            severity: '',
            show: false,
          })}/>
      }
      <Dialog
        maxWidth
        open={props.isOpen}>
        <div style={{ width: 400, padding: 10 }}>
          <CustomTextField
            label="Enter Comparison Name"
            variant="filled"
            helperText={
              savedCompNames.includes(comparisonName) ?
                'A comparison already exists with that name. ' +
                'Do you want to update it?' :
                undefined
            }
            inputRef={textRef}
            onChange={onInputChange}
            error={savedCompNames.includes(comparisonName)}
            style={{ padding: 10, display: 'flex' }}/>
        </div>
        <DialogActions style={{ marginTop: -10 }}>
          <Button
            onClick={onCancelClick}
            variant="outlined">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSaveClick}>
            { savedCompNames.includes(comparisonName) ?
              'Update': 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

SaveComparisonDialog.propTypes = {
  selectedActivities: PropTypes.object,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  onSave: PropTypes.func,
  savedComparisons: PropTypes.array,
  setSavedComparisons: PropTypes.func,
};


import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';

import {
  makeDateString,
  loadPatientData,
} from '../utils/auxFunctions.js';


/**
 * Shows detailed info about a patient.
 * @param {Object} props containing the patient profile data.
 * @return {jsx} The patient info component.
 */
export default function PatientInfo(props) {
  const patientData = loadPatientData();
  let diagnosis;
  if (typeof patientData.diagnosis === 'object') {
    if (patientData.diagnosis.content.length === 0) {
      diagnosis = ['none'];
    } else {
      diagnosis = patientData.diagnosis.content.map( (condition) => {
        if (condition.includes('Other')) {
          return condition;
        } else {
          return condition.split('-')[1];
        }
      });
    }
  } else {
    diagnosis = ['none'];
  }

  const tableItems = [
    {
      label: 'Condition',
      value: diagnosis.join('\n'),
    },
    {
      label: 'Date of Birth',
      value: makeDateString(patientData.birthdate),
    },
    {
      label: 'Height',
      value: `${patientData.height} cm`,
    },
    {
      label: 'Weight',
      value: `${patientData.weight} kg`,
    },
    {
      label: 'Shoe Size',
      value: `${patientData.shoeSize} (EU)`,
    },
  ];
  return (
    <Table size="small">
      <TableBody>
        {
          tableItems.map( (x, idx) => (
            <TableRow key={`TR - ${idx}`}>
              <TableCell align="left" style={{ borderBottom: 'none' }}>
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {x.label}
                </Typography>
              </TableCell>
              <TableCell align="right" style={{ borderBottom: 'none' }}>
                <div style={{
                  maxHeight: 65,
                  overflow: 'scroll',
                }}>
                  <Typography variant="body2"
                    style={{ fontSize: 12, whiteSpace: 'pre-line' }}>
                    {x.value}
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
};

const serviceURLs = {
  'development': 'https://reg.nushu-dev.pryv.io/service/info',
  'staging': 'https://reg.nushu-staging.pryv.io/service/info',
  'production': 'https://reg.nushu.health/service/info',
};

const getSettings = () => {
  switch (process.env.REACT_APP_GIT_BRANCH) {
  case 'development':
    return {
      style: {
        color: '#9f7559',
        text: 'Development Build',
        textColor: 'white',
      },
      aux: {
        serviceUrl: serviceURLs,
      },
    };
  case 'staging':
    return {
      style: {
        color: '#6d5e52',
        text: 'Staging Build',
        textColor: 'white',
      },
      aux: {
        serviceUrl: serviceURLs['staging'],
      },
    };
  case 'master':
    return {
      style: {
        color: 'secondary',
        text: '',
        textColor: 'transparent',
      },
      aux: {
        serviceUrl: serviceURLs['production'],
      },
    };
  case 'certified':
    return {
      style: {
        color: 'primary',
        text: '',
        textColor: 'transparent',
      },
      aux: {
        serviceUrl: serviceURLs['production'],
      },
    };
  default:
    return {
      style: {
        color: '#6d5e52',
        text: '... under construction ...',
        textColor: 'white',
      },
      aux: {
        serviceUrl: serviceURLs,
      },
    };
  }
};

const appSettings = getSettings();

export default appSettings;

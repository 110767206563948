import React from 'react';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import { makeDateString } from '../utils/auxFunctions.js';

const columns = [
  {
    field: 'username',
    headerName: 'Username',
    width: 140,
  },
  {
    field: 'firstName',
    headerName: 'Surname',
    width: 130,
  },
  {
    field: 'lastName',
    headerName: 'Name',
    width: 130,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'firstAssessment',
    headerName: 'First assessment date',
    type: 'number',
    width: 150,
  },
  {
    field: 'lastAssessment',
    headerName: 'Last assessment date',
    type: 'number',
    width: 150,
  },
  {
    field: 'total-assessments',
    headerName: 'Total assessments',
    type: 'number',
    width: 180,
  },
  {
    field: 'trend',
    headerName: 'Trend',
    type: 'number',
    width: 120,
  },
];

/**
 * Displays patients data in a grid table.
 * @param {Object} props The component properties.
 * @return {jsx} The patient data table component.
 */
export default function PatientTable(props) {
  const history = useHistory();
  const redirectToPage = (row) => {
    const patientData = row.row;

    history.push({ pathname: '/patient/' + patientData.username });
  };


  const formattedData = () => {
    props.data.map( (row) => {
      if (row['total-assessments'] === 0) {
        row.firstAssessment = 'n/a';
        row.lastAssessment = 'n/a';
      } else {
        row.firstAssessment = makeDateString(row.firstAssessment);
        row.lastAssessment = makeDateString(row.lastAssessment);
      }
    });
  };

  formattedData();

  return (
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        columns={columns}
        onRowClick={redirectToPage}
        pageSize={13}
        rows={props.data}
      />
    </div>
  );
}

PatientTable.propTypes = {
  data: PropTypes.array,
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  ButtonBase,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListSubheader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  BsBookmarkStarFill,
} from 'react-icons/bs';
import {
  MdVibration,
} from 'react-icons/md';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useStyles } from '../useStyles.js';
import {
  makeDateString,
  loadPatientData,
} from '../utils/auxFunctions.js';
import ActivityTypeToggle from './ActivityTypeToggle.js';
import { vibId2TitleMap } from '../definitions/vibrationTypes.js';
import { id2TitleMap } from '../definitions/activityTypes.js';

/**
 * SelectAssessmentsList
 * @param {Object} props The component properties.
 * @return {jsx} The SelectAssessmentsList component.
 */
function SelectAssessmentsList(props) {
  const classes = useStyles();
  const history = useHistory();

  const patientData = loadPatientData();
  const analyzedActs = patientData.activities.filter(
    (item) => item.content.isAnalyzed,
  );

  const [filter, setFilter] = React.useState({
    activityType: 'overall-stats',
    vibrationType: 'none',
  });
  const [dispList, setDispList] = React.useState([]);
  const [checked, setChecked] = React.useState([0]);

  // Set checkmarks if selectedActs is passed.
  useEffect(() => {
    if (props.selectedActs) {
      setChecked([0, ...props.selectedActs]);
    }
  }, [props.selectedActs]);

  // Set which activity Ids have been bookmarked for comparison
  let comparisonMarks = JSON.parse(
    sessionStorage.getItem('markedActivities'),
  );
  comparisonMarks = comparisonMarks ? comparisonMarks : [];
  const markedIds = comparisonMarks.map((idx) => {
    return analyzedActs[idx].content['activity-id'];
  });

  let color;
  let dColor;
  switch (props.group) {
  case 'A':
    color = '#54dea9';
    dColor = '#c0decd';
    break;
  case 'B':
    color = '#4ebfff';
    dColor = '#afd8ec';
    break;
  default:
    color = '#3a464c';
    dColor = 'lightgrey';
  }


  useEffect(() => {
    setDispList(formatActList(analyzedActs));
  }, []);

  useEffect(() => {
    let selected = analyzedActs;

    // filter by activity type
    if (filter.activityType !== 'overall-stats') {
      selected = selected
        .filter((x) => x.content.title === filter.activityType);
    }

    // filter by vibration
    switch (filter.vibrationType) {
    case 'none': break;
    case 'any':
      selected = selected
        .filter((x) => x.content.vibrationType);
      break;
    case 'without':
      selected = selected
        .filter((x) => !x.content.vibrationType);
      break;
    default:
      selected = selected
        .filter((x) => x.content.vibrationType === filter.vibrationType);
      break;
    }
    if (filter.vibrationType !== 'none') {

    };
    setDispList(formatActList(selected));
  }, [filter]);

  const handleToggle = (value) => {
    const currentIndex = checked.findIndex((x) => x.id === value.id );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    props.setSelection(newChecked.slice(1));
    setChecked(newChecked);
  };

  const dateTimeString = (dd) => {
    const ds = makeDateString(dd);

    let hrs = dd.getHours().toString();
    let min = dd.getMinutes().toString();

    if (hrs.length < 2) hrs = '0' + hrs;
    if (min.length < 2) min = '0' + min;
    const t = hrs + ':' + min;

    return `${ds}  @  ${t}`;
  };

  const formatActList = (actList) => {
    const list = actList;
    list.forEach( (act, idx) => {
      list[idx]['dateTime'] = dateTimeString(
        new Date(act.time * 1000),
      );
    });

    const dateSet = new Set( list.map( (act) => {
      return act.dateTime.split('@')[0].slice(0, -1);
    }));

    const actByDate = [];
    dateSet.forEach((uniqueDate) => {
      actByDate.push({
        'date': uniqueDate,
        'activities': list.filter((act) => {
          const dAct = act.dateTime.split('@')[0].slice(0, -1);
          return dAct === uniqueDate;
        }),
      });
    });
    return actByDate;
  };

  const toAssessmentClick = (activity) => {
    if (location.pathname.includes(activity.content['activity-id'])) {
      props.setOpen(false);
      return;
    }
    history.push({
      pathname: '/assessment/' + activity.content['activity-id'],
    });
  };

  const checkAssessmentsByDate = (day) => {
    day.hasBeenToggled = !day.hasBeenToggled;
    const _checked = [...checked];
    day.activities.forEach( (act) => {
      const aIdx = _checked.indexOf(act);
      if (day.hasBeenToggled && aIdx === -1) {
        _checked.push(act);
      }

      if (!day.hasBeenToggled && aIdx !== -1) {
        _checked.splice(aIdx, 1);
      }
    });
    props.setSelection(_checked.slice(1));
    setChecked(_checked);
  };

  const textSize = ['A', 'B'].includes(props.group) ? 12: 15;
  // const dateTitileSize = ['A', 'B'].includes(props.group) ? 12: 15;

  const activityTypeToggleProps = {
    replaceOverall: 'All',
    activityType: filter.activityType,
    vibrationType: filter.vibrationType,
    setActivityType: (at) => {
      const _vt = filter.vibrationType;
      setFilter({ activityType: at, vibrationType: _vt });
    },
    setVibrationType: (vt) => {
      const _at = filter.activityType;
      setFilter({ activityType: _at, vibrationType: vt });
    },
    popperPlacement: 'top',
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center"
        style={{ height: '100%' }}>
        { !(['C', 'D'].includes(props.group)) &&
          <Grid item xs={12}
            style={{
              marginTop: '5px',
              height: 40,
            }}>
            <ActivityTypeToggle {...activityTypeToggleProps} size={15} />
          </Grid>}
        <Grid
          item xs={12}
          style={{
            marginTop: '5px',
            height: 'calc(100% - 50px)',
          }}>
          <Paper
            variant="outlined"
            className={classes.scrollPaper}
            style={['C', 'D'].includes(props.group) ? {
              minHeight: 350,
              maxHeight: 350,
              position: 'relative',
            }: { minHeight: '100%', maxHeight: '100%', position: 'relative' }}>
            {
              (dispList.length === 0) ?
                <div
                  style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}>
                  <Typography
                    align="center"
                    color="textSecondary"
                    style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    No Assessments available.
                  </Typography>
                </div> :
                <List dense subheader={<li />}>
                  {dispList.map((day, ii) => (
                    <li key={`section-${ii}`} style={{
                      backgroundColor: 'white',
                    }}>
                      <ul style={{
                        padding: 0,
                      }}>
                        <ListSubheader
                          style={{
                            backgroundColor: 'white',
                            padding: 0,
                          }}>
                          <ButtonBase
                            onClick={() => checkAssessmentsByDate(day)}
                            className={classes.listSubheaderButton}
                            style={{ backgroundColor: dColor }}>
                            <Typography color="primary" variant="button"
                              style={{
                                fontSize: textSize,
                              }}>
                              {`${day.date}`}
                            </Typography>
                          </ButtonBase>
                        </ListSubheader>
                        {day.activities.map((act, jj) => {
                          const bmk = markedIds.includes(
                            act.content['activity-id'],
                          );
                          const chkd = checked.slice(1).map( (cAct) => {
                            return cAct.content['activity-id'];
                          }).includes(act.content['activity-id']);
                          return (
                            <ListItem
                              button
                              onClick={() => {
                                if (props.group === 'D') {
                                  toAssessmentClick(act);
                                } else {
                                  handleToggle(act);
                                }
                              }}
                              key={`item-${ii}-${jj}`}
                              style={{
                                height: 40,
                              }}>
                              <div
                                style={{
                                  width: 40,
                                  height: '100%',
                                  display: 'flex',
                                }}>
                                { !(props.group == 'D') &&
                                    <Checkbox
                                      edge="start"
                                      checked={chkd}
                                      tabIndex={-1}
                                      disableRipple
                                      size="small"
                                      style={{ color: color }}
                                    />
                                }
                                { bmk &&
                                  <BsBookmarkStarFill
                                    className={classes.primaryColor}
                                    style={{
                                      height: 30,
                                      top: !(props.group == 'C') ? 5 : 0,
                                      marginLeft: -5,
                                    }}/>
                                }
                              </div>
                              <Typography
                                color="textSecondary"
                                style={{
                                  fontSize: textSize,
                                  marginRight: textSize,
                                }}>
                                {act.dateTime.split('@')[1]}
                              </Typography>
                              <Typography style={{ fontSize: textSize }}>
                                {id2TitleMap(act.content.title)}
                              </Typography>
                              { act.content.vibrationType &&
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}>
                                  <Typography align="center"
                                    variant="h3" color="primary"
                                    style={{
                                      fontSize: textSize,
                                      marginLeft: 5,
                                    }}>
                                    +
                                  </Typography>
                                  <Tooltip
                                    title={
                                      vibId2TitleMap(act.content.vibrationType)
                                    }>
                                    <div style={{ display: 'flex' }}>
                                      <MdVibration
                                        style={{
                                          marginLeft: 5,
                                          fontSize: textSize,
                                        }}/>
                                    </div>
                                  </Tooltip>
                                </div>
                              }
                              <ListItemSecondaryAction>
                                <IconButton
                                  size="small"
                                  onClick={() => toAssessmentClick(act)}
                                  color="primary">
                                  <ChevronRightIcon style={{ color: color }}/>
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                      </ul>
                    </li>
                  ))}
                </List>
            }
          </Paper>
        </Grid>
        { (['C', 'D'].includes(props.group)) &&
          <Grid item xs={12}
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
              marginRight: '20px',
            }}>
            <ActivityTypeToggle {...activityTypeToggleProps} size={20}/>
          </Grid>}
      </Grid>
    </React.Fragment>
  );
}

SelectAssessmentsList.propTypes = {
  group: PropTypes.string,
  setSelection: PropTypes.func,
  isComputing: PropTypes.bool,
  selectedActs: PropTypes.array,
  setOpen: PropTypes.func,
};

export default SelectAssessmentsList;

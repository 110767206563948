import React from 'react';
import { Link, Typography } from '@material-ui/core';

const branch2versionSuffix = () => {
  switch (process.env.REACT_APP_GIT_BRANCH) {
  case 'development': return 'a';
  case 'staging': return 'b';
  default: return '';
  };
};

/**
 * Renders the "Copright" component, a notice including link.
 * @return {jsx} The about page component.
 */
export default function Copyright() {
  const suffix = branch2versionSuffix();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      style={{ position: 'fixed', bottom: 0, padding: 50 }}
    >
      <Link color="inherit" href="https://www.magnes.ch">
        Magnes AG
      </Link>
      {' © '}
      {new Date().getFullYear()}
      <br />
      {`v${process.env.REACT_APP_VERSION}${suffix}`}
    </Typography>
  );
};

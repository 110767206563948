import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import {
  loadPatientData,
} from '../utils/auxFunctions.js';

import {
  createEventCall,
  pryvApiCall,
  updateEventCall } from '../data/apiCalls.js';
import { definedDiagnoses } from '../definitions/diagnosis.js';
import { generateEntryStr } from './PatientTimeline.js';

/**
 * Renders the "Select Diagnosis" dialog overlay
 * @param {Object} props of the toggle
 * @return {jsx} The component.
 */
export default function SelectDiagnosis({ onSave, onCancel, force }) {
  const patient = loadPatientData();

  const [diagnosis, setDiagnosis] = useState(
    patient.diagnosis !== 'none' ? patient.diagnosis.content : [],
  );

  const onSaveClick = () => {
    if (diagnosis.length === 0) return;

    const timestamp = new Date().getTime() / 1000;

    let diagnosisCall;
    if (patient.diagnosis !== 'none') {
      const id = patient.diagnosis.id;
      diagnosisCall = updateEventCall(id, diagnosis);
    } else {
      diagnosisCall = createEventCall(
        ['diagnosis'],
        'diagnosis-magnes/nushu-v1',
        diagnosis,
        timestamp,
      );
    }

    // Add diagnosis update timeline entry!
    // eslint-disable-next-line max-len
    const timelineEntryText = `The patient's diagnosis has been updated with following conditions: ${diagnosis.map(
      (d) => d.split('-')[1],
    )}.`;
    const timelineCall = createEventCall(
      ['comments'],
      'note/txt',
      generateEntryStr('New Diagnosis', new Date(), timelineEntryText),
      timestamp,
    );

    pryvApiCall(patient.apiToken, [
      {
        method: 'callBatch',
        params: [...diagnosisCall, ...timelineCall],
      },
    ]).then((res) => {
      patient.diagnosis = res[0].results[0].event;
      sessionStorage.setItem('patientData', JSON.stringify(patient));

      onSave();
    });
  };

  const onCancelClick = () => {
    onCancel();
  };

  const nestedDiagnosesList = [
    Object.keys(definedDiagnoses).slice(0, 2),
    Object.keys(definedDiagnoses).slice(2, 4),
    Object.keys(definedDiagnoses).slice(4),
  ];

  const onSelectClick = (selection) => {
    const healthyKey = 'Miscellenous-Healthy';
    const notAvailableKey = 'Miscellenous-Not (yet) available';
    let selectedDiagnoses = [...diagnosis];
    if (selection === healthyKey || selection === notAvailableKey) {
      if (
        diagnosis.includes(healthyKey) || diagnosis.includes(notAvailableKey)
      ) {
        selectedDiagnoses = [];
      } else {
        selectedDiagnoses = [selection];
      }
    } else {
      for (const key of [healthyKey, notAvailableKey]) {
        const removeIdx = selectedDiagnoses.indexOf(key);
        if (removeIdx !== -1) {
          selectedDiagnoses.splice(removeIdx, 1);
        }
      }
      const diagnosisIndex = selectedDiagnoses.indexOf(selection);
      if (diagnosisIndex !== -1) {
        selectedDiagnoses.splice(diagnosisIndex, 1);
      } else {
        selectedDiagnoses.push(selection);
      }
    }
    setDiagnosis(selectedDiagnoses);
  };

  const getDivStyle = (condition) => {
    if (['Healthy', 'Not listed (yet)'].includes(condition)) {
      const dStyle = {
        backgroundColor: 'lightgrey',
        borderRadius: '5px',
      };
      if (condition === 'Not listed (yet)') {
        dStyle.marginTop = '25px';
      }
      return dStyle;
    }
  };

  return (
    <Paper style={{ padding: 2, width: 600 }}>
      <DialogTitle>
        <Typography align="center">
          {'Select the Patient\'s Conditions'}
        </Typography>
      </DialogTitle>
      <Divider />
      <Box style={{ margin: 1 }}>
        <Grid container>
          {nestedDiagnosesList.map((diagnosisList) => (
            <Grid item xs={4} key={uuidv4()}>
              {diagnosisList.map((type) => (
                <List
                  component="div"
                  disablePadding
                  key={uuidv4()}
                  dense
                  subheader={
                    <ListSubheader component="div" style={{ marginTop: 1 }}>
                      <Typography color="secondary" style={{ fontSize: 14 }}>
                        {type}
                      </Typography>
                    </ListSubheader>
                  }
                >
                  {definedDiagnoses[type].map((condition) => (
                    <Box key={uuidv4()} style={getDivStyle(condition)}>
                      <ListItem style={{ marginLeft: 1 }}>
                        <Checkbox
                          edge="start"
                          checked={diagnosis.includes(`${type}-${condition}`)}
                          tabIndex={-1}
                          disableRipple
                          size="small"
                          onClick={() => onSelectClick(`${type}-${condition}`)}
                          style={
                            condition === 'Healthy' ?
                              {
                                'padding': 0,
                                'color': '#00B359',
                                '&$checked': {
                                  color: '#00B359',
                                },
                              } :
                              {
                                padding: 0,
                              }
                          }
                        />
                        <Typography
                          style={{
                            fontSize: 14,
                            color: condition === 'Healthy' ? '#00B359' : '',
                          }}
                        >
                          {condition}
                        </Typography>
                      </ListItem>
                    </Box>
                  ))}
                </List>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
      <DialogActions>
        <Button
          onClick={onCancelClick}
          variant="outlined"
          color="primary"
          disabled={force}>
          Cancel
        </Button>
        <Button onClick={onSaveClick} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Paper>
  );
};

SelectDiagnosis.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  force: PropTypes.bool,
};

import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import QRCode from 'react-qr-code';
import { useStyles } from '../useStyles.js';


/**
 * Renders the UserInfoDialog element, which displays the docs
 user info, such as the QR code, TODO: add editable vcard info.
 * @param {Object} props The component properties.
 * @return {jsx} The user dialog component.
 */
export default function QrCodeDialog(props) {
  const classes = useStyles();

  let docUserName = 'N/A';
  let docAffiliation = 'N/A';

  if (props.doctorData && props.doctorData[0].events.length !== 0) {
    docUserName = props.doctorData[0]['events'].filter(
      (item) => item.streamId === '.username',
    )[0]['content'];
    docAffiliation = props.doctorData[0]['events'].filter(
      (item) => item.streamId === '.affiliation',
    )[0]['content'];
  }

  const qrCodeString = docUserName + '@' + docAffiliation;

  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <DialogContent>
        <MuiDialogTitle color="primary" align="center">
          Request Access
        </MuiDialogTitle>
        <Divider className={classes.marginBottom}/>
        <Typography variant="subtitle1" color="primary">
          Show this QR code to your patient,
          <br /> to request access to his data.
        </Typography>
        <Divider className={classes.marginTopBottom}/>
        <div>
          <QRCode
            level="Q"
            style={{ width: 200 }}
            value={ qrCodeString } />
        </div>
      </DialogContent>
    </Dialog>
  );
};

QrCodeDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  doctorData: PropTypes.array,
};


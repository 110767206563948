import React from 'react';
import { Link } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import { useStyles } from '../useStyles.js';

/**
 * Renders the "MenuList" component. Lists the available pages.
 * @return {jsx} The about page component.
 */
function MenuList() {
  const classes = useStyles();
  return (
    <List component="nav" className={classes.menuList}>

      {/* About */}
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={'/about'}
      >
        <ListItem button>
          <ListItemIcon>
            <InfoIcon/>
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
      </Link>

      {/* How Stats are computed */}
      <Link
        style={{ textDecoration: 'none', color: 'inherit' }}
        to={'/documentation'}
      >
        <ListItem button>
          <ListItemIcon>
            <FindInPageIcon/>
          </ListItemIcon>
          <ListItemText primary="Documentation" />
        </ListItem>
      </Link>
    </List>
  );
}

export default MenuList;

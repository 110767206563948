import React from 'react';
import { PropTypes } from 'prop-types';
import {
  average,
  linspace,
  kernelDensityEstimation,
  stdDev,
} from '../utils/mathUtils.js';

import {
  Button,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core';

import { Line } from 'react-chartjs-2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartArea,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
const view = {
  LINE: 'line',
  HISTOGRAM: 'histogram',
  TABLE: 'table',
};
import Overlay from './Overlay.js';
import TimeSeriesPlot from './TimeSeriesPlot.js';

Chart.defaults.global.legend.display = false;

/**
 * Shows detailed charts (line and histogram) with upper and lower bounds.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export default function DetailChart(props) {
  const [chartView, setChartView] = React.useState(view.LINE);
  const [xAxisType, setXAxisType] = React.useState('Stride No');
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleViewChange = (event) => {
    setChartView(event.currentTarget.value);
  };

  const t0 = Math.min(
    props.timestamps['Left'][0], props.timestamps['Right'][0],
  );

  const tf = Math.max(
    props.timestamps['Left'].slice(-1), props.timestamps['Right'].slice(-1),
  );

  const timestamps = {
    'Left': props.timestamps['Left'].map( (x) => (x - t0)),
    'Right': props.timestamps['Right'].map( (x) => (x - t0)),
  };

  const timestampLabel = linspace(0, tf - t0, Math.ceil((tf - t0) * 10));

  const labels = {
    'Stride No': [...Array(Math.max(
      props.leftData.values.length,
      props.rightData.values.length,
    )).keys()]
      .map((x) => (x + 1).toFixed(0)),
    'Time': timestampLabel.map((ts) => ts.toFixed(2)),
  };

  // Prepare l & r such that each array has an entry for timestampLabel
  const dataByTsIdx = {
    'Left': new Array(timestampLabel.length).fill(null),
    'Right': new Array(timestampLabel.length).fill(null),
  };

  ['Left', 'Right'].forEach( (side) => {
    for (let ii = 0; ii < timestamps[side].length; ii++) {
      const minDT = Math.min(
        ...timestampLabel.map(
          (tsl) => Math.abs(tsl - timestamps[side][ii]),
        ),
      );
      const idx = timestampLabel.findIndex(
        (tsl) => Math.abs(tsl - timestamps[side][ii]) === minDT,
      );

      dataByTsIdx[side][idx] = props[`${side.toLowerCase()}Data`].values[ii]
        .toFixed(2);
    }
  });

  props.macroEvents.forEach((mE) => {
    const dts = timestampLabel.map(
      (ts) => Math.abs(ts - mE.time),
    );
    mE.tsIdx = dts.indexOf(Math.min(...dts));
  });

  const datasets = {
    'Stride No': ['Left', 'Right'].map( (side) => {
      return {
        label: side,
        fill: false,
        backgroundColor: (side === 'Left') ? 'rgb(0, 143, 204)' :
          'rgb(78, 191, 255)',
        borderColor: (side === 'Left') ? 'rgb(0, 143, 204)' :
          'rgb(78, 191, 255)',
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: props[`${side.toLowerCase()}Data`].values.map((val, idx) => {
          return { x: idx + 1, y: val.toFixed(2) };
        }),
      };
    }),
    'Time': ['Left', 'Right'].map( (side) => {
      const color = (side ==='Left') ? '0, 143, 204' : '78, 191, 255';
      return {
        label: side,
        fill: true,
        borderWidth: 1,
        borderColor: `rgba(${color}, 1)`,
        backgroundColor: `rgba(${color}, 0.6)`,
        data: dataByTsIdx[side],
      };
    }),
  };

  const data = {
    labels: labels[xAxisType],
    datasets: datasets[xAxisType],
  };

  if (props.range) {
    data.datasets.push(...[
      {
        type: 'line',
        label: 'Lower Bound',
        fill: 'start',
        backgroundColor: '#3a464c22',
        borderColor: 'darkgrey',
        borderJoinStyle: 'round',
        borderWidth: 1,
        lineTension: 0.2,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        data: [
          { x: 1, y: props.range[0].toFixed(2) },
          { x: labels[xAxisType].slice(-1)[0],
            y: props.range[0].toFixed(2) },
        ],
      },
      {
        type: 'line',
        label: 'Upper Bound',
        fill: 'end',
        backgroundColor: '#3a464c22',
        borderColor: 'darkgrey',
        borderJoinStyle: 'round',
        borderWidth: 1,
        lineTension: 0.2,
        pointBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        data: [
          { x: 1, y: props.range[1].toFixed(2) },
          { x: labels[xAxisType].slice(-1)[0],
            y: props.range[1].toFixed(2) },
        ],
      },
    ]);
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [{
        barPercentage: Math.ceil(labels['Time'].length / 100) + 1,
        scaleLabel: {
          display: false,
          labelString: props.xAxisLabel,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: props.yAxisLabel,
        },
        ticks: {
          beginAtZero: true,
        },
      }],
    },
    annotation: {
      annotations: props.macroEvents.map( (mE) => ({
        drawTime: 'afterDatasetsDraw',
        type: 'line',
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: (xAxisType === 'Stride No') ? mE.strideNo: mE.tsIdx,
        borderWidth: 2,
        borderColor: 'orange',
        label: {
          content: mE.type,
          enabled: true,
          position: 'bottom',
          fontSize: 8,
          backgroundColor: '#3a464c',
        },
      }),
      ),
    },
  };

  const combinedValues = props.leftData.values
    .concat(props.rightData.values);
  const N = combinedValues.length;
  const avg = average(combinedValues);
  const x0 = avg - 3 * stdDev(combinedValues, avg, N);
  const xT = avg + 3 * stdDev(combinedValues, avg, N);
  const xi = linspace(x0, xT, 100);

  const histogramData = {
    labels: xi.map((x) => x.toFixed(2)),
    datasets: [
      {
        label: 'Left',
        fill: true,
        backgroundColor: '#008fccaa',
        borderColor: '#008fcc',
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        data: kernelDensityEstimation(
          props.leftData.values,
          xi,
          props.leftData.stdDev,
        )
          .map((x) => {
            return Math.round(x * 100) / 100;
          }),
      },
      {
        label: 'Right',
        fill: true,
        backgroundColor: '#4ebfffaa',
        borderColor: '#4ebfff',
        borderJoinStyle: 'round',
        lineTension: 0.2,
        pointBorderWidth: 1,
        pointRadius: 0,
        pointHitRadius: 10,
        data: kernelDensityEstimation(
          props.rightData.values,
          xi,
          props.rightData.stdDev,
        )
          .map((x) => {
            return Math.round(x * 100) / 100;
          }),
      },
    ],
  };

  const histogramOptions = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function(tooltipItem, data) {
          const dLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dLabel} - ${tooltipItem.yLabel}`;
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: props.yAxisLabel,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: '% of Strides',
        },
      }],
    },
  };

  return (
    <React.Fragment>
      <ButtonGroup color="primary">
        <Tooltip placement="top" title="Show time series" arrow>
          <Button
            value={view.LINE}
            onClick={handleViewChange}
            variant={chartView === view.LINE ? 'contained' : 'outlined'}
          >
            <FontAwesomeIcon icon={faChartLine} />
          </Button>
        </Tooltip>
        <Tooltip placement="top" title="Show histogram" arrow>
          <Button
            value={view.HISTOGRAM}
            onClick={handleViewChange}
            variant={chartView === view.HISTOGRAM ? 'contained' : 'outlined'}
          >
            <FontAwesomeIcon icon={faChartArea} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      {chartView === view.LINE &&
        <div>
          <div style={{ height: 190 }}>
            <TimeSeriesPlot
              data={data}
              options={options}
              xAxisType={xAxisType}
              setXAxisType={setXAxisType}
              onClick={handleDialogOpen}/>
          </div>
          <Overlay
            title={
              props.yAxisLabel.substring(0, props.yAxisLabel.lastIndexOf(' '))
            }
            open={openDialog}
            onClose={handleDialogClose}
            key={`Dialog-LP-${props.yAxisLabel}`}>
            <TimeSeriesPlot
              data={data}
              options={options}
              xAxisType={xAxisType}
              setXAxisType={setXAxisType}/>
          </Overlay>
        </div>
      }
      {chartView === view.HISTOGRAM &&
        <div>
          <div onClick={handleDialogOpen}>
            <Line data={histogramData} options={histogramOptions}/>
          </div>
          <Overlay
            title={
              props.yAxisLabel.substring(0, props.yAxisLabel.lastIndexOf(' '))
            }
            open={openDialog}
            onClose={handleDialogClose}
            key={`Dialog-KDE-${props.yAxisLabel}`}>
            <Line data={histogramData} options={histogramOptions}/>
          </Overlay>
        </div>
      }

    </React.Fragment>
  );
};

DetailChart.propTypes = {
  timestamps: PropTypes.object,
  leftData: PropTypes.object,
  rightData: PropTypes.object,
  unit: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  range: PropTypes.array,
  macroEvents: PropTypes.array,
};

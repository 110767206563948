/* eslint-disable max-len */
/* eslint-disable require-jsdoc*/
import Pryv from 'pryv';

function startLoginScreen(loginButton, authUrl) {
  const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft;
  const screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop;
  const outerWidth = typeof window.outerWidth !== 'undefined' ?
    window.outerWidth : document.body.clientWidth;
  const outerHeight = typeof window.outerHeight !== 'undefined' ?
    window.outerHeight : (document.body.clientHeight - 22);
  const width = 900;
  const height = 700;
  const left = parseInt(screenX + ((outerWidth - width) / 2), 10);
  const top = parseInt(screenY + ((outerHeight - height) / 2.5), 10);
  const features = (
    'width=' + width +
        ',height=' + height +
        ',left=' + left +
        ',top=' + top +
        ',scrollbars=yes'
  );
  loginButton.popup = window.open(authUrl, 'Your custom Sign-in pop-up', features);

  if (!loginButton.popup) {
    console.log('Pop-up blocked. A second click should allow it.');
  } else if (window.focus) {
    loginButton.popup.focus();
  }
}

export default class MyLoginButton {
  constructor(authSettings, service) {
    this.authSettings = authSettings;
    this.service = service;
    this.serviceInfo = service.infoSync();
  }

  async init() {
    const loginButtonSpan = document.getElementById(this.authSettings.spanButtonID);
    loginButtonSpan.addEventListener('click', this.onClick.bind(this));
    this.loginButtonSpan = loginButtonSpan;

    this._cookieKey = 'pryv-libjs-' + this.authSettings.authRequest.requestingAppId;

    this.auth = new Pryv.Auth.AuthController(this.authSettings, this.service, this);
    await this.auth.init();
  }

  onClick() {
    this.auth.handleClick();
  }

  async onStateChange(state) {
    switch (state.status) {
    case Pryv.Auth.AuthStates.LOADING:
      break;
    case Pryv.Auth.AuthStates.INITIALIZED:
      break;
    case Pryv.Auth.AuthStates.NEED_SIGNIN:
      state.authUrl = state.authUrl.replace('patient', 'doctor');
      const loginUrl = state.authUrl;
      startLoginScreen(this, loginUrl);
      break;
    case Pryv.Auth.AuthStates.AUTHORIZED:
      break;
    case Pryv.Auth.AuthStates.SIGNOUT:
      break;
    case Pryv.Auth.AuthStates.ERROR:
      this.text = Pryv.getErrorMessage(this, state.message);
      break;
    default:
      console.log('WARNING Unhandled state for Login: ' + state.status);
    }
  }


  saveAuthorizationData(authData) {
    console.log('You should save this object to the storage', authData);
  }

  getAuthorizationData() {
    return Pryv.Browser.CookieUtils.get(this._cookieKey);
  }

  async deleteAuthorizationData() {
    console.log('You should delete saved data from the storage');
  }
}


import React, { useEffect } from 'react';
import { Chart, Line } from 'react-chartjs-2';
import { PropTypes } from 'prop-types';
import {
  average,
  linspace,
  kernelDensityEstimation,
  stdDev,
} from '../utils/mathUtils.js';

const lineOptions = (group, side) => {
  let color;
  let alpha;
  let bcAlpha;
  if (group.includes('A')) {
    if (side === 'L') {
      color = '73, 159, 104';
    } else {
      color = '84, 222, 169';
    };
    alpha = 1;
    bcAlpha = 0.25;
  } else {
    if (side === 'L') {
      color = '0, 143, 204';
    } else {
      color = '78, 191, 255';
    };
    alpha = 1;
    bcAlpha = 0.25;
  };


  const lo = {
    label: `${group} - ${side}`,
    color: `rgba(${color}, ${alpha})`,
    backgroundColor: `rgba(${color}, ${bcAlpha})`,
    fill: true,
  };
  return lo;
};

/**
 * Shows detailed charts (line and histogram) with upper and lower bounds.
 * @param {Object} props The component properties.
 * @return {jsx} The detailed chart component.
 */
export default function CompHistogramChart(props) {
  useEffect(() => {
    Chart.pluginService.register({
      afterDraw: function(chart, easing) {
        if (chart.config.options.usePlugin === 'verticalLine') {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.controller.tooltip._active[0];
            const ctx = chart.ctx;
            const x = activePoint.tooltipPosition().x;
            const topY = chart.scales['y-axis-0'].top;
            const bottomY = chart.scales['y-axis-0'].bottom;
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = '#3a464c';
            ctx.stroke();
            ctx.restore();
          }
        }
      },
    });
  });
  const allVals = props.data['Group A']['L']['values'].concat(
    props.data['Group A']['R']['values'],
    props.data['Group B']['L']['values'],
    props.data['Group B']['R']['values'],
  );

  const N = allVals.length;
  const avg = average(allVals);

  const x0 = avg - 3 * stdDev(allVals, avg, N);
  const xT = avg + 3 * stdDev(allVals, avg, N);

  const xi = linspace(x0, xT, 100);

  const datasets = [];
  for (const group of Object.keys(props.data)) {
    for (const side of ['L', 'R']) {
      const auxLo = lineOptions(group, side);
      datasets.push(
        {
          label: auxLo.label,
          fill: auxLo.fill,
          borderColor: auxLo.color,
          backgroundColor: auxLo.backgroundColor,
          borderJoinStyle: 'round',
          lineTension: 0.2,
          pointBorderWidth: 1,
          pointRadius: 0,
          pointHitRadius: 10,
          data: kernelDensityEstimation(
            props.data[group][side].values,
            xi,
            props.data[group][side].std,
          )
            .map((x) => {
              return Math.round(x * 100) / 100;
            }),
        },
      );
    }
  }

  const data = {
    labels: xi.map((x) => x.toFixed(2)),
    datasets: datasets.reverse(),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    usePlugin: 'verticalLine',
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      mode: 'index',
      callbacks: {
        label: function(tooltipItem, data) {
          const dLabel = data.datasets[tooltipItem.datasetIndex].label;
          return `${dLabel} - ${tooltipItem.yLabel}`;
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: (props.unit === '') ? '[-]': `[${props.unit}]`,
          beginAtZero: true,
        },
      }],
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: `Ratio of Values [%]`,
        },
        ticks: {
          display: false,
          beginAtZero: false,
        },
      }],
    },
  };

  return (
    <React.Fragment>
      <Line data={data} options={options}/>
    </React.Fragment>
  );
};

CompHistogramChart.propTypes = {
  data: PropTypes.object,
  unit: PropTypes.string,
};

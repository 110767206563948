import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { DataGrid } from '@material-ui/data-grid';

import {
  pryvApiCall,
  deleteEventCall,
} from '../data/apiCalls.js';

import CustomSnackbar from '../components/CustomSnackbar.js';

import {
  loadPatientData,
} from '../utils/auxFunctions.js';

import {
  MdChevronRight,
} from 'react-icons/md';
import {
  FaTrashAlt,
} from 'react-icons/fa';

const makeRowData = (id, name, created, modified, noAssessments) => {
  return { id, name, created, modified, noAssessments };
};

/**
 * Renders the LoadComparisonDialog element
 * @param {Object} props The component properties.
 * @return {jsx} The component.
 */
export default function LoadComparisonDialog(props) {
  const patientData = loadPatientData();
  const analyzedActs = patientData.activities.filter(
    (item) => item.content.isAnalyzed,
  );

  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false,
  });

  const onLoadClick = (cName) => {
    const cp = props.savedComparisons.find( (x) => x.content.name === cName);
    const ga = analyzedActs.filter( (x) => {
      return (
        cp.content.activities['Group A']
          .includes(x.content['activity-id'])
      );
    });
    const gb = analyzedActs.filter( (x) => {
      return (
        cp.content.activities['Group B']
          .includes(x.content['activity-id'])
      );
    });

    if (ga.length !== cp.content.activities['Group A'].length ||
        gb.length !== cp.content.activities['Group B'].length) {
      setSnackbar({
        message: `Seems like some of the comparison\'s activities are gone.
              They may have been deleted since saving this comparison.`,
        severity: 'warning',
        show: true,
      });
    }
    props.onLoad(ga, gb);
    props.setOpen(false);
  };

  const onDeleteClick = (name) => {
    const tda = props.savedComparisons.find((x) => x.content.name === name);
    const docToken = sessionStorage.getItem('docEP');
    pryvApiCall(docToken, deleteEventCall(tda.id))
      .then((res) => {
        setSnackbar({
          show: true,
          message: 'Comparison Deleted',
          severity: 'success',
        });
        props.setSavedComparisons(
          props.savedComparisons.filter((x) => x !== tda),
        );
      });
  };

  const rows = props.savedComparisons.map((x, idx) => {
    const assessmentCount = x.content.activities['Group A'].length +
      x.content.activities['Group B'].length;

    const lastModified = ((x.modified - x.created) > 10) ?
      new Date(x.modified * 1000).toISOString().slice(0, -5)
        .replace('T', ' '): '';

    return makeRowData(
      idx,
      x.content.name,
      new Date(x.created * 1000).toISOString().slice(0, -5) .replace('T', ' '),
      lastModified,
      assessmentCount,
    );
  });
  rows.sort( (a, b) => a.created - b.created);


  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      disableClickEventBubbling: true,
    },
    {
      field: 'created',
      headerName: 'created',
      width: 180,
    },
    {
      field: 'modified',
      headerName: 'modified',
      width: 180,
    },
    {
      field: 'noAssessments',
      headerName: '# of Assessments',
      align: 'center',
      width: 90,
    },
    {
      field: 'delete',
      headerName: ' ',
      sortable: false,
      filterable: false,
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Delete">
              <IconButton color="primary"
                onClick= {() => onDeleteClick(params.row.name)}>
                <FaTrashAlt style={{ fontSize: 15 }}/>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: 'goto',
      headerName: ' ',
      sortable: false,
      filterable: false,
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <div>
            <Tooltip title="Load">
              <IconButton
                color="primary"
                onClick={() => onLoadClick(params.row.name)}>
                <MdChevronRight style={{ fontSize: 35 }}/>
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      {snackbar.show &&
        <CustomSnackbar
          content={snackbar}
          handleClose={() => setSnackbar({
            message: '',
            severity: '',
            show: false,
          })}/>
      }
      <Dialog
        maxWidth
        open={props.isOpen}
        onClose={() => props.setOpen(false)}>
        <div style={{ width: 800, padding: 10 }}>
          <DialogTitle>
            Saved Comparisons
          </DialogTitle>
          <div style={{ height: 400 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableColumnSelector
              autoPageSize
              disableSelectionOnClick
              GridLines="None"
            />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

LoadComparisonDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  savedComparisons: PropTypes.array,
  setSavedComparisons: PropTypes.func,
  onLoad: PropTypes.func,
};


import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import { useStyles } from '../useStyles';


/**
 * Displays patients as a card.
 * @param {Object} props The component properties.
 * @return {jsx} The patient card component.
 */
export default function PatientCard(props) {
  const history = useHistory();
  const classes = useStyles();

  const [openUpdateNotification, setOpenUpdateNotification] = useState(false);
  const patientData = props.patientData;
  const fullName = `${patientData.firstName} ${patientData.lastName}`;
  const birthDate = patientData.birthdate.toLocaleDateString();
  const username = props.patientData.username;

  const toPatientClick = () => {
    sessionStorage.setItem('patientData', JSON.stringify(patientData));
    history.push({
      pathname: '/patient/' + username,
      state: {
        patientData: patientData,
        hasAssessments: true,
      },
    });
  };


  return (
    <>
      <Card>
        <LinearProgress
          value={0} variant="determinate"
        />
        <CardHeader
          avatar={
            <AccountCircleIcon
              fontSize='large'
            />
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={fullName}
          subheader=
            <React.Fragment>
              {username} <br/>
              {birthDate}
            </React.Fragment>
        />
        <CardActions disableSpacing style={{ position: 'relative' }}>
          {
            patientData.upToDate &&
              <IconButton>
                <AssessmentIcon />
                <Icon>
                  {patientData.trend}
                </Icon>
              </IconButton>
          }
          {!patientData.upToDate &&
            <Tooltip title="Show More Info">
              <IconButton onClick={() => setOpenUpdateNotification(true)}>
                <WarningRoundedIcon className={classes.warnColor}/>
                <Typography style={{ fontSize: 12, color: 'orange' }}>
                  Req. Update
                </Typography>
              </IconButton>
            </Tooltip>
          }
          <Button
            variant="contained"
            color="primary"
            endIcon={<ChevronRightIcon />}
            onClick={toPatientClick}
            style={{ position: 'absolute', right: 30 }}
            disabled={!patientData.upToDate}
          >
            Details
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={ openUpdateNotification }
        onClose={ () => setOpenUpdateNotification(false) }>
        <div style={{ width: 500 }}>
          <DialogTitle>
            Update Needed
          </DialogTitle>
          <Divider style={{ marginLeft: 5, marginRight: 5 }}/>
          <DialogContent style={{ fontSize: 15 }}>
            This user is temporarily disabled as they are running on an old
            version of our platform. To regain full access to their data, do
            the following:
            <ol>
              <li>
                Ask the patient to (re-)login to their account using the App.
              </li>
              <li>
                Ask them to rescan your QR code, as your current access is
                deleted.
              </li>
              <li>
                Wait for the system to reanalyze all of the patient&apos;s
                activities using our new and improved algorithms.
              </li>
            </ol>
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
}

PatientCard.propTypes = {
  patientData: PropTypes.object,
};

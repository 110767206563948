import Pryv from 'pryv';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import {
  pryvApiCall,
  pdfReportsCall,
} from '../data/apiCalls.js';
import { saveAs } from 'file-saver';
import affiliationList from '../definitions/affiliations/affiliations.json';


const toArrayBuffer = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};

export const getAffiliationLogo = async (affiliation = undefined) => {
  const af = !affiliation ? sessionStorage.getItem('affiliation'):affiliation;
  const logoFilename = affiliationList[af]['logo-fn'];
  // Differentiate if called for test or in browser op.
  if (!process.env.JEST_WORKER_ID) {
    // tried passing a variable to require(..) doesn't work
    const logo = require(
      `../definitions/affiliations/logos/${logoFilename}`,
    ).default;
    return toArrayBuffer(logo);
  } else {
    const fs = require('fs');
    const logoUri = fs.readFileSync(
      __dirname +
      `/../definitions/affiliations/logos/${logoFilename}`,
    ).toString('base64');

    return 'data:image/png;base64,' + logoUri;
  }
};


export const fetchPdf = async (apiToken, activityId, filename) => {
  const reportInfo = (await pryvApiCall(
    apiToken,
    pdfReportsCall(activityId),
  ).then((res) => {
    const _details = res[0].events[0].attachments.slice(-1)[0];
    return {
      fileId: _details.id,
      eventId: res[0].events[0].id,
    };
  }));

  const access = Pryv.utils.extractTokenAndApiEndpoint(apiToken);
  const header = { Authorization: access.token };
  const request = [
    'events',
    `${reportInfo.eventId}`,
    `${reportInfo.fileId}`,
    `${filename}`,
  ].join('/');

  const pdfBytes = await fetch(
    access.endpoint + request, { method: 'get', headers: header },
  ).then((res) => {
    return res.arrayBuffer();
  });

  return pdfBytes;
};


export const annotatePdf = async (pdfBytes, annotations) => {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const logoAB = await getAffiliationLogo();

  const pdfLogo = await pdfDoc.embedPng(logoAB);
  const baseXPos = 100;
  const baseYPos = 630;
  const docShift = 210;

  pdfDoc.getPages().forEach( (page, idx) => {
    if (idx === 0) {
      // patient
      page.drawText(`${annotations.patientData.firstName}` +
        `${' '}` +
        `${annotations.patientData.lastName}`, {
        x: baseXPos,
        y: baseYPos + 30,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${annotations.patientData.birthdate.toLocaleDateString()}`,
        {
          x: baseXPos,
          y: baseYPos + 20,
          size: 10,
          font: font,
          color: rgb(0, 0, 0),
        },
      );
      page.drawText(`${annotations.patientData.height}cm`, {
        x: baseXPos,
        y: baseYPos + 10,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${annotations.patientData.weight}kg`, {
        x: baseXPos,
        y: baseYPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      // HCP
      page.drawText(`${annotations.doctorData.firstName}` +
        `${' '}` +
        `${annotations.doctorData.lastName}`, {
        x: baseXPos + docShift,
        y: baseYPos + 30,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${annotations.doctorData.affiliation}`, {
        x: baseXPos + docShift,
        y: baseYPos + 20,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${annotations.doctorData.email}`, {
        x: baseXPos + docShift,
        y: baseYPos + 10,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
      page.drawText(`${annotations.doctorData.phoneNo}`, {
        x: baseXPos + docShift,
        y: baseYPos,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });

      const width = 100;
      const height = width / pdfLogo.width * pdfLogo.height;
      page.drawImage(pdfLogo, {
        x: baseXPos,
        y: page.getHeight() - 70,
        width: width,
        height: height,
      });
    };
    if (idx !== 0) {
      page.drawText(`${annotations.patientData.firstName}` +
        `${' '}` +
        `${annotations.patientData.lastName}`, {
        x: 400,
        y: page.getHeight() - 70,
        size: 10,
        font: font,
        color: rgb(0, 0, 0),
      });
    };
    if (idx === pdfDoc.getPageCount()-1) {
      page.drawText(`${annotations.comment}`, {
        x: baseXPos,
        y: page.getHeight() - 140,
        size: 8,
        font: font,
        color: rgb(0, 0, 0),
        maxWidth: page.getWidth() - 2*baseXPos,
        lineHeight: 10,
      });
    }
  });
  const annotatedPdfBytes = await pdfDoc.save();

  return annotatedPdfBytes;
};


export const downloadPdf = async (pdfBytes, filename) => {
  const newBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  await saveAs(newBlob, `${filename}.pdf`);
};

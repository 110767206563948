export const statTypes = [
  'endurance',
  'consistency',
  'activity',
  'symmetry',
  'total-duration',
  'total-distance',
  'total-assessments',
  'total-steps',
  'duration',
  'distance',
  'assessments',
  'steps',
  'stride-duration',
  'stride-velocity',
  'stride-length',
  'stride-swing-stance-ratio',
  'stride-supination-angle',
  'stride-strike-angle',
  'stride-heel-clearance',
];

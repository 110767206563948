export const average = (arr) => {
  return arr.reduce((acc, curr) => acc + curr) / arr.length;
};

export const stdDev = (arr, mean, n) => Math.sqrt(
  arr.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n,
);

export const arraySum = (array1, array2) => array1.map(function(num, idx) {
  return num + array2[idx];
});
/**
  * Compute the sum of the elements of an array (of numbers)
  * @param {array} x Input array
  * @return {float} Sum of all entries of x.
  */
export function sumArray(x) {
  return x.reduce((a, b) => a + b, 0);
};


/**
  * Compute the element-wise product of two arrays
  * @param {array} x Input array
  * @param {array} y Input array
  * @return {float} Sum of all entries of x.
  */
export function multiplyArrays(x, y) {
  if (x.length !== y.length) {
    throw Error('Arrays must have the same length!');
  }
  const n = x.length;
  if (n === 0) {
    throw Error('Arrays must have non-zero length!');
  }

  const result = new Array(n);
  for (let ii = 0; ii < n; ii++) {
    result[ii] = x[ii] * y[ii];
  }
  return result;
};

/**
  * Returns the unweighted means over last k data points.
  * (A smaller window is used for the first k-1 values.)
  * @param {array} input the input array with size n
  * @param {int} count the window count to be averaged over
  * @return {array} the resulting moving average values
  */
export function trailingMovingAvg(input, count) {
  if (count <= 1) return input;
  const n = input.length;
  const result = new Array(n);
  for (let i = 0; i <= n; i++) {
    if (i === 0) result[i] = input[0];
    else if (0 < i < count) {
      const newInput = input.slice(0, i);
      result[i - 1] = average(newInput);
    }
    if (i >= count) {
      const subArray = input.slice(i - count, i);
      result[i - 1] = average(subArray);
    }
  }
  return result;
};


/**
  * Returns the linear-fit to y = ax + b evaluated at x.
  * @param {array} x The x-values of interest.
  * @param {array} y The y-values to be fitted.
  * @param {boolean} ignoreZeros Whether 0s in the data y should be ignored.
  * @param {boolean} nonNegative Whether the result should only feature
  * values >= 0.
  * @return {array} The fitted values at x.
  */
export function linearRegression(x,
  y,
  ignoreZeros = true,
  nonNegative = true,
) {
  if (x.length !== y.length) {
    throw Error('Arrays must have the same length!');
  }

  const eps = 1e-6;
  const n = x.length;
  let regN = n; /* Number of entries to be considered for regression */
  let result = new Array(n);
  let lastNonZeroIdx = 0;
  const zeroValues = linspace(0., 0., n);
  const regX = linspace(0., 0., n);

  /* Zero-values handling */
  if (ignoreZeros) {
    for (let ii = 0; ii < n; ii++) {
      if (Math.abs(y[ii]) < eps) {
        zeroValues[ii] = 1.;
      } else {
        lastNonZeroIdx = ii;
        regX[ii] = x[ii];
      }
    }
    regN = Math.round(n - sumArray(zeroValues));
  } else {
    for (let ii = 0; ii < n; ii++) {
      regX[ii] = x[ii];
    }
  }

  switch (regN) {
  case 0:
    /* If all entries are zero and zeros shall be ignored (no computation) */
    result = linspace(0., 0., n);
    break;

  case 1:
    /* If only one entry is non-zero, set all constant trend to that value */
    result = linspace(y[lastNonZeroIdx], y[lastNonZeroIdx], n);
    break;

  default:
    /* If at least two entries are non-zero, compute the linear regression */
    const sumX = sumArray(regX);
    const sumY = sumArray(y);
    const sumX2 = sumArray(multiplyArrays(regX, regX));
    const sumXY = sumArray(multiplyArrays(regX, y));

    const a = (regN * sumXY - sumY * sumX) / (regN * sumX2 - sumX * sumX);
    const b = (sumY - sumX * a) / regN;

    for (let ii = 0; ii < n; ii++) {
      result[ii] = a * x[ii] + b;
    }
  }

  if (nonNegative) {
    /* If only non-negative values shall be computed, rectify negative values */
    return result.map(relu);
  }

  return result;
};


/**
  * Rectified linear unit, returns max between x and 0.
  * @param {number} x Value to be rectified.
  * @return {number} Max between 0. and x.
  */
export function relu(x) {
  return Math.max(x, 0.);
};


/**
  * Generate liearly-spaced array between start and stop with n elements.
  * @param {number} start Start value (included)
  * @param {number} stop Stop value (included)
  * @param {number} n Number of elements must be >= 2!
  * @return {array} Array of n linearly spaced values between start and stop.
  */
export function linspace(start, stop, n) {
  if (n < 1) {
    throw Error('n needs to be greater than 1!');
  }

  const res = new Array(n);
  const alpha = (stop - start) / (n - 1);
  for (let ii = 0; ii < n; ii++) {
    res[ii] = start + ii * alpha;
  }

  return res;
};

/**
 * Get random value between min and max.
 * @param {int} max the maximum value
 * @param {int} min the minimum value
 * @return {number} The random integer
 */
export function getRandomValue(max, min = 0) {
  return Number(Math.random() * (max - min) + min);
};


/**
  * Bin data
  * @param {Array} values that are to be binned.
  * @param {Array} bins an associative array of bins where x0 is lower and x1
  * is the upper bound, and to which the values are 'binned'
  * @return {Array} of binned values with their thresholds analog to the d3
  * bin() operator.
  */
export function binData(values, bins) {
  const binnedValues = [];
  for (let idx = 0; idx < bins.length; idx++) {
    const subArray = values.filter((val) => (
      val > bins[idx][0] && val <= bins[idx][1]),
    );
    const ele = {
      values: subArray,
      limits: {
        x0: bins[idx][0],
        x1: bins[idx][1],
      },
    };
    binnedValues.push(ele);
  }
  return binnedValues;
}


/**
  * 'Sum' Distributed Variables
  * @param {Array} stats [{avg: m1, std: s1}, {avg: m2, std: s2}, ...]
  * @return {Object} with {std: NUMBER, avg: NUMBER} of 'combined' passed
  * Variables. NOTE; only works if each dataset, is constructed from (roughly)
  * the same number of samples AND they are independent.
  */
export function sumStatistics(stats) {
  const means = stats.map( (x) => x.avg);
  const combinedAvg = average(means);

  const stds = stats.map((x) => x.std);
  let varSum = 0;
  stds.forEach( (s) => {
    varSum += s ** 2;
  });

  return { avg: combinedAvg, std: Math.sqrt(varSum) / (stats.length - 1) };
}

const gaussKernel = (xi, x, std) => {
  return (
    1 / Math.sqrt(2 * Math.PI * std ** 2)) *
    Math.exp(Math.pow((xi - x), 2) / (-2 * std ** 2));
};
/**
  * Kernel Density Estimation
  * @param {Array} x measured datapoints
  * @param {Array} xi points at which KDE is evaluated at
  * @param {number} std optional standard dev of x
  * @param {function} kernel applied (default is the gauss kernel)
  * @return {Array} of the expected values at xi.
  */
export function kernelDensityEstimation(x, xi, std=null, kernel=gaussKernel) {
  const data = [];
  const N = x.length;

  let xStd;
  if (std === null) {
    xStd = stdDev(x, average(x), N);
  } else {
    xStd = std;
  }

  const bw = xStd / 5;
  for (let i = 0; i < xi.length; i++) {
    let temp = 0;
    for (let j = 0; j < N; j++) {
      temp = temp + gaussKernel(xi[i], x[j], bw);
    }
    data.push((1 / N) * temp);
  }

  const sum = data.reduce((a, b) => a + b);
  return data.map((x)=> 100 * (x / sum));
};

/**
 * Shift Array
 * @param {Array} arr to be shifted
 * @param {Number} n number of indices to shift by
 * @return {Arr} shifted array which has the same length as the input but
 * is appended by shift no of zeros.
 */
export function shiftArray(arr, n) {
  if (n === 0) {
    return arr;
  }
  const toAppend = new Array(n).fill(0);
  arr.push(...toAppend);
  return arr.slice(n);
}

/* eslint-disable quote-props */
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

// TODO: Sort alphabetically or reasonable.
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  alignContainer: {
    display: 'flex',
  },
  alignCenter: {
    margin: 'auto',
  },
  alignRight: {
    marginLeft: 'auto',
  },
  appBar: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  assessmentDrawer: {
    width: 400,
  },
  avatar: {
    top: 20,
    height: 50,
    width: 50,
    margin: 'auto',
    zIndex: 10,
  },
  cardTrendError: {
    color: theme.palette.error.main,
  },
  cardTrendSuccess: {
    color: theme.palette.success.main,
  },
  cardUnit: {
    color: theme.palette.text.secondary,
    fontSize: '0.5em',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  defaultSigninText: {
    fontSize: 'large',
    color: 'primary',
    variant: 'h6',
  },
  divStyle: {
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'secondary',
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
  },
  fullPageDiv: {
    width: '99%',
    backgroundColor: 'blue',
    top: 100,
    left: 0,
    height: '100%',
    position: 'fixed',
  },
  verticalDivider: {
    position: 'relative',
    left: '50%',
    zIndex: 1,
    marginTop: '40px',
    marginBottom: '5px',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  drawerPaperHide: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
  extendedButton: {
    marginRight: theme.spacing(1),
  },
  fab: {
    position: 'relative',
    marginLeft: 'auto',
  },
  fabFixed: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    marginLeft: 'auto',
  },
  fullHeight: {
    height: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  iconOutlined: {
    color: theme.palette.primary.main,
    stroke: theme.palette.secondary.main,
    strokeWidth: 10,
  },
  hoveringPaper: {
    position: 'absolute',
    bottom: '-3%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 150,
    height: '120px',
    width: '500px',
    display: 'flex',
    backgroundColor: 'green',
    alignItems: 'center',
  },
  imageIcon: {
    display: 'flex',
    height: '160px',
    width: 'inherit',
  },
  listSubheaderButton: {
    margin: 0,
    minHeight: '50px',
    maxHeight: '50px',
    width: '100%',
    paddingLeft: 20,
    backgroundColor: '#e4e4e4',
    '&:hover': {
      opacity: 0.8,
    },
  },
  margin: {
    margin: theme.spacing(5),
  },
  marginTopBottom: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(3),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  menuList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  darkgrey: {
    color: 'darkgrey',
  },
  outlinedIconButton: {
    'color': theme.palette.secondary.main,
    'backgroundColor': theme.palette.primary.main,
    '&:hover': {
      'color': theme.palette.secondary.main,
      'opacity': 0.8,
      'backgroundColor': theme.palette.primary.main,
    },
  },
  iconButton: {
    color: theme.palette.primary.main,
    fontSize: 25,
  },
  overlayDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
  },
  paginator: {
    justifyContent: 'center',
    display: 'flex',
    padding: '10px',
  },
  paper: {
    padding: theme.spacing(1),
    overflow: 'hidden',
  },
  paperFlex: {
    padding: theme.spacing(3),
    position: 'relative',
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  padding1: {
    padding: theme.spacing(1),
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  searchBar: {
    marginLeft: '5px',
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  scrollPaper: {
    minHeight: 215,
    marginTop: '10px',
    maxHeight: 215,
    overflow: 'scroll',
  },
  snackbar: {
    position: 'fixed',
    top: 80,
  },
  subChapterTitle: {
    type: 'subtitle1',
    color: 'primary',
    display: 'flex',
    justifyContent: 'center',
    // align : "center",
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  successColor: {
    color: theme.palette.success.main,
  },
  summaryStatsAvatar: {
    top: 18,
    width: 30,
    height: 30,
    zIndex: 10,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'lightslategrey',
  },
  summaryStatsLinearProgress: {
    backgroundColor: 'lightslategrey',
  },
  title: {
    flexGrow: 1,
  },
  toggleButton: {
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
  toolbar: {
    paddingRight: 24,
    position: 'relative',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  warn: {
    backgroundColor: theme.palette.warning.main,
  },
  warnColor: {
    color: theme.palette.warning.main,
  },
}),
{ index: 1 }, // handles stylesheets precedence
);

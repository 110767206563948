import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Tooltip,
} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import {
  timelineDefs,
} from '../definitions/timeline.js';

import { useStyles } from '../useStyles.js';

/**
 * Contains the form to add a new comment.
 * @param {Object} props The component properties.
 * @return {jsx} The comment dialog component.
 */
export default function AddTimelineEntryDialog(props) {
  const classes = useStyles();
  const [entryType, setEntryType] = React.useState('Comment');

  useEffect(() => {
    if (props.entry.type !== undefined) {
      setEntryType(props.entry.type);
    }
  }, []);

  const defaultVals = {};
  if (props.entry) {
    defaultVals.content = props.entry.content;

    const d = new Date(props.entry.date * 1000);
    const day = ('0' + d.getDate()).slice(-2);
    const month = ('0' + (String(d.getMonth() + 1)) ).slice(-2);
    defaultVals.date = d.getFullYear() + '-' + month + '-' + day;
    defaultVals.time = d.toLocaleTimeString().slice(0, -3);
  }

  const onChange = (event, newEntryType) => {
    if (newEntryType !== null) {
      setEntryType(newEntryType);
    }
  };

  const onAddClick = () => {
    const d = document.getElementById('date').value;
    const t = document.getElementById('time').value;

    if (d === '' || t === '') {
      alert('A date and time is required!');
      return;
    }

    const dateTime = d + 'T' + t;

    const entry = {
      'type': entryType,
      'content': document.getElementById('content').value,
      'date': (new Date(dateTime)).getTime() / 1000,
    };

    if (props.entry.id !== undefined) {
      entry.id = props.entry.id;
    };
    props.onSubmit(entry);
  };

  const toggleButtons = Object.keys(timelineDefs).filter((x) => {
    if (!['Registration', 'First Assessment'].includes(x)) {
      return x;
    }
  },
  );

  const et = props.entry ? 'NE': 'UE';
  return (
    <Dialog
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
      }}
      scroll="body"
      disableEscapeKeyDown>
      <DialogContent>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
        }}>
          <ToggleButtonGroup
            value={entryType}
            onChange={onChange}
            exclusive
            aria-label="entry-type">
            {toggleButtons.map( (key, idx) => {
              const Icon = timelineDefs[key].icon;
              return (
                <ToggleButton
                  value={key}
                  key={`tl-toggle-${et}-${idx}`}
                  className={classes.toggleButton}>
                  <Tooltip title={key}>
                    <div
                      style={{
                        position: 'relative',
                        height: 20,
                        width: 20,
                      }}>
                      <Icon style={{ fontSize: 18 }}/>
                    </div>
                  </Tooltip>
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
        <TextField
          margin="dense"
          id="date"
          label="Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          defaultValue={defaultVals.date}
        />
        <TextField
          margin="dense"
          id="time"
          label="Time"
          type="time"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          defaultValue={defaultVals.time}
        />
        <TextField
          fullWidth
          id="content"
          label="Write your notes here..."
          margin="dense"
          multiline
          rows={6}
          rowsMax={12}
          variant="outlined"
          defaultValue={defaultVals.content}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onAddClick} color="primary" variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddTimelineEntryDialog.propTypes = {
  entry: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  FaSave,
  FaTrashAlt,
} from 'react-icons/fa';
import {
  MdModeEdit,
} from 'react-icons/md';

import { useStyles } from '../useStyles';
import theme from '../theme.js';
import {
  pryvApiCall,
  eventsFromStreams,
  createEventCall,
  updateEventCall,
} from '../data/apiCalls.js';

import CustomSnackbar from '../components/CustomSnackbar.js';


const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '3px',
      },
    },
  },
})(TextField);

/**
 * Field containing Comment.
 * @param {Object} props The component properties.
 * @return {jsx} Tshe symmetry chart component.
 */
export default function CommentField(props) {
  const classes = useStyles();

  const [isEditing, setEditing] = useState(false);
  const [commentEvent, setCommentEvent] = useState(null);
  const [callType, setCallType] = useState('');
  const [isFetching, setFetching] = useState(false);
  const [snackbar, setSnackbar] = useState({
    message: '',
    severity: '',
    show: false,
  });

  const noCommentText = 'No Comments yet, author your own comments here!';

  const apiToken = props.apiToken;

  const apiCalls = {};
  if (props.title.includes('Assessment')) {
    const aID = window.location.pathname.split('/').reverse()[0];
    apiCalls.get = eventsFromStreams(
      {
        'all': ['activity-comments'],
        'any': [`activity-${aID}`],
      },
    );
    apiCalls._update = updateEventCall;
    apiCalls._create = (cs) => {
      const ts = (new Date()).getTime() / 1000;
      return createEventCall([`activity-${aID}`,
        'activity-comments'], 'note/txt', cs, ts);
    };
  } else if (props.title.includes('Patient')) {
    apiCalls.get = eventsFromStreams(['notes']);
    apiCalls._update = updateEventCall;
    apiCalls._create = (cs) => {
      const ts = (new Date()).getTime() / 1000;
      return createEventCall(['notes'], 'note/txt', cs, ts);
    };
  };

  const getComment = () => {
    setFetching(true);
    pryvApiCall(apiToken, apiCalls.get)
      .then((res) => {
        if (res[0]['events'].length === 0) {
          setCallType('create');
          document.getElementById('TextField').value = noCommentText;
          sessionStorage.setItem('assComment', noCommentText);
        } else {
          const cEvent = res[0]['events'][0];
          setCallType('update');
          setCommentEvent(cEvent);
          document.getElementById('TextField').value = cEvent['content'];
          sessionStorage.setItem('assComment', cEvent['content']);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbar({
          message: err,
          severity: 'error',
          show: true,
        });
      });
  };

  if (!isFetching) {
    getComment();
  }


  const startEditing = () => {
    setEditing(!isEditing);
    if (!commentEvent) {
      document.getElementById('TextField').value = '';
    }
  };

  const exitWithoutSaving = () => {
    setEditing(false);
    const textField = document.getElementById('TextField');
    if (commentEvent === null) {
      textField.value = noCommentText;
    } else {
      textField.value = commentEvent['content'];
    }
  };

  const exitWithSave = () => {
    setEditing(false);
    const text = document.getElementById('TextField').value;
    let call = null;
    if (callType === 'update') {
      call = apiCalls._update(commentEvent.id, text);
    } else {
      call = apiCalls._create(text);
    }
    pryvApiCall(apiToken, call)
      .then( (res) => {
        setCallType('update');
        setCommentEvent(res[0].event);
        try {
          setSnackbar({
            message: 'Successfully Saved',
            severity: 'success',
            show: true,
          });
        } catch (e) {
          setSnackbar({
            message: 'Oops, something went wrong, the comment was not saved!',
            severity: 'error',
            show: true,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'relative',
        }}>
        {snackbar.show &&
        <CustomSnackbar
          content={snackbar}
          handleClose={() => setSnackbar({ show: false })}
        />}
        <CustomTextField
          label={props.title}
          id="TextField"
          multiline
          rows={(props.size === 'large') ? 12: 5}
          variant="outlined"
          fullWidth
          disabled={(!isEditing)}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            readOnly: (!isEditing),
            style: {
              marginRight: 40,
              fontSize: 14,
            },
          }}
          margin="dense"
        />
        <div
          style={{
            position: 'absolute',
            width: '20%',
            height: '100%',
            right: 2,
            top: 0,
          }}>

          { (!isEditing) ?
            <Tooltip title="Edit Comment">
              <IconButton
                onClick={startEditing}
                className={classes.iconButton}
                style={{
                  position: 'absolute',
                  bottom: 10,
                }}>
                <MdModeEdit/>
              </IconButton>
            </Tooltip> :
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
              }}>
              <Tooltip title="Save Changes">
                <IconButton
                  className={classes.iconButton}
                  onClick={exitWithSave}
                  style={{
                    position: 'absolute',
                    bottom: 70,
                    right: 0,
                  }}>
                  <FaSave />
                </IconButton>
              </Tooltip>
              <Tooltip title="Discard Changes">
                <IconButton
                  className={classes.iconButton}
                  onClick={exitWithoutSaving}
                  style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 10,
                  }}>
                  <FaTrashAlt />
                </IconButton>
              </Tooltip>
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

CommentField.propTypes = {
  title: PropTypes.string,
  apiToken: PropTypes.string,
  size: PropTypes.string,
};

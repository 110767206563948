import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useStyles } from '../useStyles';

/**
 * Renders a dropdown button to sort patients.
 * @param {Object} props The component properties.
 * @return {jsx} The sort button component.
 */
export default function SortButton(props) {
  const classes = useStyles();
  const [isAscending, setAscending] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(props.sortMode);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.onChange(isAscending, index);
  };

  const handleSelectionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrderChange = (event) => {
    const newValue = !isAscending;
    setAscending(newValue);
    props.onChange(newValue, selectedIndex);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        className={classes.alignRight}
        color="primary"
        variant="outlined"
      >
        <Button onClick={handleSelectionClick}>
          {props.options[selectedIndex]}
        </Button>
        <Button
          color="primary"
          size="small"
          onClick={handleOrderChange}
        >
          {isAscending ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

SortButton.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  sortMode: PropTypes.number,
};

/* eslint max-len: ["error", { "ignoreStrings": true }] */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DetailChart from './DetailChart.js';

import {
  FaDiceD20,
} from 'react-icons/fa';


/**
 * Show left and right symmetry.
 * @param {Object} props The component properties.
 * @return {jsx} The symmetry chart component.
 */
export default function GaitParameterCard(props) {
  const leftAvg = Number(props.data.L[props.param].avg).toFixed(2);
  const rightAvg = Number(props.data.R[props.param].avg).toFixed(2);

  const leftStd = Number(props.data.L[props.param].std).toFixed(2);
  const rightStd = Number(props.data.R[props.param].std).toFixed(2);

  const leftCov = Number(props.data.L[props.param].varIDX).toFixed(2);
  const rightCov = Number(props.data.R[props.param].varIDX).toFixed(2);

  const absSum = Math.abs(leftAvg) + Math.abs(rightAvg);

  const rng = props.range;
  const avgData = {
    title: 'Mean',
    chartData: {
      labels: ['Left', 'Right'],
      datasets: [
        {
          data: [leftAvg, rightAvg],
          backgroundColor: [
            '#008fcc',
            '#4ebfff',
          ],
          borderWidth: 3,
          barPercentage: 0.5,
        },
      ],
    },
    range: (rng !== undefined) ?
      `${rng.slice(0, 2)[0].toFixed(1)} - ${rng.slice(0, 2)[1].toFixed(1)}`:
      undefined,
    unit: props.unit,
  };


  const stdData = {
    title: 'STD',
    chartData: {
      labels: ['Left', 'Right'],
      datasets: [
        {
          data: [leftStd, rightStd],
          backgroundColor: [
            '#008fcc',
            '#4ebfff',
          ],
          borderWidth: 3,
          barPercentage: 0.5,
        },
      ],
    },
    range: (rng !== undefined) ? rng.slice(-1)[0].toFixed(1): undefined,
    unit: props.unit,
  };

  const covData = {
    title: 'CoV',
    chartData: {
      labels: ['Left', 'Right'],
      datasets: [
        {
          data: [leftCov, rightCov],
          backgroundColor: [
            '#008fcc',
            '#4ebfff',
          ],
          borderWidth: 3,
          barPercentage: 0.5,
        },
      ],
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: Math.PI,
    circumference: Math.PI,
    layout: {
      padding: {
        bottom: 5,
      },
    },
    legend: false,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, chartData) {
          const dataset = chartData['datasets'][0];
          const side = chartData['labels'][tooltipItem['index']];
          const value = dataset['data'][tooltipItem['index']];
          const unit = props.unit ? props.unit : '';
          const ratio = Math.abs(value / absSum);
          const percent = Math.round(ratio * 100);
          return `${side} ${value} ${unit} (${percent}%)`;
        },
      },
    },
  };

  const getRangeProps = (x) => {
    return {
      txtColor: (!x) ? 'transparent' : '#3a464c',
      ttipTitle: (!x) ? 'No Reference Available' : 'Reference Range',
      bColor: (!x) ? 'transparent' : 'lightgrey',
    };
  };

  return (
    <React.Fragment>
      <div style={{
        justifyContent: 'center',
        padding: 10,
        height: 335,
      }}>
        <Grid container style={{ height: '35%' }}>
          { [avgData, stdData, covData].map( (x, idx) => {
            const leftVal = x.chartData.datasets[0].data[0];
            const rightVal = x.chartData.datasets[0].data[1];
            const rangeProps = getRangeProps(x.range);

            return (
              <Grid item xs={4} key={idx}
                style={{
                  position: 'relative',
                  height: '100%',
                  overflow: 'visible',
                }}>
                {x.title === 'CoV' &&
                  <div style={{
                    position: 'absolute',
                    top: 45,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 100,
                  }}>
                    <Tooltip title="Open Spider Plot">
                      <IconButton
                        color="primary"
                        onClick={props.openSpiderPlotClick}>
                        <FaDiceD20 style={{ fontSize: 15 }}/>
                      </IconButton>
                    </Tooltip>
                  </div>
                }
                <Grid container style={{ position: 'relative', height: '100%' }}>
                  <Grid item xs={12}>
                    <Typography variant="overline" style={{ fontSize: 9 }}>
                      {x.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ height: '43%' }}>
                    <Doughnut
                      data={x.chartData}
                      options={options}/>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="center" variant="body2">
                      {leftVal}<small>{x.unit}</small>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography align="center" variant="body2">
                      {rightVal}<small>{x.unit}</small>
                    </Typography>
                  </Grid>
                  <Tooltip title={rangeProps.ttipTitle}>
                    <Grid item xs={12}
                      style={{ marginTop: '5px' }}>
                      <div>
                        <Typography align="center" variant="caption"
                          style={{
                            fontSize: 12,
                            color: rangeProps.bColor,
                          }}>
                          <big>—[</big>
                          <Typography align="center" variant="caption"
                            style={{
                              fontSize: 12,
                              color: rangeProps.txtColor,
                            }}>
                            {x.range}
                          </Typography>
                          <big>]—</big>
                        </Typography>
                      </div>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          },
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
          <Divider/>
        </Grid>
        <Grid item xs={12}>
          <DetailChart
            timestamps={{
              'Left': props.data.L.t0.values,
              'Right': props.data.R.t0.values,
            }}
            leftData={props.data.L[props.param]}
            rightData={props.data.R[props.param]}
            macroEvents = {props.data.macroEvents}
            unit={props.unit}
            yAxisLabel={`${props.title} [${props.unit}]`}
            range={props.range}
          />
        </Grid>

      </div>
    </React.Fragment>
  );
}

GaitParameterCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  param: PropTypes.string.isRequired,
  unit: PropTypes.string,
  range: PropTypes.array,
  openSpiderPlotClick: PropTypes.func,
};

import {
  FaWalking,
  FaRulerHorizontal,
} from 'react-icons/fa';

import {
  BiDotsHorizontalRounded,
} from 'react-icons/bi';

export const activityTypes = [
  {
    id: 'generic',
    shorthand: '-',
    title: 'Generic',
    category: 'free walks',
  },
  {
    id: '3min-test',
    shorthand: '3\'',
    title: '3min',
    category: 'free walks',
  },
  {
    id: '6min-test',
    shorthand: '6\'',
    title: '6min',
    category: 'free walks',
  },
  {
    id: '4m-walk',
    shorthand: '4m',
    title: '4m Test',
    category: '4 & 10m tests',
  },
  {
    id: 'dual-task-4m-walk',
    shorthand: '4-DT',
    title: '4m Dual Task',
    category: '4 & 10m tests',
  },
  {
    id: 'static-4m-walk',
    shorthand: '4-S',
    title: 'Static 4m Walk',
    category: '4 & 10m tests',
  },
  {
    id: '10m-walk',
    shorthand: '10m',
    title: '10m Test',
    category: '4 & 10m tests',
  },
  {
    id: 'dual-task-10m-walk',
    shorthand: '10-DT',
    title: '10m Dual Task',
    category: '4 & 10m tests',
  },
  {
    id: 'static-10m-walk',
    shorthand: '10-S',
    title: 'Static 10m Walk',
    category: '4 & 10m tests',
  },
  {
    id: '25ft-walk',
    shorthand: '25ft',
    title: '25ft Test',
    category: '4 & 10m tests',
  },
  {
    id: 'tug',
    shorthand: 'TG',
    title: 'Timed-Up-&-Go',
    category: 'Miscellenous',
  },
  {
    id: 'stair-climb',
    shorthand: 'SC',
    title: 'Stair Climb',
    category: 'Miscellenous',
  },
];


export const category2IconMap = {
  'free walks': FaWalking,
  '4 & 10m tests': FaRulerHorizontal,
  'Miscellenous': BiDotsHorizontalRounded,
};

export const id2TitleMap = (iid) => {
  try {
    return activityTypes.filter( (at) => at.id === iid)[0].title;
  } catch (e) {
    return 'n/a';
  }
};
